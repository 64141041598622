import React, { useContext } from "react";

import { Box, Button, Center, createStyles, Modal, Space, useMantineTheme } from "@mantine/core";
import { PreferencesContext } from "../../config/context/preferencesContext";
import { RejectButton } from "./RejectButton";

const useStyles = createStyles((theme, { color = 'default', dark }) => {
  return ({
    deleteButton: {
      backgroundColor: theme.colors['warning'][7]
    }
  });
});

export const DangerousActionModal = ({ title, visible, onCancel, onDelete, buttonLabel = "Delete", message = "Are you sure?" }) => {
  const preferences = useContext(PreferencesContext);
  const theme = useMantineTheme();
  const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
  return (
    <Modal
      overlayOpacity={0.55}
      overlayBlur={3}
      opened={visible}
      onClose={onCancel}
      title={title}
      closeOnEscape
      zIndex={4000}
      closable={"false"}
    >
      <Center mb={'lg'}>
        {message}
      </Center>
      <Box>
        <Center>
          <RejectButton onSubmit={onCancel}>
            Cancel
          </RejectButton>
          <Space w={'md'} />
          <Button
            key="submit"
            className={classes.deleteButton}
            onClick={onDelete}
          >
            {buttonLabel}
          </Button>
        </Center>
      </Box>
    </Modal >
  )
};