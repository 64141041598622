import React, { useEffect, useState } from 'react';
import Icon from '../Utils/Icon';
import QRCode from 'react-qr-code';
import app from 'firebase/compat/app';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Box, Button, Center, createStyles, Grid, RingProgress, Space, Text, TextInput, ThemeIcon, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconCopy, IconLink, IconLock, IconX } from "@tabler/icons";
import { doc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import { PopoverButton } from './PopoverButton';
import { RejectButton } from './RejectButton';

const useStyles = createStyles(theme => {
	return ({
		deleteButton: {
			backgroundColor: theme.colors['warning'][7]
		},
	});
});

export const ShareProgress = ({
	onDelete,
	preferences,
	publicShoppingListId
}) => {
	const { classes } = useStyles();
	const theme = useMantineTheme();
	const [pinEditable, setPinEditable] = useState(false);
	const [publicShoppingList, setPublicShoppingList] = useState(null);
	const [publicShoppingListRealtime] = useDocumentData(doc(db, 'publicShoppingLists', publicShoppingListId));

	useEffect(() => {
		if (publicShoppingListRealtime?.shoppingListId) {
			setPublicShoppingList({ id: publicShoppingListId, ...publicShoppingListRealtime });
		}
	}, [publicShoppingListRealtime]);

	const copyLink = () => {
		var url = document.getElementById('url').value;
		var pin = document.getElementById('pin').value;
		navigator.clipboard
			.writeText(url)
			.then(() => {
				// showNotification({
				// 	id: 'signup-process',
				// 	disallowClose: true,
				// 	autoClose: 2500,
				// 	title: "Link Copied!",
				// 	color: 'teal',
				// 	icon: <IconCheck />,
				// 	loading: false,
				// });
			})
			.catch(() => {
				setPinEditable(true);
				throw Error("Hmm - Something stopped us from copying this for you. 🤔");
			});
	};
	const envDomain =
		document.location.origin === 'http://localhost:3000'
			? 'http://192.168.0.136:3000/'
			: 'https://nomicon.app/';
	const sharingUrl = `${envDomain}buddy/${publicShoppingList?.id}?p=${publicShoppingList?.pin}`;

	let baggedTotal = 0;
	publicShoppingList?.ingredients?.forEach(i => {
		if (i.bagged) baggedTotal++;
	});
	const notStartedDetail = (
		<Box mt={'md'}>
			<Text align={'center'}>
				Your buddy isn't at the shop just yet.
			</Text>
		</Box>
	);
	const totalIngredients = publicShoppingList?.ingredients?.length;
	const done = baggedTotal === totalIngredients;
	const totals = done ? null : `${baggedTotal}/${totalIngredients}`;
	const progressDetail = (
		<Box mt={'lg'}>
			<Center>
				<RingProgress
					sections={[{ value: (baggedTotal / totalIngredients) * 100, color: done ? 'green' : theme.primaryColor }]}
					label={
						done ? (
							<Center>
								<ThemeIcon color="green" radius="xl" size="xl">
									<IconCheck size={22} />
								</ThemeIcon>
							</Center>
						) : (
							<Text sx={{ color: theme.colors[theme.primaryColor][theme.other.rowColorHueIndex] }} weight={700} align="center" size="xl">
								{totals}
							</Text>
						)
					}
				/>
			</Center>
		</Box>
	);
	const existingShareNotice = (
		<>
			<Grid columns={24} grow>
				<Grid.Col span={24}>
					<Box p={'sm'}>
						<Text>Get your shopping buddy to scan the QR code, or you can send them a link by clicking the copy button below.</Text>
					</Box>
					<Box mt={'md'} mb={'md'}>
						<div
							style={{
								border: `3px solid ${preferences.primary}`,
								borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
								background: 'white',
								padding: '16px',
								margin: 'auto',
								maxWidth: 124,
								width: '100%'
							}}
						>
							<QRCode
								size={256}
								style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
								value={sharingUrl}
								viewBox={`0 0 256 256`}
							/>
						</div>
					</Box>
				</Grid.Col>
				<Grid.Col span={24}>
					<TextInput
						id='url'
						name='url'
						disabled={!pinEditable}
						autoComplete={'false'}
						type={'text'}
						placeholder={`Shared Shopping List URL`}
						value={sharingUrl}
						icon={<IconLink />}
					/>
				</Grid.Col>
				<Grid.Col span={24}>
					<TextInput
						id='pin'
						name='PIN'
						disabled={!pinEditable}
						autoComplete={'false'}
						type={'text'}
						placeholder={`Shared Shopping List PIN`}
						icon={<IconLock />}
						value={publicShoppingList?.pin}
					/>
				</Grid.Col>
				<Grid.Col span={24}>
					<Center mt={"lg"}>
						<RejectButton onSubmit={onDelete}>
							Stop Sharing
						</RejectButton>
						<Space w={'md'} />
						<PopoverButton
							variant='filled'
							onClick={copyLink}
							leftIcon={<IconCopy />}
							clickMsg={"Copied link!"}
							width={120}
						>
							Copy
						</PopoverButton>
					</Center>
				</Grid.Col>
				<Grid.Col span={24}>
					{publicShoppingList?.started ? progressDetail : notStartedDetail}
				</Grid.Col>
			</Grid>
		</>
	);

	return publicShoppingList ? existingShareNotice : null;
};
