import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';

import { Box, Button, Center, createStyles, Space, Text, TextInput, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconCopy, IconLink, IconX } from '@tabler/icons';
import { RejectButton } from './RejectButton';
import { PopoverButton } from './PopoverButton';

const useStyles = createStyles((theme, { color = 'default', dark }) => {
	return ({
		deleteButton: {
			backgroundColor: theme.colors['warning'][7]
		},
	});
});

export const ShareRecipeProgress = ({ onDelete, preferences }) => {
	const theme = useMantineTheme();
	const [pinEditable, setPinEditable] = useState(false);
	const publicRecipe = useSelector(state => state.publicRecipe);
	const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });

	const copyLink = () => {
		var url = document.getElementById('url').value;
		try {
			navigator.clipboard
				.writeText(url)
				.then()
				.catch(() => {
					setPinEditable(true);
					showNotification({
						id: 'share-recipe',
						disallowClose: true,
						autoClose: 3000,
						title: "Oops!",
						message: "Hmm - Something stopped us from copying this for you. 🤔",
						color: 'red',
						icon: <IconX />,
						loading: false,
					});
				});
		} catch (error) {
			setPinEditable(true);
			showNotification({
				id: 'share-recipe',
				disallowClose: true,
				autoClose: 3000,
				title: "Oops!",
				message: "Hmm - Something stopped us from copying this for you. 🤔",
				color: 'red',
				icon: <IconX />,
				loading: false,
			});
			console.error(`sorry - we couldn't copy these details for you ${error}`);
		}
	};

	const envDomain =
		document.location.origin === 'http://localhost:3000'
			? 'http://192.168.0.136:3000/'
			: 'https://nomicon.app/';
	const sharingUrl = `${envDomain}noms/${publicRecipe?.id}`;

	const existingShareNotice = (
		<>
			<Box p={'md'}>
				<Text>Show this QR code to someone you think could use this recipe! Or you can send them the URL below.</Text>
			</Box>
			<Box p={'md'}>
				<div
					style={{
						border: `3px solid ${theme.colors[theme.primaryColor][6]}`,
						borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
						background: 'white',
						padding: '16px',
						height: 'auto',
						margin: '0 auto',
						maxWidth: 150,
						width: '100%'
					}}
				>
					<QRCode
						size={256}
						style={{
							height: 'auto',
							maxWidth: '100%',
							width: '100%'
						}}
						value={sharingUrl}
						viewBox={`0 0 256 256`}
					/>
				</div>
			</Box>
			<Box>
				<TextInput
					id='url'
					name='url'
					disabled={!pinEditable}
					autoComplete={'false'}
					type={'text'}
					placeholder={`Shared Recipe URL`}
					value={sharingUrl}
					icon={<IconLink />}
				/>
				<Space h={'md'} />
				<Center>
					<RejectButton onSubmit={onDelete}>
						Stop Sharing
					</RejectButton>
					<Space w={'md'} />
					<PopoverButton
						variant='filled'
						onClick={copyLink}
						leftIcon={<IconCopy />}
						clickMsg={"Copied link!"}
						width={120}
					>
						Copy
					</PopoverButton>
				</Center>
			</Box>
		</>
	);

	return publicRecipe ? existingShareNotice : null;
};
