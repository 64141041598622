/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, Container, useMantineTheme } from '@mantine/core';
import Loader from 'react-spinners/PuffLoader';
import { showNotification } from '@mantine/notifications';
import { IconBook2, IconCalendar, IconListCheck, IconX } from "@tabler/icons";
import { SET_ERROR } from '../../redux/actions';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { css } from '@emotion/react';
import { useLagNavigate } from '../../hooks/useLagNavigate';
import { useHeaderMenu } from '../Layout/HeaderMenu';
import { ActionsGrid } from './MenuButton';
import { getLatestShoppingListThunk } from '../../model/shoppingLists';
import { capitalizeFirstLetter } from '../../utils/textHandling';


const useStyles = createStyles((theme, { color = 'default', dark }) => {
	return ({

		box: {
			backgroundColor: theme.colors[color][theme.other.rowColorHueIndex],
			borderRadius: process.env.REACT_APP_BORDER_RADIUS_MD,
			color: 'white',
			padding: '1rem',
		},

		inner: {
			position: 'relative',
		},


		title: {
			...theme.other.preventSelection,
			textAlign: 'center',
			fontWeight: 800,
			fontSize: 40,
			letterSpacing: -1,
			color: theme.white,
			marginBottom: theme.spacing.xs,
			fontFamily: `Greycliff CF, ${theme.fontFamily}`,

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				fontSize: 28,
				textAlign: 'center',
			},
		},

		highlight: {
			color: dark ? 'black' : 'white',
		},

		description: {
			textAlign: 'center',

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				textAlign: 'left',
				fontSize: theme.fontSizes.md,
			},
		},

		controls: {
			marginTop: theme.spacing.lg,
			display: 'flex',
			justifyContent: 'center',

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				flexDirection: 'column',
			},
		},

		control: {
			'&:not(:first-of-type)': {
				marginLeft: theme.spacing.md,
			},

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				height: 42,
				fontSize: theme.fontSizes.md,

				'&:not(:first-of-type)': {
					marginTop: theme.spacing.md,
					marginLeft: 0,
				},
			},
		},
	});
});

const Menu = ({ recipeId, plannerId }) => {
	const error = useSelector(state => state.error);
	const version = useSelector(state => state.version);
	const shoppingListLatest = useSelector(state => state.shoppingListLatest);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { preferences, auth, help } = useContext(PreferencesContext);
	const theme = useMantineTheme();
	const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
	const HeaderMenu = useHeaderMenu({ help: true, disabled: true });
	const [shoppingClicked, onMenuActionClicked] = useLagNavigate(
		process.env.REACT_APP_LAG_NAV_MS,
		'Menu'
	);
	const helpRefRecipes = useRef(null);
	const helpRefShoppingLists = useRef(null);

	useEffect(() => {
		dispatch(getLatestShoppingListThunk());
		if (error && !error.acked) {
			showNotification({
				id: 'signup-process',
				disallowClose: true,
				autoClose: 3000,
				title: "Oops!",
				message: error.msg || 'Something went wrong. 😯',
				color: 'red',
				icon: <IconX />,
				loading: false,
			});
			dispatch({
				type: SET_ERROR,
				payload: {
					error: {
						acked: true
					}
				}
			});
		}
	}, []);

	const overlay =
		!auth?.dbVersion ||
		auth?.dbVersion < process.env.REACT_APP_NEW_COMER_DB_VERSION;

	if (
		auth?.dbVersion &&
		auth?.dbVersion >= process.env.REACT_APP_NEW_COMER_DB_VERSION &&
		!overlay && (
			recipeId || plannerId
		)
	)
	if (recipeId) {
		navigate(`/recipes?r=${recipeId}`);
	}
	if (plannerId) {
		navigate(`/planners/${plannerId}/share`);
	}

	const override = css`
		border-color: ${preferences.primary};
	`;

	const menuButtons = [
		{
			title: 'Recipes',
			icon: IconBook2,
			action: () => onMenuActionClicked(`/recipes`),
			help: {
				feature: process.env.REACT_APP_HELP_MENU_RECIPES,
				ref: helpRefRecipes,
				showHelp: help?.showHelp,
				position: 'top-center',
			}
		},
		{
			title: 'Shopping Lists',
			icon: IconListCheck,
			action: () => onMenuActionClicked(`/shopping`),
			help: {
				feature: process.env.REACT_APP_HELP_MENU_SHOPPING_LISTS,
				ref: helpRefShoppingLists,
				showHelp: help?.showHelp,
				position: 'top-center',
			}
		},
		{
			title: 'Planner',
			icon: IconCalendar,
			action: () => onMenuActionClicked(`/planners`),
			help: {
				feature: process.env.REACT_APP_HELP_MENU_PLANNERS,
				ref: helpRefShoppingLists,
				showHelp: help?.showHelp,
				position: 'top-center',
			}
		},
	]

	if (shoppingListLatest) {
		menuButtons.splice(2, 0, {
			title: `Shopping List: ${capitalizeFirstLetter(shoppingListLatest.name)}`,
			icon: IconListCheck,
			action: () => onMenuActionClicked(`/shopping/${shoppingListLatest.id}`),
			help: {
				feature: process.env.REACT_APP_HELP_MENU_SHOPPING_LIST_LATEST,
				ref: helpRefShoppingLists,
				showHelp: help?.showHelp,
				position: 'top-center',
			}
		});
	}

	return (
		<>
			{!version ? (
				<Loader
					color={preferences.primary}
					loading={true}
					css={override}
					size={20}
				/>
			) : (
				<>
					<HeaderMenu />
					<Container my={'lg'} sx={{ ...theme.other.wrapper }} size={1400}>
						<ActionsGrid
							menuItems={menuButtons}
						/>
					</Container>
				</>
			)}
		</>
	);
};

export default Menu;
