import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions";

import { Box, Button, Center, Modal, useMantineTheme } from "@mantine/core";
import styles from "../../styles/Home.module.scss";
import {
  syncPublicRecipeThunk,
  updatePublicRecipeThunk,
} from "../../model/publicRecipes";
import { LagLoader } from "../Utils/LagLoader";
import { ShareRecipeProgress } from "./ShareRecipeProgress";

export const ShareRecipeModal = ({ visible, onCancel, preferences }) => {
  const [showSharePage, setShowSharePage] = useState(false);
  const dispatch = useDispatch();
  const publicRecipe = useSelector(state => state.publicRecipe);
  const theme = useMantineTheme();

  useEffect(() => {
    setShowSharePage(publicRecipe?.active)
    return () => { setShowSharePage(false); }
  }, [publicRecipe]);

  const onDelete = () => {
    dispatch(updatePublicRecipeThunk({ ...publicRecipe, active: false }));
    dispatch({
      type: actions.PUBLIC_RECIPE_CREATED,
      payload: {
        publicRecipe: null,
      }
    });
  }

  const shareViaNomicon = () => {
    dispatch(syncPublicRecipeThunk());
  };

  const progress = (
    <LagLoader preferences={preferences} ms={1000}>
      <ShareRecipeProgress
        onCancel={onCancel}
        onDelete={onDelete}
        preferences={preferences}
      />
    </LagLoader>
  );

  const startButtons = (
    <Box>
      <Center>
        <Button
          key="submit"
          disabled={false}
          onClick={shareViaNomicon}
        >
          Share via Nomicon
        </Button>
      </Center>
    </Box>
  );

  return (
    <Modal
      overlayColor={preferences.dark ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      opened={visible}
      onClose={onCancel}
      title="Sharing Recipe"
      closeOnEscape
      zIndex={4000}
      closable={"false"}
    >
      {showSharePage ? progress : startButtons}
    </Modal>
  );
};