/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePreferencesThunk } from '../../model/users';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { NewItemModal } from '../Inputs/NewModal';
import { updateProfileThunk } from '../../model/profiles';
import { IconUser, IconSettings, IconEdit, IconSun, IconMoonStars } from '@tabler/icons';
import {
	Accordion,
	Checkbox,
	CheckIcon,
	ColorSwatch,
	Group,
	Loader,
	Modal,
	useMantineTheme,
	SegmentedControl,
	Box,
	Center,
	Space,
	Button,
	Switch
} from '@mantine/core';
import { AccountProfile } from './AccountProfile';
import SignoutButton from '../SignoutButton/SignoutButton';


const ColorSwatchItem = ({ id, onColorChange }) => {
	const theme = useMantineTheme();
	const { preferences } = useContext(PreferencesContext);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (loading) {
			onColorChange(id);
			setTimeout(() => setLoading(false), 1000);
		}
	}, [loading]);

	let icon = loading ? <Loader color={'white'} size="xl" /> : undefined;
	if (!icon) icon = id === preferences.themeId && <CheckIcon color={'black'} width={10} />

	return (
		<ColorSwatch
			key={id}
			color={theme.colors[id][6]}
			onClick={() => setLoading(true)}
		>
			{icon}
		</ColorSwatch>
	);
}

const Account = () => {
	const { preferences } = useContext(PreferencesContext);
	const user = useSelector(state => state.user);
	const profile = useSelector(state => state.profile);
	const recipesMeta = useSelector(state => state.recipesMeta);
	const [editingProfile, setEditingProfile] = useState(false);
	const [panel, setPanel] = useState('');
	const theme = useMantineTheme();
	const themes = theme.other.defaultColorMap;
	const dispatch = useDispatch();

	const numRecipes = recipesMeta && Object.values(recipesMeta?.recipeCounts)
		.reduce((prev, curr) => prev + curr);

	const toggleHandedness = () => {
		dispatch(
			updatePreferencesThunk({
				...preferences,
				rightHanded: !preferences.rightHanded
			})
		);
	};

	const toggleDarkmode = () => {
		dispatch(
			updatePreferencesThunk({
				...preferences,
				dark: !preferences.dark
			})
		);
	};

	const onColorChange = themeId => {
		if (!themeId) return;
		dispatch(updatePreferencesThunk({ themeId }));
	};

	const themePallete = themes.map((id, idx) => {
		return <ColorSwatchItem key={idx} id={id} onColorChange={onColorChange} />;
	});

	const onEditedProfile = profileName => {
		dispatch(
			updateProfileThunk({
				...profile,
				name: profileName
			})
		);
		setEditingProfile(false);
	};

	const profileModal = (
		<Modal
			overlayColor={preferences.dark ? theme.colors.dark[9] : theme.colors.gray[2]}
			overlayOpacity={0.55}
			overlayBlur={3}
			opened={editingProfile}
			onClose={() => setEditingProfile(false)}
			title={"Edit Profile"}
			closeOnEscape
			zIndex={4000}
			closable={"false"}
		>
			<NewItemModal
				source={'profile'}
				onCancel={() => setEditingProfile(false)}
				onSubmit={profileName => onEditedProfile(profileName)}
				nameMaxLength={process.env.REACT_APP_MAX_LENGTH_PROFILE_NAME}
				inName={profile?.name}
			/>
		</Modal>
	);
	const getColor = (color) => theme.colors[color][theme.colorScheme === 'dark' ? 5 : 7];

	const component = (
		<>
			{profileModal}
			<Box>
				<Center>
					<AccountProfile
						onEdit={setEditingProfile}
						image={null}
						avatar={null}
						name={profile?.name}
						email={user?.email}
						stats={[
							{
								label: 'Recipes',
								value: numRecipes
							},
							{
								label: 'Recipes Shared',
								value: profile?.recipesContributed
							},
						]}
					/>
				</Center>
			</Box>
			<Center>
				<Box>
					<Group spacing={'xl'} position={'center'}>
						<SegmentedControl
							value={preferences.rightHanded ? 'right' : 'left'}
							label="Shipping address"
							onChange={toggleHandedness}
							data={[
								{ value: 'left', label: 'Left Handed' },
								{ value: 'right', label: 'Right Handed' },
							]}
						/>
						{/* <Checkbox
							checked={preferences.dark}
							onChange={toggleDarkmode}
							label="Dark Mode"
							color={theme.primaryColor}
						/> */}
						<Switch
							pb={'md'}
							size="lg"
							checked={preferences.dark}
							color={preferences.dark ? 'gray' : 'dark'}
							onChange={toggleDarkmode}
							onLabel={<IconSun size="1rem" stroke={2.5} color={theme.colors.yellow[4]} />}
							offLabel={<IconMoonStars size="1rem" stroke={2.5} color={theme.colors.blue[6]} />}
						/>
					</Group>
				</Box>
			</Center>
			<Group position='center' p={'xl'}>
				{themePallete}
			</Group>
			<Box p={'lg'}>
				<SignoutButton />
			</Box>
		</>
	);
	return component;
	// return (
		// <Accordion
		// 	variant="contained"
		// 	value={panel}
		// 	onChange={setPanel}
		// >
		// 	<Accordion.Item value="profile">
		// 		<Accordion.Control icon={<IconUser size={20} color={getColor('red')} />}>
					
		// 		</Accordion.Control>
		// 		<Accordion.Panel>
					
		// 		</Accordion.Panel>
		// 	</Accordion.Item>
		// 	<Accordion.Item value="preferences">
		// 		<Accordion.Control icon={<IconSettings size={20} color={getColor('blue')} />}>
		// 			Preferences
		// 		</Accordion.Control>
		// 		<Accordion.Panel>
					
		// 		</Accordion.Panel>
		// 	</Accordion.Item >
		// </Accordion >
	// );
};

export default Account;
