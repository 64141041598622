import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { extractParamsFromLocation } from '../utils/mappings';
import { useHeaderMenu } from '../components/Layout/HeaderMenu';
import { Button, Center, createStyles, Space, useMantineTheme } from '@mantine/core';
import { PreferencesContext } from '../config/context/preferencesContext';
import { Hero } from './hero';
import { IconX } from '@tabler/icons';
import { showNotification } from '@mantine/notifications';
import { useState } from 'react';

const useStyles = createStyles((theme, { color }) => {
	return ({
		wrapper: {
			position: 'relative',
			paddingTop: 20,
			paddingBottom: 80,

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				paddingTop: 40,
				paddingBottom: 60,
			},
		},
		skewed: {
			transform: 'skewY(-7deg)',
		},
		backdrop: {
			// background: theme.colors[theme.primaryColor][8],
			height: '100vh',
			width: '100vw',
			position: 'fixed',
		},
		clipped: {
			padding: '3.5rem',
			// background: theme.colors[theme.primaryColor][8],
			// clipPath: 'polygon(0 15%, 100% 0, 100% 85%, 0 100%)',
			// clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 69% 100%, 43% 83%, 0 83%)',
			// clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 69% 100%, 43% 83%, 0 83%)',
		},
		clippedHeader: {
			padding: '2rem',
			// background: theme.colors[theme.primaryColor][8],
			clipPath: 'polygon(0 0, 100% 0, 100% 85%, 0 100%)',
		},
		header: {
			background: 'red',
			backgroundSize: 'cover',
			backgroundPosition: 'top',
			position: 'relative',
		},

		box: {
			// backgroundColor: theme.primaryColor,
			borderRadius: process.env.REACT_APP_BORDER_RADIUS_MD,
			color: 'white',
			padding: '1rem',
		},

		inner: {
			position: 'relative',
		},


		title: {
			...theme.other.preventSelection,
			textAlign: 'center',
			fontWeight: 800,
			fontSize: 40,
			letterSpacing: -1,
			color: theme.white,
			marginBottom: theme.spacing.xs,
			fontFamily: `Greycliff CF, ${theme.fontFamily}`,

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				fontSize: 28,
				textAlign: 'center',
			},
		},

		controls: {
			marginTop: theme.spacing.lg,
			display: 'flex',
			justifyContent: 'center',

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				flexDirection: 'column',
			},
		},

		control: {
			'&:not(:first-of-type)': {
				marginLeft: theme.spacing.md,
			},

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				height: 42,
				fontSize: theme.fontSizes.md,

				'&:not(:first-of-type)': {
					marginTop: theme.spacing.md,
					marginLeft: 0,
				},
			},
		},
	});
});

export default function Home({ error404 = false }) {
	const navigate = useNavigate();
	const isLoggedIn = useSelector(state => state.isLoggedIn);
	const location = useLocation();
	const params = extractParamsFromLocation(location);
	const HeaderMenu = useHeaderMenu({ hideHelp: true, disabled: true, transparent: true });
	const theme = useMantineTheme();
	const [errorShown, setErrorShown] = useState(false);
	const { preferences } = useContext(PreferencesContext);
	const { classes } = useStyles({ color: preferences.themeId });

	if (error404 && !errorShown) {
		showNotification({
			id: "error404",
			autoClose: 3500,
			title: "Sorry, we couldn't find what you were looking for.",
			// message,
			color: "red",
			icon: <IconX />,
			loading: false,
		});
		setErrorShown(true);
	}

	const landing = (
		<div>
			<HeaderMenu hideHelp={true} />
			<Center>
				<Hero />
			</Center>
			<Center className={classes.clipped}>
				<Center mt={'lg'}>
					<Button
						onClick={() => navigate(`/auth/signup${location?.search}`)}
						size={'xl'}
						sx={{
							color: document.body.style.backgroundColor,
							backgroundColor: "white",
							'&:hover': {
								backgroundColor: "#343434",
								color: 'white',
							},
						}}
					>
						Sign Up
					</Button>
				</Center>
				<Space w={'lg'} />
				<Center mt={'lg'}>
					<Button
						onClick={() => navigate(`/auth/signin${location?.search}`)}
						size={'xl'}
						sx={{
							color: document.body.style.backgroundColor,
							backgroundColor: "white",
							'&:hover': {
								backgroundColor: "#343434",
								color: 'white',
							},
						}}
					>
						Sign In
					</Button>
				</Center>
			</Center>
		</div>
	);

	let passthru = undefined;
	if (params.r) {
		passthru = <Navigate to={`/recipes${location?.search}`} />;
	} else if (params.p) {
		passthru = <Navigate to={`/planners${location?.search}`} />;
	} else {
		passthru = <Navigate to={`/menu${location?.search}`} />;
	}
	return isLoggedIn ? passthru : landing;
}
