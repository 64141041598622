import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Center, Title, Text } from '@mantine/core';

import styles from '../../styles/Home.module.scss';
import { CLEAR_PREFERENCES } from '../../redux/actions';
import { useHeaderMenu } from '../../components/Layout/HeaderMenu';

export default function BetaPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const HeaderMenu = useHeaderMenu({ help: true, disabled: true, isPublic: true });
	useEffect(() => {
		dispatch({
			type: CLEAR_PREFERENCES,
			payload: {}
		});
	}, [dispatch]);
	return (
		<>
			<HeaderMenu hideHelp={true} disabled />
			<Box>
				<Title align="center" size="lg" weight={500} mt="lg" p={'lg'}>
					Hey there! Thanks for signing up to{' '}
					<span className={styles.site__primary_color}>Nomicon</span> beta.
				</Title>
				<Center mt={'lg'}>
					<Text align='center'>
						Click the button below get started!
					</Text>
				</Center>
				<Center mt={'lg'}>
					<Button
						onClick={() => navigate('/auth/signin')}
					>
						Sign In
					</Button>
				</Center>
			</Box>
		</>
		// <TrunkPublic>
		// 	<FullBrandRow />
		// 	<Row className={[styles.item__row__blank].join(' ')}>
		// 		<Col span={24} className={[styles.item__col].join(' ')}>
		// 			<h2
		// 				style={{ userSelect: 'none' }}
		// 				className={[styles.item_row__blank_heading].join(' ')}
		// 			>

		// 			</h2>
		// 		</Col>
		// 		<Col span={24} className={[styles.item__col].join(' ')}>
		// 			<p
		// 				style={{ userSelect: 'none' }}
		// 				className={[styles.item_row__blank_heading].join(' ')}
		// 			>
		// 				We're not open to the public just yet but we'll activate your
		// 				account soon.
		// 			</p>
		// 		</Col>
		// 	</Row>
		// 	<Row
		// 		justify='center'
		// 		style={{ paddingTop: '10px' }}
		// 		className={[styles.sign_in__row].join(' ')}
		// 	>
		// 		<Col span={20}>
		// 			<Button
		// 				onClick={() => navigate('/')}
		// 				className={[styles.submit_button, styles.action_button].join(' ')}
		// 			>
		// 				Back
		// 			</Button>
		// 		</Col>
		// 	</Row>
		// </TrunkPublic>
	);
}
