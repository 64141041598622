import React, { useContext, useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { DragDropContext } from 'react-beautiful-dnd';
import DroppableBox from '../Layout/DroppableBox';
import { deserializeItems } from '../../utils/mappings';
import { createStyles, Modal, useMantineTheme } from '@mantine/core';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { NewItemModal } from '../Inputs/NewModal';
import { capitalizeFirstLetter } from '../../utils/textHandling';
import { reorder } from '../../utils/sort';
import InfoBox from '../Layout/InfoBox';
import { HelpWrapper } from '../Utils/HelpWrapper';

const useStyles = createStyles((theme) => {
	return ({
		mealplanBox: {
			color: 'white',
			fontWeight: 'bold',
			userSelect: 'none',
			borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
		},
		inner: {
			width: '100%',
			justifyContent: 'space-between',
			alignItems: 'center',
			margin: 'auto',
			color: 'white',
			backgroundColor: theme.colors[theme.primaryColor][6],
			borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
		},
	});
});


const changeDestination = (
	sourceList,
	desintationList = [],
	sourceIndex,
	destinationIndex,
	destinationDroppableId
) => {
	const sourceResult = Array.from(sourceList || []);
	const destinationResult = Array.from(desintationList || []);
	const [removed] = sourceResult.splice(sourceIndex, 1);
	if (destinationDroppableId === 'new section') {
		return [sourceResult, destinationResult, removed];
	} else {
		removed.droppableId = destinationDroppableId;
	}
	destinationResult.splice(destinationIndex, 0, removed);
	return [sourceResult, destinationResult];
};

const ShoppingListIngredients = ({
	shoppingListDocRef,
	onDelete,
	onSaveOrder,
	onSaveSectionOrder,
	isPublic = false,
	preferencesOverride = null,
	editItem
}) => {
	const theme = useMantineTheme();
	let { preferences } = useContext(PreferencesContext);
	const [newSectionItem, setNewSectionItem] = useState({});
	const [allItems, setAllItems] = useState({});
	const [fetched, setFetched] = useState(false);
	const [isDragging, setIsDragging] = useState(false);
	const [shoppingListRealtime] = useDocumentData(shoppingListDocRef);
	if (preferencesOverride) preferences = preferencesOverride;
	const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });

	const addingNewSection = Object.entries(newSectionItem).length > 0;

	const onNewSectionItem = sectionNameInput => {
		// new section has been accepted - put the item in it
		const sectionName = sectionNameInput.toLowerCase();
		newSectionItem.droppableId = sectionName;
		if (
			Object.keys(allItems).findIndex(section => section === sectionName) > -1
		) {
			allItems[sectionName].push(newSectionItem);
		} else {
			// when its a brand new section, we're creating a new array
			allItems[sectionName] = [newSectionItem];
		}
		onSaveOrder(allItems);
		setNewSectionItem({});
	};

	const onCancelNewSectionItem = () => {
		// new section has been cancelled - put the item back where it came from
		if (allItems[newSectionItem.droppableId] === undefined) {
			allItems[newSectionItem.droppableId] = [newSectionItem];
		} else {
			allItems[newSectionItem.droppableId].push(newSectionItem);
		}
		setNewSectionItem({});
	};

	const newSectionModal = (
		<Modal
			overlayColor={preferences.dark ? theme.colors.dark[9] : theme.colors.gray[2]}
			overlayOpacity={0.55}
			overlayBlur={3}
			opened={addingNewSection}
			onClose={onCancelNewSectionItem}
			title={"Add Group"}
			closeOnEscape
			zIndex={800}
			closable={"false"}
		>
			<NewItemModal
				source={'group'}
				onCancel={onCancelNewSectionItem}
				onSubmit={sectionName => onNewSectionItem(sectionName)}
				nameMaxLength={process.env.REACT_APP_MAX_LENGTH_GROUP_NAME}
			/>
		</Modal>
	);

	useEffect(() => {
		if (shoppingListRealtime) {
			if (shoppingListRealtime?.ingredients && shoppingListRealtime?.sections) {
				setAllItems(
					deserializeItems(
						shoppingListRealtime.ingredients,
						shoppingListRealtime.sections
					)
				);
			}
			setFetched(true);
		}
		return () => {
			setAllItems({});
		};
	}, [shoppingListRealtime]);

	const onDragEnd = result => {
		setIsDragging(false);
		if (!result.destination) {
			// dropped outside the list
		} else {
			let sectionedItems = null;
			if (result.destination.droppableId === result.source.droppableId) {
				// dropped in the same section
				const orderedItems = reorder(
					allItems[result.source.droppableId],
					result.source.index,
					result.destination.index
				);
				sectionedItems = {
					...allItems,
					[result.source.droppableId]: orderedItems
				};
			} else {
				const [sourceList, destinationList, removedItem] = changeDestination(
					allItems[result.source.droppableId],
					allItems[result.destination.droppableId],
					result.source.index,
					result.destination.index,
					result.destination.droppableId
				);

				if (result.destination.droppableId === 'new section') {
					setNewSectionItem(removedItem);
				}

				sectionedItems = {
					...allItems,
					[result.source.droppableId]: sourceList,
					[result.destination.droppableId]: destinationList
				};
			}
			setAllItems(sectionedItems);
			onSaveOrder(sectionedItems);
		}
	};

	const onDragStart = result => setIsDragging(true);

	const onDragUpdate = result => { };

	const baggedItem = itemObj => {
		const sectionItems = allItems[itemObj.droppableId]?.map(i => {
			const bagged = i.id === itemObj.id ? !i.bagged : i.bagged;
			return {
				...i,
				bagged
			};
		});
		allItems[itemObj.droppableId] = sectionItems;
		onSaveOrder(allItems);
	};

	const moveSection = (droppableId, dir) => {
		if (!droppableId || !dir) return;
		onSaveSectionOrder(droppableId, dir);
	};

	const draggableList = (
		<DragDropContext
			onDragEnd={onDragEnd}
			onDragStart={onDragStart}
			onDragUpdate={onDragUpdate}
		>
			{isPublic || Object.keys(allItems).length === 0 ? null : (
				<HelpWrapper
					feature={process.env.REACT_APP_HELP_SHOPPING_LIST_REORDERABLE_GROUPS}
					position={'top-center'}
				>
					<DroppableBox
						keyId={'new section'}
						droppableItems={shoppingListRealtime?.ingredients?.filter(
							item => item?.droppableId === 'new section'
						)}
						onDelete={onDelete}
						baggedItem={baggedItem}
						isPublic={isPublic}
						preferencesOverride={preferencesOverride}
						droppableId={'new section'}
						showDragActions={isDragging}
					/>
				</HelpWrapper>
			)}
			{shoppingListRealtime?.sections?.map(({ droppableId }, idx) => {
				return (
					<DroppableBox
						key={droppableId}
						droppableItems={allItems[droppableId]}
						onDelete={onDelete}
						mainActionFn={itemObj => editItem(itemObj)}
						isPublic={isPublic}
						preferencesOverride={preferencesOverride}
						droppableId={droppableId}
						droppableName={capitalizeFirstLetter(droppableId)}
						backgroundColor={preferences.primaryLight}
						isFirst={idx === 0}
						isLast={idx + 1 === shoppingListRealtime?.sections?.length}
						moveSection={dir => moveSection(droppableId, dir)}
						baggedItem={baggedItem}
						section={shoppingListRealtime.sections.find(
							section => droppableId === section.droppableId
						)}
						showDragActions={isDragging}
					/>
				);
			})}
		</DragDropContext>
	);

	const renderComponent = allItems ? draggableList : null;
	return (
		<div
			style={{
				width: '100%',
				position: 'relative',
			}}
			className={classes.mealplanBox}
		>
			{newSectionModal}
			{Object.keys(allItems).length === 0 ? (
				<InfoBox text={'There is nothing on your list yet.'} />
			) : null}
			{fetched && !allItems ? null : renderComponent}
		</div>
	);
};

export default ShoppingListIngredients;
