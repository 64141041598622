import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionIcon, Box, Button, Center, createStyles, Group, Indicator, Loader, Modal, SegmentedControl, Space, Stack, Stepper, Tabs, Text, TextInput, Title, useMantineTheme } from "@mantine/core";
import { CLEAR_PLANNER_EVENT, CLEAR_PLANNER_EVENT_RECIPE } from "../../redux/actions";
import RecipesPageInner from "../Recipes/RecipesPageInner";
import { FloatingLabelInput } from "./FloatingLabelInput";
import { getLongDayStringFromDate } from "../../utils/time";
import { IconAdjustments, IconX } from "@tabler/icons";
import { createPlannerEventThunk, deletePlannersEventsThunk, updatePlannerEventThunk } from "../../model/planners";
import { DangerousActionModal } from "./DangerousActionModal";
import { getRecipeThunk } from "../../model/recipes";
import { useForm } from "@mantine/form";

export const PlannerEventModal = ({
  dayString,
  dateObj,
  eventInput,
  visible,
  onCancel,
  preferences,
  userCanEdit,
  defaultMeal = "breakfast",
}) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const publicProfile = useSelector(state => state.publicProfile);

  const form = useForm({
    initialValues: {
      cost: '',
      recipeId: false,
    },

    validate: {
      cost: (value) => (/^\d*\.?\d*$/.test(value) ? null : "Numbers and decimal places only."),
    },

    onSubmit: function() {

    }
  });

  const eventRecipe = useSelector(state => {
    if (state.eventRecipe) return state.eventRecipe;
    return state.recipe;
  });
  const initialEvent = {
    category: "takeaway",
    mealTime: defaultMeal,
    dateObj: dateObj,
  };
  const eventInitialiser = eventInput || initialEvent;
  const [deleting, setDeleting] = useState(false);
  const [event, setEvent] = useState({ ...eventInitialiser });
  const [hideRecipes, setHideRecipes] = useState(false);

  useEffect(() => {
    if (event.recipeId) {
      setHideRecipes(true)
      dispatch(getRecipeThunk(event.recipeId));
    }
  }, []);
  useEffect(() => {
    if (eventRecipe) {
      setHideRecipes(true)
    }
  }, [eventRecipe]);

  const handleSave = () => {
    event.day = dateObj?.getDate();
    event.month = dateObj?.getMonth();
    event.year = dateObj?.getFullYear();
    if (event.category === "recipe") {
      event.recipeId = eventRecipe.id;
      event.recipeName = eventRecipe.name;
      event.recipeData = {
        sortname: eventRecipe?.sortname,
        sortletter: eventRecipe?.sortletter,
        name: eventRecipe?.name,
        ingredients: eventRecipe?.ingredients,
        steps: eventRecipe?.steps,
        publicProfileId: eventRecipe?.publicProfileId || publicProfile?.id,
        tags: eventRecipe?.tags,
      };
    } else {
      event.recipeName = `Takeaway: $${event.cost}.`;
    }
    dispatch(createPlannerEventThunk(event));
    handleCancel();
  }
  const handleEdit = () => {
    if (event.category === "recipe") {
      event.recipeId = eventRecipe.id;
      event.recipeName = eventRecipe.name;
      event.recipeData = {
        sortname: eventRecipe?.sortname,
        sortletter: eventRecipe?.sortletter,
        name: eventRecipe?.name,
        ingredients: eventRecipe?.ingredients,
        steps: eventRecipe?.steps,
        publicProfileId: publicProfile?.publicProfileId,
        tags: eventRecipe?.tags,
      };
    } else {
      event.recipeId = undefined;
      event.recipeName = `Takeaway: $${event.cost}.`;
    }
    dispatch(updatePlannerEventThunk(event));
    handleCancel();
  }

  const handleCancel = () => {
    dispatch({ type: CLEAR_PLANNER_EVENT, payload: {} });
    dispatch({ type: CLEAR_PLANNER_EVENT_RECIPE, payload: {} });
    setEvent(initialEvent);
    setHideRecipes(false);
    onCancel();
  };

  const handleDelete = () => {
    dispatch(deletePlannersEventsThunk(event));
    onCancel(event.id);
  }

  const handleCategoryChange = category => {
    let cost = "";
    let recipeData = null;
    let recipeId = null;
    if (category === "recipe") {
      if (eventRecipe) {
        dispatch({ type: CLEAR_PLANNER_EVENT_RECIPE, payload: {} });
      };
    }
    setHideRecipes(false);
    setEvent(lastState => ({
      ...event,
      recipeId,
      recipeData,
      category,
      cost,
    }));
    dispatch(updatePlannerEventThunk(event));
  };
  const handleMealChange = mealTime => {
    setEvent({
      ...event,
      mealTime,
    })
  };

  const resetEventRecipe = () => {
    dispatch({ type: CLEAR_PLANNER_EVENT_RECIPE, payload: {} });
    setHideRecipes(false);
    let cost = "";
    let recipeData = null;
    let recipeId = null;
    setEvent(lastState => ({
      ...event,
      recipeId,
      recipeData,
      cost,
    }));
    dispatch(updatePlannerEventThunk(event));
  }

  const onCostChange = evt => {
    const { value } = evt.currentTarget;
    setEvent({ ...event, cost: value })
  };

  const validCostRegex = new RegExp(/^\d*\.?\d*$/);
  const isValidCost = cost => {
    let validCost = false;
    if (cost && validCostRegex.test(cost)) {
      validCost = true;
    }
    return validCost;
  };
  const costError = event?.cost && !isValidCost(event?.cost) && <Text size={"sm"} dimmed>Numbers and decimal places only.</Text>;

  const costInput = (
    <FloatingLabelInput
      required={event.category !== "recipe"}
      autoFocus={true}
      mt={"2rem"}
      label={"Cost"}
      placeholder={"Cost"}
      value={event?.cost}
      onChange={onCostChange}
      error={costError}
      onEnter={isValidCost(event?.cost) && (event.id ? handleEdit : handleSave)}
    />
  );

  let recipeList = undefined;
  if (event?.category === "recipe") {
    if (!hideRecipes) {
      recipeList = <RecipesPageInner objectType={"eventRecipe"} highlightedListData={[ { id: event.recipeId }]} />;
    } else if (eventRecipe) {
      recipeList = (
        <>
          <Center mt={"xl"} mb={"xl"}>
            <Indicator onClick={resetEventRecipe} inline processing size={26} color="red" label={<IconX size={16} />}>
              <Text xs={{ textOverflow: "ellipsis", overflow: "hidden" }}>{eventRecipe.name}</Text>
            </Indicator>
          </Center>
          {costInput}
        </>
      );
    }
  }

  return (
    <Modal
      overlayColor={preferences.dark ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      opened={visible}
      onClose={handleCancel}
      title={event?.id ? "Edit Meal" : "Add Meal"}
      closeOnEscape
      zIndex={4000}
      closable={"false"}
    >
      <form onSubmit={form.onSubmit((values) => values)}>
        {/* <TextInput
          withAsterisk
          label="Email"
          placeholder="your@email.com"
          {...form.getInputProps('email')}
        /> */}
        <FloatingLabelInput
          autoFocus
          mt={"2rem"}
          label={"Day"}
          placeholder={"Day"}
          value={getLongDayStringFromDate(new Date(Date.parse(dateObj)))}
          disabled
        />

        <Stack align="stretch" justify="flex-end" spacing="xs" h={300} mt={'sm'} mb={'sm'}>
          <SegmentedControl
            fullWidth
            // m={"sm"}
            color={theme.primaryColor}
            value={event?.mealTime}
            label="Meal"
            onChange={handleMealChange}
            data={[
              { label: "Breakfast", value: "breakfast" },
              { label: "Lunch", value: "lunch" },
              { label: "Dinner", value: "dinner" },
            ]}
          />
          <SegmentedControl
            fullWidth
            // m={"sm"}
            color={theme.primaryColor}
            value={event?.category}
            label="Meal Category"
            onChange={handleCategoryChange}
            data={[
              { label: "Takeaway Meal", value: "takeaway" },
              { label: "Recipe", value: "recipe" }
            ]}
          />
        </Stack>

        {/* <Checkbox
          mt="md"
          label="I agree to sell my privacy"
          {...form.getInputProps('termsOfService', { type: 'checkbox' })}
        /> */}
      </form>
      {recipeList}
      {event?.category ===  "takeaway" && costInput}
      {event?.id && userCanEdit && (
        <Button
          sx={{ float: "left" }}
          onClick={() => setDeleting(true)}
          mt={"xl"}
          color={"red"}
        >
          Delete
        </Button>
      )}
      <Button
        sx={{ float: "right" }}
        disabled={(event?.category === "recipe" && !eventRecipe) || (event?.category === "takeaway" && !isValidCost(event?.cost))}
        onClick={event.id ? handleEdit : handleSave}
        mt={"xl"}
      >
        Save
      </Button>
      <DangerousActionModal
        title={`Delete Meal`}
        onDelete={() => {
          handleDelete();
          setDeleting(false);
        }}
        onCancel={() => setDeleting(false)}
        visible={deleting}
      />
    </Modal>
  );
};
