import React from 'react';
import { connect } from 'react-redux';
import { Button, createStyles } from '@mantine/core';
import * as actions from '../../redux/actions';
import { IconLogout } from '@tabler/icons';
import { doSignOut } from '../../config/firebase/firebase';

const useStyles = createStyles(theme => {
	return ({
		deleteButton: {
			backgroundColor: theme.colors['warning'][7]
		},
	});
});

const SignoutButton = ({ firebase, onUserSignout, ...props }) => {
	const { classes } = useStyles();
	const handleSignout = () => {
		doSignOut();
		onUserSignout();
	};
	return (
		<Button
			onClick={handleSignout}
			type='button'
			leftIcon={<IconLogout />}
			uppercase
			compact
			fullWidth
			radius="sm"
			// mt="xl"
			size="md"
			className={classes.deleteButton}
			{...props}
		>
			Logout
		</Button>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		onUserSignout: () =>
			dispatch({
				type: actions.SET_USER,
				payload: { user: null, isLoggedIn: false, isLoggedOut: true }
			})
	};
};

// export default withFirebase(connect(null, mapDispatchToProps)(SignoutButton));
export default connect(null, mapDispatchToProps)(SignoutButton);
