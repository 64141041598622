import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	limit,
	orderBy,
	query,
	startAfter,
	where,
} from "firebase/firestore";
import * as actions from '../redux/actions';
import { byObjProperty } from '../utils/sort';
import {
	createDocument,
	getDocument,
	getDocumentsByAttribute,
	getDocumentSingleton,
	handleError,
	updateDocument,
	updateDocumentThunk,
} from './generic';
import { getSettings, updateSettings } from './users';
import { LOAD_RECIPE, LOAD_PUBLIC_RECIPE } from '../redux/actions';
import { upgradeUserVersion } from './versions';
import { db } from '../config/firebase/firebase';
import sendNotification from "../components/Utils/notificationHandler";
import { getValidRecipeName } from "../utils/textHandling";

const ERROR_MSGS = {
	recipe404: "We couldn't find that recipe. 😬",
	recipeNoName: "We can't create a recipe without a name."
};

export const getRecipesMeta = async uid => {
	const recipesMeta = await getDocumentSingleton('recipesMeta', uid);
	return recipesMeta;
};

const createRecipesMeta = async (uid, recipesMeta) => {
	const existingRecipeMeta = await getRecipesMeta(uid);
	try {
		let recipesMetaDoc = null;
		const updatedRecipesMeta = {
			...recipesMeta,
			createdAt: Date.now()
		};
		if (existingRecipeMeta) {
			await updateDocument(updatedRecipesMeta, 'recipesMeta', uid);
		} else {
			recipesMetaDoc = await createDocument(updatedRecipesMeta, 'recipesMeta', uid);
		}
		return recipesMetaDoc;
	} catch (err) {
		console.error('Error adding document: ', err);
	}
};

export const updateRecipesMeta = async (uid, recipesMeta) => {
	const recipesMetaUpdated = Object.assign(
		{},
		{
			...recipesMeta,
			modifiedAt: Date.now()
		}
	);
	try {
		await updateDocument(recipesMetaUpdated, 'recipesMeta', uid);
	} catch (e) {
		console.error(`error updating recipesMeta: ${e}`);
	}
	return recipesMetaUpdated;
};

export const syncRecipeLetters = async (uid, dbVersion) => {
	const recipesToSync = await getAllRecipes(uid);
	const recipeCounts = {};
	for (let i = 65; i <= 90; i++) {
		recipeCounts[String.fromCharCode(i)] = 0;
	}
	const recipePromises = recipesToSync.map(recipe => {
		const sortLetter = recipe.name[0].toUpperCase();
		recipe.sortletter = sortLetter;
		recipeCounts[sortLetter] = recipeCounts[sortLetter] + 1;
		return updateRecipe(uid, recipe);
	});
	await Promise.all(recipePromises);
	const settings = await getSettings(uid);
	await createRecipesMeta(uid, { recipeCounts });
	await updateSettings(uid, {
		...settings,
		dbVersion
	});
};

export const updateRecipe = async (uid, recipe, dispatch = null) => {
	if (!recipe.name) throw ERROR_MSGS.recipeNoName;
	const recipeUpdated = Object.assign(
		{},
		{
			...recipe,
			id: recipe.recipeId,
			tags: recipe.name.toUpperCase().split(' '),
			sortletter: recipe.name[0].toUpperCase(),
			modifiedAt: Date.now()
		}
	);
	try {
		await updateDocument(recipeUpdated, 'recipes', uid);
		if (dispatch) {
			let recipesMeta = await getRecipesMeta(uid);
			const sortLetter = recipe.name[0].toUpperCase();
			recipesMeta.recipeCounts[sortLetter] = recipesMeta.recipeCounts[
				sortLetter
			]++;
			recipesMeta = await updateRecipesMeta(uid, recipesMeta);
			dispatch({
				type: actions.LOAD_RECIPES_META,
				payload: {
					recipesMeta
				}
			});
		}
	} catch (e) {
		console.error(`error updating recipe: ${e}`);
	}
	return recipeUpdated;
};

export const getAllRecipes = async uid => {
	const q = query(collection(db, `users/${uid}/recipes`));
	const recipes = [];
	const querySnapshot = await getDocs(q);
	querySnapshot.forEach(recipe => {
		const recipeDoc = { id: recipe.id, ...recipe.data() };
		recipes.push(recipeDoc);
	});
	return recipes;
};

export const getRecipes = async (uid, recipeLetter) => {
	if (!recipeLetter) return;
	const q = query(
		collection(db, `users/${uid}/recipes`),
		where('sortletter', '==', recipeLetter.toUpperCase()),
		orderBy('sortname', 'asc'),
	);
	const recipes = [];
	const querySnapshot = await getDocs(q);
	querySnapshot.forEach(recipe => {
		recipes.push({ id: recipe.id, ...recipe.data() });
	});
	return recipes;
};

export function getRecipesMetaThunk() {
	return async function inner(dispatch, getState) {
		try {
			const state = getState();
			if (!state.user) return;
			const recipesMeta = await getRecipesMeta(state.user.uid);
			dispatch({
				type: actions.LOAD_RECIPES_META,
				payload: {
					recipesMeta
				}
			});
		} catch (err) {
			console.error(err);
		}
	}
}

const storeRecipes = async (uid, recipeLetter, dispatch) => {
	const recipesMeta = await getRecipesMeta(uid);
	const recipes = await getRecipes(uid, recipeLetter);
	dispatch({
		type: actions.LOAD_RECIPES_META,
		payload: {
			recipesMeta
		}
	});
	dispatch({
		type: actions.LOAD_RECIPES,
		payload: {
			recipes,
			recipeLetter,
			source: 'storeRecipes'
		}
	});
};

export function getRecipesThunk(recipeLetter = null) {
	return async function inner(dispatch, getState) {
		try {
			const state = getState();
			if (!state.user) return;
			await upgradeUserVersion(state.user.uid);
			await storeRecipes(
				state.user.uid,
				recipeLetter ? recipeLetter : state.recipeLetter,
				dispatch
			);
		} catch (e) {
			console.error(e);
		}
	};
}

export const getRecipe = async (uid, recipeId) => {
	const recipe = await getDocument(recipeId, 'recipes', uid);
	return recipe;
};

export function getRecipeThunk(recipeId) {
	return async function inner(dispatch, getState) {
		try {
			const state = getState();
			if (!state.user) return;
			const recipe = await getRecipe(state.user.uid, recipeId);
			dispatch({
				type: actions.LOAD_RECIPE,
				payload: {
					recipe,
					recipeLetter: recipe?.sortLetter
				}
			});
		} catch (err) {
			if (err === ERROR_MSGS.recipe404) {
				handleError(
					{
						msg: err
					},
					dispatch
				);
			}
		}
	};
}

export const createRecipe = async (uid, recipe, validatedRecipeName) => {
	if (!recipe.name) throw ERROR_MSGS.recipeNoName;
	try {
		const recipeDb = {
			...recipe,
			sortname: recipe.name?.toUpperCase(),
			sortletter: validatedRecipeName[0].toUpperCase(),
			tags: recipe.name.toUpperCase().split(' '),
			createdAt: Date.now()
		}
		const recipeDoc = await createDocument(
			recipeDb,
			'recipes',
			uid
		);
		recipeDb.id = recipeDoc.id;
		return recipeDb;
	} catch (err) {
		console.error('Error adding document: ', err);
	}
};

export function createRecipeThunk(recipe, copying) {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!state.user || !recipe) return;
		sendNotification(dispatch, "create", "loading", "recipe");
		const validRecipeName = getValidRecipeName(recipe.name);
		recipe = await createRecipe(state.user.uid, recipe, validRecipeName);
		let recipesMeta = await getRecipesMeta(state.user.uid);
		const recipeCountsUpdated = recipesMeta?.recipeCounts;
		recipeCountsUpdated[recipe.sortletter] = recipeCountsUpdated[recipe.sortletter] + 1;
		let newRecipesMeta = {
			...recipesMeta,
			recipeCounts: recipeCountsUpdated,
		};
		if (copying) {
			let newSavedRecipeIds = newRecipesMeta?.savedRecipeIds;
			if (newSavedRecipeIds) {
				newSavedRecipeIds.push(recipe.recipeId);
			} else {
				newSavedRecipeIds = [recipe.recipeId];
			}
			newRecipesMeta = {
				...newRecipesMeta,
				savedRecipeIds: newSavedRecipeIds
			};
		}
		recipesMeta = await updateRecipesMeta(state.user.uid, newRecipesMeta);
		dispatch({
			type: actions.LOAD_RECIPES_META,
			payload: {
				recipesMeta
			}
		});
		const recipes = await getRecipes(state.user.uid, recipe.sortletter);
		sendNotification(dispatch, copying ? "copying" : "create", "success", "recipe");
		dispatch({
			type: actions.LOAD_RECIPES,
			payload: {
				recipes,
				recipeLetter: recipe.sortletter
			}
		});
	};
}

export const deleteRecipe = async (uid, recipeId) => {
	try {
		await deleteDoc(doc(db, `users/${uid}/recipes`, recipeId));
		return true;
	} catch (err) {
		console.error(`Error deleting recipe: ${err}`);
		return false;
	}
};

export function deleteRecipeThunk(recipe) {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!recipe || !state.user) return;
		await deleteRecipe(state.user.uid, recipe.id);
		let recipesMeta = await getRecipesMeta(state.user.uid);
		const sortLetter = recipe.name[0].toUpperCase();
		const recipeCountsUpdated = recipesMeta.recipeCounts;
		if (recipeCountsUpdated[sortLetter] !== 0) {
			recipeCountsUpdated[sortLetter] = recipeCountsUpdated[sortLetter] - 1;
		}
		let newRecipesMeta = {
			...recipesMeta,
			recipeCounts: recipeCountsUpdated
		};

		const savedRecipeIndex = newRecipesMeta['savedRecipeIds']?.indexOf(
			recipe.recipeId
		);
		if (savedRecipeIndex > -1) {
			newRecipesMeta.savedRecipeIds.splice(savedRecipeIndex, 1);
		}
		recipesMeta = await updateRecipesMeta(state.user.uid, newRecipesMeta);
		let recipeLetter = 'A';
		if (recipeCountsUpdated[sortLetter] === 0) {
			const recipeCountObjArray = [];
			for (const [letter, count] of Object.entries(recipeCountsUpdated)) {
				if (count > 0) {
					recipeCountObjArray.push({ recipeLetter: letter, count });
				}
			}
			if (recipeCountObjArray?.length > 0) {
				recipeCountObjArray.sort(byObjProperty('recipeLetter', false));
				recipeLetter = recipeCountObjArray[0].recipeLetter;
			}
		} else {
			recipeLetter = sortLetter;
		}
		const recipes = await getRecipes(state.user.uid, recipeLetter);
		dispatch({
			type: actions.LOAD_RECIPES,
			payload: {
				recipes,
				recipeLetter,
				source: 'deleteRecipeThunk'
			}
		});
		dispatch({
			type: actions.LOAD_RECIPES_META,
			payload: {
				recipesMeta
			}
		});
	};
}

export function copyRecipeThunk(publicRecipeId) {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!publicRecipeId || !state.user) return;
		await upgradeUserVersion(state.user.uid);
		const publicRecipe = await getDocument(publicRecipeId, 'publicRecipes');
		const [recipe, recipesNextPage] = await getDocumentsByAttribute(
			'recipes',
			{ field: 'recipeId', value: publicRecipeId },
			state.user.uid,
			null,
			true,
			true,
		);
		const existingRecipe = recipe;
		if (existingRecipe) {
			const recipes = await getRecipes(
				state.user.uid,
				existingRecipe.sortletter
			);
			dispatch({
				type: actions.LOAD_RECIPES,
				payload: {
					recipes,
					recipeLetter: existingRecipe.sortletter,
					source: 'copyRecipeThunk'
				}
			});
		} else {
			delete publicRecipe?.id;
			dispatch(createRecipeThunk({ ...publicRecipe, bookmarked: true }, true));
		}
	};
}

export function removeBookmarkThunk(publicRecipe) {
	return async function inner(dispatch, getState) {
		const { recipesMeta, user } = getState();
		if (!publicRecipe || !user) return;
		const [recipe, recipeNextPage] = await getDocumentsByAttribute(
			'recipes',
			{ field: 'recipeId', value: publicRecipe.id },
			user.uid,
			null,
			true,
			true
		);
		const existingRecipe = recipe;
		const newRecipesMeta = Object.assign({}, {
			...recipesMeta,
			savedRecipeIds: recipesMeta.savedRecipeIds.filter(id => id !== publicRecipe?.id),
		});
		if (existingRecipe) {
			dispatch(deleteRecipeThunk(existingRecipe[0]));
		} else {
			await updateRecipesMeta(user.uid, newRecipesMeta);
			dispatch({
				type: actions.LOAD_RECIPES_META,
				payload: {
					recipesMeta: newRecipesMeta
				}
			});
		}
	};
}

export function syncRecipe(dispatch, recipe) {
	const recipeDoc = Object.assign({}, recipe);
	recipeDoc.ingredients?.map(ing => { // data cleanse
		if (ing.droppableId === undefined) delete ing.droppableId;
		return ing;
	});
	dispatch(
		updateDocumentThunk(recipeDoc, 'recipes', 'recipe', LOAD_RECIPE, true)
	);
	if (recipeDoc?.recipeId) {
		dispatch(updateDocumentThunk(recipeDoc, 'publicRecipes', 'publicRecipe', LOAD_PUBLIC_RECIPE));
	}
}

export const getRecipeRows = async (uid, sortletter, next, pageSize) => {
	let q = null;
	if (next) {
		q = next;
	} else {
		q = query(
			collection(db, `users/${uid}/recipes`),
			where('sortletter', '==', sortletter),
			orderBy('sortname'),
			limit(pageSize)
		);
	}
	let recipes = [];
	try {
		const documentSnapshots = await getDocs(q);
		const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
		if (lastVisible) {
			next = query(
				collection(db, `users/${uid}/recipes`),
				where('sortletter', '==', sortletter),
				orderBy('sortname'),
				startAfter(lastVisible),
				limit(pageSize)
			);
		}

		documentSnapshots.forEach(dbDocument => {
			recipes.push({ id: dbDocument.id, ...dbDocument.data() });
		});
	} catch (err) {
		throw err;
	}
	return [recipes, next];
};

export function getRecipeRowsThunk({ next = null, recipeLetter, pageSize = 8 }) {
	return async function inner(dispatch, getState) {
		try {
			const state = getState();
			if (!state.user) return;
			const [recipes, nextPage] = await getRecipeRows(
				state.user.uid, recipeLetter, next, pageSize
			);
			dispatch({
				type: actions.LOAD_RECIPES_INFINITE,
				payload: {
					recipes,
					recipeLetter,
					source: 'storeRecipes',
					nextRecipePage: nextPage,
				}
			});
		} catch (err) {
			if (err === ERROR_MSGS.recipe404) {
				handleError(
					{
						msg: err
					},
					dispatch
				);
			}
		}
	};
}