import React, { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styles from '../../styles/Home.module.scss';
import { Badge, Box, Center, Container, createStyles, Grid, Text, useMantineTheme } from '@mantine/core';
import { IconGripVertical, IconSquare, IconSquareCheck } from '@tabler/icons';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { useBuildIngredientLabel } from '../Recipes/RecipeRow';
import { useSelector } from 'react-redux';
import useWindow from '../../hooks/useWindow';

const grid = 8;

const getItemStyle = (
	draggableStyle,
	snapshot,
	isPublic,
	preferences,
	bagged,
) => {
	let backgroundColor = snapshot.isDragging
		? preferences.secondary
		: preferences.primary;
	if (bagged) backgroundColor = preferences.baggedColor;
	const defaultStyle = {
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',
		padding: '4px',
		margin: `0 auto ${grid}px auto`,
		borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
		width: `100%`, // 100% = 350px, 99% = 353px, 95% = 368px
		backgroundColor,
		color: preferences.color,
		border: `1px outset ${backgroundColor}`,
		// boxShadow: `rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset`,
		// styles we need to apply on draggables
		...draggableStyle
	};

	if (!snapshot.isDropAnimating) {
		return defaultStyle;
	}
	// const { moveTo, curve, duration } = snapshot.dropAnimation;
	// move to the right spot
	// const translate = `translate(${moveTo.x + 25}px, ${moveTo.y}px)`;
	// const translate2 = `translate(${moveTo.y}px, ${moveTo.x + 25}px)`;
	// add a bit of turn for fun
	// const rotate = 'rotate(0.5turn)';

	return {
		...defaultStyle
		// ...draggableStyle,
		// transform: `${translate}, ${translate2}`,
		// slowing down the drop because we can
		// transition: `${translate} ${curve} ${duration + 0.2}s. `,
	};
};

const useStyles = createStyles((theme, { color = 'default', dark }) => ({
	dark,
	header: {
		backgroundColor: theme.fn.variant({ variant: 'filled', color, }).background,
		borderBottom: 0,
	},

	title: {
		color: 'white',
		display: 'inline-block',
		order: 1,
	},

	inner: {
		alignItems: 'center',
	},
}));

const DraggableRow = ({
	draggableId,
	listItem,
	index,
	mainActionFn,
	isPublic = false,
	preferencesOverride = null,
	baggedItem
}) => {
	const shoppingListRecipes = useSelector(state => state.shoppingListRecipes);
	let { preferences } = useContext(PreferencesContext);
	const theme = useMantineTheme();
	const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
	const recipe = shoppingListRecipes?.find(slr => slr.id === listItem.recipeId);
	const { width } = useWindow();
	
	if (preferencesOverride) preferences = preferencesOverride;
	
	const hasBadge = (
		listItem?.recipeId
		&& shoppingListRecipes?.find(slr => slr.id === listItem?.recipeId)?.name
		&& listItem.droppableId !== recipe?.name
	);
	const ingredientLabel = useBuildIngredientLabel(listItem);

	return (
		<Draggable key={draggableId} draggableId={draggableId} index={index}>
			{(provided, snapshot) => {
				const itemStyle = getItemStyle(
					provided.draggableProps.style,
					snapshot,
					isPublic,
					{
						color: 'white',
						// primary: theme.colors[theme.primaryColor][theme.other.rowColorHueIndex],
						primary: theme.colors[theme.primaryColor][4],
						secondary: theme.colors[theme.primaryColor][4],
						baggedColor: theme.colors[theme.primaryColor][6],
					},
					listItem.bagged,
				);
				const badgeText = (
					<p style={{ color: 'white' }}>
						{shoppingListRecipes?.find(slr => slr.id === listItem?.recipeId)?.name}
					</p>
				);
				return (
					<Container
						p={0}
						key={index}
						className={[
							// snapshot.isDragging
							// 	? styles.item_row__drag
							// 	: styles.item_row__static,
							listItem.bagged ? styles.item__row_bagged : ''
						].join(' ')}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={itemStyle}
					>
						<Grid
							className={classes.inner}
							columns={24}
						>
							<Grid.Col span={width < 800 ? 18 : 20} onClick={() => mainActionFn(listItem)} order={preferences.rightHanded ? 1 : 3}>
								{hasBadge && (
									<Center w={50}>
										<Box
											p={0}
											m={0}
											radius={process.env.REACT_APP_BORDER_RADIUS_SM}
											variant="solid"
										>
											<Text italic size={'sm'} p={0} m={0} pt={'0.2rem'} pb={'0.2rem'}>
												{shoppingListRecipes?.find(slr => slr.id === listItem?.recipeId)?.name}
											</Text>
										</Box>
									</Center>
								)}
								<Box>
									{ingredientLabel}
								</Box>
							</Grid.Col>
							<Grid.Col pt={'md'} span={width < 800 ? 3 : 2} order={preferences.rightHanded ? 3 : 1}>
								<div style={{ float: preferences.rightHanded ? 'right' : 'left'}}>
									<IconGripVertical size={32} />
								</div>
							</Grid.Col>
							<Grid.Col pt={'md'} span={width < 800 ? 3 : 2} order={2}>
								<div style={{ float: preferences.rightHanded ? 'right' : 'left'}}>
									{listItem.bagged ? (
									<IconSquareCheck onClick={() => baggedItem(listItem)} size={32} />
									) : (
										<IconSquare onClick={() => baggedItem(listItem)} size={32} />
									)}
								</div>
							</Grid.Col>
						</Grid>
					</Container>
				);
			}}
		</Draggable >
	);
};

export default DraggableRow;
