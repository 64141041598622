import * as actions from '../redux/actions';
import { getDocument, getDocumentsByAttribute, getDocumentSingleton } from './generic';
import { getRecipesMeta } from './recipes';
import { getSettings } from './users';
import { getCurrentVersion } from './versions';

export function authoriseUserThunk(authUser) {
	return async function inner(dispatch, getState) {
		try {
			const state = getState();
			if (!authUser) return;
			const uid = authUser?.uid;
			const user = await getDocument(uid, 'users');
			const profile = state.profile || await getDocumentSingleton('profiles', uid);
			const [publicProfileDoc, publicProfilesNextPage] = await getDocumentsByAttribute(
				'publicProfiles',
				{ field: 'profileId', value: profile.id },
				null,
				null,
				true,
				true
			);
			const publicProfile = state.publicProfile || publicProfileDoc;
			const recipesMeta = state.recipesMeta || await getRecipesMeta(authUser.uid);
			const settings = state.auth?.dbVersion || await getSettings(uid);
			const version = state.version || await getCurrentVersion(authUser.uid);
			dispatch({
				type: actions.LOAD_AUTHORISATION,
				payload: {
					auth: {
						dbVersion: settings?.dbVersion,
						active: user?.active
					},
					recipesMeta,
					profile,
					publicProfile: publicProfile || null,
					version: version?.active ? version : { error: true }
				}
			});
		} catch (e) {
			// error authorising user - logging out
			dispatch({
				type: actions.SET_USER,
				payload: {
					user: null,
					isLoggedIn: false,
					isLoggedOut: true,
					sessionExpired: true,
				}
			});
		}
	};
}
