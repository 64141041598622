import React, { useContext, useEffect, useState } from "react";
import { ActionIcon, Button, Card, Center, createStyles, Grid, Group, Menu, Title, useMantineTheme } from "@mantine/core";
import { PreferencesContext } from "../../config/context/preferencesContext";
import { useSwiper } from 'swiper/react';
import { DangerousActionModal } from "../Inputs/DangerousActionModal";
import { swingAnimation } from '../../App';
import 'swiper/css';
import "swiper/css/effect-flip";
import { IconArrowRight, IconBookmarkOff, IconCaretDown, IconTrash } from "@tabler/icons";

const useStyles = createStyles((theme, { color = 'default', dark, clicked }) => {
  return ({
    deleteButton: {
      backgroundColor: theme.colors['warning'][7]
    },
    box: {
      display: 'flex',
      backgroundImage: `linear-gradient(-60deg, ${theme.colors[theme.primaryColor][4]} 0%, ${theme.colors[theme.primaryColor][7]
        } 100%)`,
      padding: theme.spacing.xl * theme.other.rowItemPaddingModifier,
      borderRadius: theme.radius.sm,
      flexDirection: 'column',
    },
    boxOptions: {
      backgroundColor: theme.colors[color][theme.other.rowColorHueIndex],
      borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
      color: 'white',
      padding: '1rem',
      marginLeft: '1rem',
      marginRight: '1rem',
    },
    inner: {
      position: 'relative',
      zIndex: 1,
    },
    title: {
      ...theme.other.preventSelection,
      textAlign: 'center',
      letterSpacing: 1,
      color: clicked ? 'white' : color,

      [`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
        fontSize: 18,
        textAlign: 'center',
      },
    },
    helpSwiper: {
      position: 'absolute',
      top: '25%',
      right: '10%',
      textAlign: 'center',
      animation: `${swingAnimation} 1s infinite`,
      zIndex: 5000,
    },
  });
});

export const SwiperOptionsPanel = ({ deleteAction, parentRef }) => {
  const { preferences } = useContext(PreferencesContext);
  const theme = useMantineTheme();
  const { classes } = useStyles({
    color: theme.primaryColor,
    dark: preferences.dark
  });
  const swiper = useSwiper();

  const onDeleteAction = () => {
    swiper.slideTo(swiper.previousIndex, 100, false)
    swiper.update();
    deleteAction?.deleteFn();
  }

  return (
    <Center
      className={classes.box}
      sx={{
        height: parentRef?.current?.offsetHeight,
        paddingTop: 'auto',
        paddingBottom: 'auto',
        ...theme.other.lineItem,
      }}
    >
      <Group position="center">
        <Button
          onClick={onDeleteAction}
          uppercase
          className={classes.deleteButton}
          leftIcon={deleteAction.deleteLabel === 'Unbookmark Recipe' ? <IconBookmarkOff /> : <IconTrash />}
        >
          {deleteAction?.deleteLabel}
        </Button>
      </Group>
    </Center>
  );
};

export const RowButton = ({
  onClickFn,
  title,
  rowTypeLabel,
  deleteAction,
  rightIcon = { highlighted: false, iconComponent: undefined },
}) => {
  const {  preferences } = useContext(PreferencesContext);
  const theme = useMantineTheme();
  const [deleting, setDeleting] = useState(false);
  const [clicked, setClicked] = useState(false);
  const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark, clicked });

  const { deleteFn, deleteLabel } = deleteAction;
  const { iconComponent, highlighted } = rightIcon;

  useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        onClickFn();
        setClicked(false);
      }, 300);
    }
  }, [clicked, onClickFn]);

  const color = theme.colorScheme === 'dark' ? 'white' : theme.colors.gray[7];
  let options = (
    iconComponent ? (
      <ActionIcon
        loading={clicked}
        onClick={() => setClicked(clicked => true)}
        sx={{
          backgroundColor: highlighted ? theme.colors[theme.primaryColor][6] : undefined,
        }}
      >
        {iconComponent}
      </ActionIcon>
    ) : (
      <Menu withinPortal position="bottom-end" shadow="sm" sx={{ float: 'right' }}>
        <Menu.Target>
          <ActionIcon>
            <IconCaretDown color={clicked ? 'white' : theme.colors[theme.primaryColor][4]} size={16} />
            {/* <IconCaretDown color={theme.colors[theme.primaryColor][4]} size={16} /> */}
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<IconArrowRight size={14} />} onClick={onClickFn}>
            View {rowTypeLabel}
          </Menu.Item>
          <Menu.Item icon={<IconTrash size={14} />} color="red" onClick={() => setDeleting(true)}>
            {deleteLabel}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    )
  );
  if (!iconComponent && !deleteLabel) {
    options = null;
  }

  return (
    <>
      <Card
        shadow="sm"
        radius="md"
        mb={'lg'}
        sx={{
          border: `1px solid ${theme.colors[theme.primaryColor][9]}`,
          ...theme.other.lineItem,
        }}
      >
        <Card.Section inheritPadding py="xs" sx={{ backgroundColor: clicked ? theme.colors[theme.primaryColor][6] : undefined }}>
          <Grid align={'center'}>
            <Grid.Col span={2} onClick={() => setClicked(clicked => true)} />
            <Grid.Col span={8} onClick={() => setClicked(clicked => true)}>
              <Title
                className={classes.title}
                order={3}
                weight={500}
              >
                {title}
              </Title>
            </Grid.Col>
            <Grid.Col span={2}>
              {options}
            </Grid.Col>
          </Grid>
        </Card.Section>
      </Card>
      <DangerousActionModal
        title={`Delete ${rowTypeLabel}`}
        onDelete={() => {
          deleteFn();
          setDeleting(false);
        }}
        onCancel={() => setDeleting(false)}
        visible={deleting}
      />
    </>
  );
};
