import React from 'react';
import { useParams } from 'react-router-dom';

import Profile from '../../components/Profiles/Profile';
import { useDispatch } from 'react-redux';
import { CLEAR_NEXT } from '../../redux/actions';

const ProfilePage = () => {
	const dispatch = useDispatch();
	const params = useParams();
	// dispatch({
	// 	type: CLEAR_NEXT,
	// 	payload: {}
	// });
	return <Profile id={params.id} />;
};

export default ProfilePage;
