/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Container,
  createStyles,
  Loader,
  Modal,
  Space,
  Tabs,
  Text,
  Title,
  useMantineTheme
} from '@mantine/core';
import { useFullscreen } from '@mantine/hooks';
import { IconBasket, IconBookmarkOff, IconFileText, IconSettings, IconTrash } from '@tabler/icons';

import SideControls from '../Inputs/SidebarControls';

import { PreferencesContext } from '../../config/context/preferencesContext';
import { useHeaderMenu } from '../Layout/HeaderMenu';
import { BackButton } from '../Layout/BackButton';
import useWindow from '../../hooks/useWindow';
import { HelpWrapper } from '../Utils/HelpWrapper';
import { LOAD_RECIPE_PUBLIC_PROFILE } from '../../redux/actions';
import { TitleText } from '../Layout/TitleText';
import { acknowledgePlannerShareThunk, getPlannerThunk } from '../../model/planners';
import { ChefList } from '../ChefList/ChefList';
import { PlannerShareModal } from '../Inputs/PlannerShareModal';

const useStyles = createStyles((theme, { color = 'default', dark }) => {
  return ({
    wrapper: {
      position: 'relative',
      width: '100%',
    },

    deleteButton: {
      backgroundColor: theme.colors['warning'][7]
    },

    detailBarItem: {
      ...theme.other.detailBarItem,
    },

    backBtn: {
      ...theme.other.backBtn,
    },

    innerContainer: {
      ...theme.other.innerContainer,
    },

    title: {
      ...theme.other.title,
    },

    highlight: {
      color: 'white'
    }
  });
});

const mapRolesToChefs = (userRoleArray, roleType) => {
  const chefs = [];
  userRoleArray.map(userRole => {
    const userChef = {
      name: userRole.name,
      publicProfileId: userRole.id,
      role: roleType,
    };
    chefs.push(userChef);
  });
  return chefs;
}

const PlannerShare = ({ id }) => {
  const { preferences, setScrollLocked } = useContext(PreferencesContext);
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const plannerShareLink = useSelector(state => state.plannerShareLink);
  const plannerShareLinkAcknowledged = useSelector(state => state.plannerShareLinkAcknowledged);
  const [counter, setCounter] = useState(9);
  const [loading, setLoading] = useState(true);
  const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });

  useEffect(() => {
    dispatch(acknowledgePlannerShareThunk(id));
  }, []);

  useEffect(() => {
    if (plannerShareLinkAcknowledged !== undefined) setLoading(false);
  }, [plannerShareLinkAcknowledged]);

  useEffect(() => {
    if (loading && plannerShareLink) {
      setTimeout(() => setLoading(false), process.env.REACT_APP_LAG_NAV_MS);
    }
  }, [plannerShareLink]);

  useEffect(() => {
		let timer;
		if (counter > 0) {
			timer = setTimeout(() => setCounter(c => c - 1), 1000);
		}
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [counter]);

	if (counter === 0) navigate('/planners', { replace: true });


  const HeaderMenu = useHeaderMenu({ help: true, disabled: false });
  const { width } = useWindow();

  return (
    <>
      <HeaderMenu />
      <Container px={0} sx={{ ...theme.other.wrapper }}>
        <Container my={'xs'}>
          <BackButton defaultFrom={'Menu'} />
          {/* <HelpWrapper
            feature={process.env.REACT_APP_HELP_RECIPE_TITLE}
            position={'middle-end'}
          > */}
          <TitleText title={'Share Link Activation'} />
          {/* </HelpWrapper> */}
          {!loading && plannerShareLinkAcknowledged && (
            <>
              <Title p={'md'} order={4}>You now have access to this planner.</Title>
            </>
          )}
          {!loading && !plannerShareLinkAcknowledged && (
            <>
              <Title p={'md'} order={4}>That activation link has either expired or has been revoked.</Title>
            </>
          )}
          {!loading && (
            <Center mt={'xl'}>
              <Button
                variant={'white'}
                size={'lg'}
                onClick={() => navigate('/planners')}
              >
                Your Planners <Text color={'dimmed'} sx={{ fontSize: '14px', paddingLeft: '0.5rem', width: '1rem' }}>{counter}</Text>
              </Button>
            </Center>
          )}
          {loading && (
            <Center>
              <Loader size={50} />
            </Center>
          )}
        </Container>
      </Container>
    </>
  );
};

export default PlannerShare;
