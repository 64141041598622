/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  createStyles,
  Grid,
  Indicator,
  Loader,
  LoadingOverlay,
  Skeleton,
  Space,
  Text,
  Title,
  useMantineTheme
} from '@mantine/core';
import { IconArrowBack } from '@tabler/icons';

import SideControls from '../Inputs/SidebarControls';

import { PreferencesContext } from '../../config/context/preferencesContext';
import { useHeaderMenu } from '../Layout/HeaderMenu';
import { BackButton } from '../Layout/BackButton';
import useWindow from '../../hooks/useWindow';
import { HelpWrapper } from '../Utils/HelpWrapper';
import { CLEAR_RECIPE, CLEAR_RECIPES } from '../../redux/actions';
import { TitleText } from '../Layout/TitleText';
import { getPlannerEventsThunk, getPlannerThunk } from '../../model/planners';
import { PlannerShareModal } from '../Inputs/PlannerShareModal';
import { Calendar } from '@mantine/dates';
import { getLongDayStringFromDate, getMonthStringFromDate } from '../../utils/time';
import { PlannerEventModal } from '../Inputs/PlannerEventModal';
import PlannerEventRow from './PlannerEventRow';

const useStyles = createStyles((theme, { color = 'default', dark }) => {
  return ({
    wrapper: {
      position: 'relative',
      width: '100%',
    },

    deleteButton: {
      backgroundColor: theme.colors['warning'][7]
    },

    detailBarItem: {
      ...theme.other.detailBarItem,
    },

    backBtn: {
      ...theme.other.backBtn,
    },

    innerContainer: {
      ...theme.other.innerContainer,
    },

    title: {
      ...theme.other.title,
    },

    highlight: {
      color: 'white'
    }
  });
});

const Planner = ({ id, isPublic = false }) => {
  const { preferences, setScrollLocked } = useContext(PreferencesContext);
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const planner = useSelector(state => state.planner);
  const profile = useSelector(state => state.profile);
  const plannerEvents = useSelector(state => state.plannerEvents);
  const databaseWorking = useSelector(state => state.databaseWorking);
  const [loading, setLoading] = useState(true);
  const [addingEvent, setAddingEvent] = useState();
  const [meal, setMeal] = useState('');
  const [event, setEvent] = useState(undefined);
  const [deletingEvent, setDeletingEvent] = useState(undefined);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [day, setDay] = useState();
  const [calendar, setCalendar] = useState(new Date());
  const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
  const { width } = useWindow();
  const userCanEdit = (
    planner?.admins.filter(e => e.id === profile.id).length > 0
    || planner?.editors.filter(e => e.id === profile.id).length > 0
  );
    
  useEffect(() => {
    dispatch(getPlannerThunk(id));
    const today = new Date();
    dispatch(getPlannerEventsThunk(id, today.getMonth(), today.getFullYear()));
    dispatch({ type: CLEAR_RECIPE, payload: {} });
    dispatch({ type: CLEAR_RECIPES, payload: {} });
    // dispatch({ type: CLEAR_PLANNER_EVENTS, payload: {} });
  }, []);

  useEffect(() => {
    if (event) setAddingEvent(true);
  }, [event]);

  useEffect(() => {
    if (plannerEvents || plannerEvents?.length === 0) setLoading(false);
  }, [plannerEvents]);

  useEffect(() => {
    if (planner) {
      let date = new Date();
      if (day) {
        date = day;
      }
    }
  }, [day]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => setLoading(false), process.env.REACT_APP_LAG_NAV_MS);
    }
  }, [planner]);


  const HeaderMenu = useHeaderMenu({ help: true, disabled: false });

  const toggleShareModal = () => setShareModalVisible(!shareModalVisible);
  const toggleEventModal = () => {
    setEvent(undefined);
    setAddingEvent(!addingEvent);
  }

  const displayDateString = getLongDayStringFromDate(new Date(Date.parse(day)));
  const data = []
  data.push(
    { category: "takeaway", cost: 25.00, name: "takeaway meal", recipeId: undefined },
    { category: "recipe", cost: "0.00", name: "recipe.name", recipeId: "recipe.id" },
    { category: "recipe", cost: "0.00", name: "recipe.name", recipeId: "recipe.id" },
    { category: "recipe", cost: "0.00", name: "recipe.name", recipeId: "recipe.id" },
    { category: "takeaway", cost: 25.00, name: "takeaway meal", recipeId: undefined },
  );

  const handleMonthChange = (date) => {
    setLoading(true);
    dispatch(getPlannerEventsThunk(id, date.getMonth(), date.getFullYear()));
    return date;
  };

  const renderDay = date => {
    const dayInput = date.getDate();
    let dayComponent = <div>{dayInput}</div>;
    const hasEvents = plannerEvents?.filter(evt => evt.day === dayInput).length > 0;
    if (hasEvents) {
      dayComponent = (
        <Box sx={{ outline: `2px solid ${preferences.dark ? '#626262' : theme.primaryColor}`, outlineOffset: '-3px', borderRadius: '3px' }}>
          <div>{dayInput}</div>
        </Box>
      );
    }
    return dayComponent;
  };

  const filteredEventsByDay = () => {
    return plannerEvents?.filter(evt => {
      if ([evt.year, evt.month, evt.day].join('-')
        === [day?.getFullYear(), day?.getMonth(), day?.getDate()].join('-') ) {
        return true
      }
      return false;
    });
  }
  const skeletonRow = <Skeleton visible height={55} radius="sm" />;
  const eventRow = (event, key) => {
    return (
      <Grid.Col key={key}>
        <Center>
          {deletingEvent === event.id ? (
            skeletonRow
          ) : (
            <PlannerEventRow
            key={key}
            createdBy={event.createdBy}
            name={event.recipeName}
            onClick={() => setEvent(event)}
            showButton={event.category === 'recipe'}
            onClickButton={() => navigate(`/recipes/${event.recipeId}`)}
          />
          )}
        </Center>
      </Grid.Col>
    )
  };

  const renderEventModal = (userCanEdit) => {
    const modal = (
      <PlannerEventModal
        userCanEdit={userCanEdit}
        eventInput={event}
        dayString={displayDateString}
        dateObj={day}
        visible={addingEvent}
        onCancel={(eventId) => {
          toggleEventModal();
          setDeletingEvent(eventId);
        }
        }
        preferences={preferences}
        defaultMeal={meal}
      />
    );
    return addingEvent && modal;
  }

  const handleAddMealClick = (label) => {
    setMeal(label);
    toggleEventModal();
  }
  const addButton = (label, isEmpty, hide) => {
    if (hide) return;
    // userCanEdit && day && toggleEventModal
    const displayLabel = isEmpty ? `No ${label} planned` : `Add ${label}`;
    return (
      <Center>
        <Button disabled={!userCanEdit} onClick={() => handleAddMealClick(label)} variant="light" radius="xl" size="xs">
          {displayLabel}
        </Button>
      </Center>
    );
  }

  const monthlyCost = plannerEvents?.reduce(function (acc, obj) { return acc + Number(obj.cost); }, 0);
  const eventList = filteredEventsByDay();

  let totalCost = 0.00;
  let totalBreakfastCost = 0.00;
  let totalLunchCost = 0.00;
  let totalDinnerCost = 0.00;
  if (eventList?.length > 0) {
    totalCost = eventList.reduce(function (acc, obj) { return acc + Number(obj.cost); }, 0);
    totalBreakfastCost = eventList.filter(evt => evt.mealTime === 'breakfast').reduce(function (acc, obj) { return acc + Number(obj.cost); }, 0);
    totalLunchCost = eventList.filter(evt => evt.mealTime === 'lunch').reduce(function (acc, obj) { return acc + Number(obj.cost); }, 0);
    totalDinnerCost = eventList.filter(evt => evt.mealTime === 'dinner').reduce(function (acc, obj) { return acc + Number(obj.cost); }, 0);
  }

  const breakfastMeals = eventList?.filter(evt => evt.mealTime === 'breakfast');
  const lunchMeals = eventList?.filter(evt => evt.mealTime === 'lunch');
  const dinnerMeals = eventList?.filter(evt => evt.mealTime === 'dinner');

  return (
    <>
      <HeaderMenu hideHelp={isPublic} />
      <Container px={0} sx={{ ...theme.other.wrapper }}>
        <Container my={'xs'}>
          <BackButton defaultFrom={'Planner'} />
          <HelpWrapper
            feature={process.env.REACT_APP_HELP_PLANNER_TITLE}
            position={'middle-end'}
          >
          <TitleText title={planner?.name} />
          </HelpWrapper>
          {(
            <>
              <Skeleton visible={!planner?.id || loading || !plannerEvents}>
                <Container sx={{ position: 'relative' }}>
                  <Center>
                    <Calendar
                      fullWidth
                      value={calendar}
                      sx={{ display: !!day ? 'none' : 'block' }}
                      onChange={date => {
                        setDay(date);
                        setCalendar(date);
                      }}
                      onMonthChange={date => {
                        setCalendar(date);
                        handleMonthChange(date);
                      }}
                      renderDay={renderDay}
                      size={width > 800 ? 'xl' : 'md'}
                    />
                  </Center>
                  <Space h={'lg'} />
                  {!day && monthlyCost > 0 && (
                    <Center mt={'xl'}>
                      <Badge size={'xl'} sx={{ float: 'right' }}>
                        {!day && getMonthStringFromDate(calendar)} Total: ${parseFloat(monthlyCost).toFixed(2)}
                      </Badge>
                    </Center>
                  )}
                  {day && (
                    <>
                      <Grid grow>
                        <Grid.Col span={'auto'}>
                          <Center>
                            <Indicator
                              onClick={() => setDay(undefined)}
                              inline
                              processing
                              size={26}
                              color="red"
                              label={<IconArrowBack size={16} />}
                              position={'middle-end'}
                            >
                              <Button onClick={() => setDay(undefined)}>{displayDateString}</Button>
                            </Indicator>
                          </Center>
                        </Grid.Col>
                          {
                            totalCost > 0 && (
                              <Grid.Col span={'auto'} >
                                <Badge size={'xl'} sx={{ float: 'right' }}>
                                  ${parseFloat(totalCost).toFixed(2)}
                                </Badge>
                              </Grid.Col>
                              )
                          }
                      </Grid>
                      <Space h={'lg'} />
                      <Grid grow>
                        <Grid.Col span={'auto'}>
                          <Title sx={{ textAlign: 'left'}} order={3}>Breakfast</Title>
                        </Grid.Col>
                        {
                          totalBreakfastCost > 0 && (
                            <Grid.Col span={'auto'}>
                              <Badge size={'xl'} sx={{ float: 'right' }}>
                                ${parseFloat(totalBreakfastCost).toFixed(2)}
                              </Badge>
                            </Grid.Col>
                          )
                        }
                      </Grid>
                      <Space h={'lg'} />
                      <Grid grow>
                        {breakfastMeals?.map((evt, idx) => {
                          return eventRow(evt, `breakfast-meal-${idx}`);
                        })}
                        <Grid.Col>
                          {databaseWorking && !deletingEvent && meal === 'breakfast' ? (
                            skeletonRow
                          ) : addButton('breakfast', breakfastMeals?.length === 0, !userCanEdit)}
                        </Grid.Col>
                      </Grid>
                      <Grid grow>
                        <Grid.Col span={'auto'}>
                          <Title sx={{ textAlign: 'left' }}order={3}>Lunch</Title>
                        </Grid.Col>
                          {
                            totalLunchCost > 0 && (
                              <Grid.Col span={'auto'}>
                                <Badge size={'xl'} sx={{ float: 'right' }}>
                                  ${parseFloat(totalLunchCost).toFixed(2)}
                                </Badge>
                              </Grid.Col>
                            )
                          }
                      </Grid>
                      <Space h={'lg'} />
                      <Grid grow>
                        {eventList?.filter(evt => evt.mealTime === 'lunch')?.map((evt, idx) => {
                          return eventRow(evt, `lunch-meal-${idx}`);
                        })}
                        <Grid.Col>
                          {databaseWorking && !deletingEvent && meal === 'lunch' ? (
                            skeletonRow
                          ) : addButton('lunch', lunchMeals?.length === 0, !userCanEdit)}
                        </Grid.Col>
                      </Grid>
                      <Grid grow>
                        <Grid.Col span={'auto'}>
                          <Title sx={{ textAlign: 'left'}} order={3}>Dinner</Title>
                        </Grid.Col>
                          {
                            totalDinnerCost > 0 && (
                              <Grid.Col span={'auto'}>
                                <Badge size={'xl'} sx={{ float: 'right' }} ml={'lg'}>
                                  ${parseFloat(totalDinnerCost).toFixed(2)}
                                </Badge>
                              </Grid.Col>
                            )
                          }
                      </Grid>
                      <Space h={'lg'} />
                      <Grid grow>
                        {eventList?.filter(evt => evt.mealTime === 'dinner')?.map((evt, idx) => {
                          return eventRow(evt, `dinner-meal-${idx}`);
                        })}
                        <Grid.Col>
                          {databaseWorking && !deletingEvent && meal === 'dinner' ? (
                            skeletonRow
                          ) : addButton('dinner', dinnerMeals?.length === 0, !userCanEdit)}
                        </Grid.Col>
                      </Grid>
                    </>
                  )}
                </Container>
              </Skeleton>
            </>
          )}
        </Container>
        <Space h={"xl"} />
        <Space h={"xl"} />
        <Space h={"xl"} />
        <Space h={"xl"} />
        <Space h={"xl"} />
      </Container>
      <SideControls
        isPublic={false}
        // add={userCanEdit && day && toggleEventModal}
        adding={addingEvent}
        share={(
          profile
          && planner
          && planner.admins.filter(a => a.id === profile.id).length > 0
          && toggleShareModal) || undefined}
        source={'planner'}
        helpAdd={process.env.REACT_APP_HELP_PLANNER_ADD_BUTTON}
        hideShare={
          profile && planner && planner.admins.filter(a => a.id === profile.id).length === 0
        }
      />
      <PlannerShareModal
        visible={shareModalVisible}
        onCancel={toggleShareModal}
        preferences={preferences}
      />
      {renderEventModal(userCanEdit)}
    </>
  );
};

export default Planner;
