import { Button, Popover, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";
import { useState } from "react";


export const PopoverButton = ({
  leftIcon,
  onClick,
  variant,
  clickMsg,
  width,
}) => {
  const [opened, setOpened] = useState(false);
  const [clearTimeout, setClearTimeout] = useState(null);
  const handleClick = () => {
    if (!onClick) return;
    try {
      onClick();
      setOpened(true);
      if (!clearTimeout) {
        const timeout = setTimeout(() => {
          setOpened(false);
          setClearTimeout(null);
        }, 2500);
        setClearTimeout(timeout);
      }
    } catch (err) {
      showNotification({
        id: `popover-button`,
        disallowClose: true,
        autoClose: 5000,
        title: "Oops!",
        message: err.message,
        color: 'red',
        icon: <IconX />,
        loading: false,
      });
    };
  };
  return (
    <Popover width={width} position="top" withArrow shadow="md" opened={opened}>
      <Popover.Target>
        <Button
          variant={variant}
          onClick={handleClick}
          leftIcon={leftIcon}
        >
          Copy
        </Button>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
        <Text sx={{ textAlign: "center" }} size="sm">{clickMsg}</Text>
      </Popover.Dropdown>
    </Popover>
  );
};