/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { Box, Button, Center, createStyles, Grid, Select, Switch, Textarea, TextInput, useMantineTheme } from '@mantine/core';

import { capitalizeFirstLetter, textToIngredients } from '../../utils/textHandling';
import { FloatingLabelInput, FloatingLabelSelectInput } from '../Inputs/FloatingLabelInput';

export const INGREDIENT_TYPES = [
	{ value: 'none', label: '(Select a Unit)' },
	{ value: 'millilitres', label: 'Millilitres' },
	{ value: 'litres', label: 'Litres' },
	{ value: 'grams', label: 'Grams' },
	{ value: 'kilograms', label: 'Kilograms' },
	{ value: 'cups', label: 'Cups' },
	{ value: 'teaspoons', label: 'Teaspoons' },
	{ value: 'tablespoons', label: 'Tablespoons' },
	{ value: 'bag', label: 'Bag' },
	{ value: 'bunch', label: 'Bunch' },
	{ value: 'loaf', label: 'Loaf/loaves' },
	{ value: 'pack', label: 'Pack' },
	{ value: 'pieces', label: 'Pieces' },
];

const useStyles = createStyles(theme => {
	return ({
		deleteButton: {
			backgroundColor: theme.colors['warning'][7]
		}
	});
});

export const IngredientForm = ({
	source = 'ingredient',
	ingredient,
	onSubmit,
	onCancel,
	onDelete
}) => {
	const [name, setName] = useState(ingredient?.name || '');
	const [amount, setAmount] = useState(ingredient?.amount || '');
	const [type, setType] = useState(ingredient?.type || 'none');
	const [showListMode, setShowListMode] = useState(false);
	const [listText, setListText] = useState('');
	const theme = useMantineTheme();
	const { classes } = useStyles();

	const onDeleteIngredientSubmit = () => {
		onDelete(ingredient);
		onCancel();
	};

	const submitIngredients = ingredients => {
		onSubmit(ingredients);
		onCancel();
	};

	const onEnter = event => {
    if (!showListMode) {
			submitIngredients([{
				id: ingredient?.id,
				name,
				amount,
				type,
				droppableId: ingredient?.droppableId
			}])
		}
  };

	const listIngredientForm = (
		<Box sx={{ maxWidth: 300 }} mx="auto">
			<Textarea
				mb={"lg"}
				placeholder="You can type a list of ingredients here and we'll add each row to your list."
				minRows={10}
				maxRows={10}
				value={listText}
				onChange={evt => setListText(evt.target.value)}
			/>
		</Box>
	);

	const singleIngredientForm = (
		<Box sx={{ maxWidth: 300 }} mx="auto">
			<FloatingLabelInput
				autoFocus={true}
				mt={"2rem"}
				label={capitalizeFirstLetter(`${source} name`)}
				placeholder={`${source} name`}
				value={name}
				onChange={(event) => setName(event.currentTarget.value)}
				required
				onEnter={onEnter}
			/>
			<FloatingLabelInput
				mt={"2rem"}
				label={capitalizeFirstLetter(`${source} amount`)}
				placeholder={`${source} amount`}
				value={amount}
				onChange={(event) => setAmount(event.currentTarget.value)}
			/>
			<FloatingLabelSelectInput
				mt={"2rem"}
				mb={"xl"}
				label={capitalizeFirstLetter(`${source} type`)}
				placeholder="(Select a Unit)"
				data={INGREDIENT_TYPES}
				value={type}
				onChange={setType}
			/>
		</Box>
	);
	return (
		<>
			{showListMode ? listIngredientForm : singleIngredientForm}
			<Grid justify={'space-around'} mt={'lg'}>
				<Center>
					{ingredient?.id ? (
						<Button
							onClick={onDeleteIngredientSubmit}
							className={classes.deleteButton}
						>
							Delete
						</Button>
					) : (
						<Switch
							onChange={() => setShowListMode(!showListMode)}
							label={showListMode ? "List Mode" : "List Mode"}
							onLabel="ON"
							offLabel="OFF"
							size={"md"}
							mb={'lg'}
						/>
					)}
				</Center>
				<Center>
					{showListMode ? (
						<Button
							disabled={!listText || listText === ""}
							onClick={() => submitIngredients(textToIngredients(listText))}
						>
							Save
						</Button>
					) : (
						<Button
							disabled={!name || name === ""}
							onClick={() => submitIngredients([{
								id: ingredient?.id,
								name,
								amount,
								type,
								droppableId: ingredient?.droppableId
							}])}
						>
							Save
						</Button>
					)}
				</Center>
			</Grid>
		</>
	);
};
