import { SET_ERROR } from '../redux/actions';
import { db } from '../config/firebase/firebase';
import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	startAfter,
	updateDoc,
	where,
} from "firebase/firestore";

export const getDocumentSingleton = async (docType, uid = null) => {
	let document = {};
	try {
		const q = query(collection(db, `users/${uid}/${docType}`));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(dbDocument => {
			document = { id: dbDocument.id, ...dbDocument.data() };
		});
	} catch (e) {
		console.error(`Error getting ${docType} document: `, e);
		return document;
	}
	return document;
};

export const getDocumentsByAttribute = async (
	docType,
	{ field, value },
	uid = null,
	orderField = 'sortname',
	noOrder = false,
	returnFirst = false,
	pageOptions,
) => {
	if (!field || !value) return;
	let docs = [];
	let nextPage = pageOptions?.nextPage;
	const pageSize = pageOptions?.pageSize || 1;
	try {
		const baseRef = uid
			? collection(db, `users/${uid}/${docType}`)
			: collection(db, docType);
		const constraints = [baseRef, where(field, '==', value)];
		if (!noOrder) {
			constraints.push(orderBy(orderField, 'asc'));
		}
		let q = query(...constraints);
		if (nextPage) {
			q = nextPage;
		}
		const documentSnapshots = await getDocs(q);
		const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
		if (lastVisible && pageOptions) {
			nextPage = query(
				...constraints,
				startAfter(lastVisible),
				limit(pageSize)
			);
		}
		documentSnapshots.forEach(dbDocument => {
			const document = { id: dbDocument.id, ...dbDocument.data() };
			docs.push(document);
		});
	} catch (e) {
		console.error('Error getting documents by attr: ', e);
		throw e;
	}
	if (docs.length === 0) docs = null;
	let returnDoc = docs;
	if (returnFirst) {
		if (docs && docs[0]){
			returnDoc = docs[0];
		}
	}
	return [returnDoc, nextPage];
};

export function getDocumentsByAttributeThunk(
	docType,
	attr,
	reduxType,
	docAction,
	uid = null,
	orderField,
	noOrder,
	returnFirst,
	pagingOptions = undefined,
) {
	return async function inner(dispatch, getState) {
		try {
			if (!docType) throw Error('no document type');
			const [documents, nextPage] = await getDocumentsByAttribute(docType, attr, uid, orderField, noOrder, returnFirst, pagingOptions);
			const dispatchDocument = {
				type: docAction,
				payload: {
					[reduxType]: returnFirst ? documents[0] : documents
				}
			};
			if (nextPage) {
				dispatchDocument.nextPage = nextPage;
			}
			dispatch(dispatchDocument);
		} catch (e) {
			console.error(e);
			dispatch({
				type: docAction,
				payload: {
					[reduxType]: { error: true }
				}
			});
		}
	};
}

export const getDocument = async (docId, docType, uid = null, activeOnly) => {
	if (!docId || typeof docId === 'object') return;
	let document = null;
	const path = uid ? `users/${uid}/${docType}` : docType;
	try {
		const q = query(doc(db, path, docId));
		const docSnapshot = await getDoc(q);
		if (docSnapshot.exists()) {
			document = { id: docId, ...docSnapshot.data() };
		}
	} catch (e) {
		throw Error(`Error getting ${docType} document: ${e}`);
	}
	return document;
};

export function getDocumentThunk(
	docId,
	docType,
	reduxType,
	docAction,
	uid = null,
	activeOnly = false
) {
	return async function inner(dispatch, getState) {
		try {
			if (!docId) throw Error('no document id');
			const document = await getDocument(docId, docType, uid, activeOnly);
			if (document === {}) throw Error('no document returned');
			dispatch({
				type: docAction,
				payload: {
					[reduxType]: document
				}
			});
		} catch (e) {
			console.error(e);
			dispatch({
				type: docAction,
				payload: {
					[reduxType]: { error: true }
				}
			});
		}
	};
}

export const getDocumentsByArray = async (
	docType,
	{ field, array },
	uid = null
) => {
	if (!field || !array || array === []) return;
	const documents = [];
	try {
		const path = uid ? `users/${uid}/${docType}` : docType;
		const q = query(
			collection(db, path),
			where(field, 'array-contains-any', array)
		)
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(dbDocument => {
			documents.push({ id: dbDocument.id, ...dbDocument.data() });
		});
		return documents;
	} catch (e) {
		console.error(`Error getting ${docType} document: `, e);
		return documents;
	}
};

export function getDocumentsByArrayThunk(
	docType,
	termArray,
	reduxType,
	docAction,
	uid = null
) {
	return async function inner(dispatch, getState) {
		try {
			if (!docType) throw Error('no document type');
			const documents = await getDocumentsByArray(docType, termArray, uid);
			if (documents === []) throw Error('no documents returned');
			dispatch({
				type: docAction,
				payload: {
					[reduxType]: documents
				}
			});
		} catch (e) {
			console.error(e);
			dispatch({
				type: docAction,
				payload: {
					[reduxType]: { error: true }
				}
			});
		}
	};
}

export const createDocument = async (
	document,
	docType,
	uid = null,
	id = null
) => {
	try {
		const docObject = Object.assign({}, {
			...document,
			createdAt: document.createdAt ? document.createdAt : Date.now()
		});
		let docRef = null;
		const path = uid ? `users/${uid}/${docType}` : docType;
		if (id) {
			await setDoc(doc(db, path, id), docObject);
		} else {
			docRef = await addDoc(collection(db, path), docObject);
		}
		docObject.id = id || docRef?.id;
		return docObject;
	} catch (err) {
		console.error(
			`Error ${id ? 'setting' : 'adding'} ${docType} document: ${err}`
		);
	}
};

export function createDocumentThunk(document, docType, docAction, uid = null) {
	return async function inner(dispatch, getState) {
		if (!document) return;
		const newDocument = await createDocument(document, docType, uid);
		dispatch({
			type: docAction,
			payload: {
				docType: newDocument
			}
		});
	};
}

export const updateDocument = async (document, docType, uid = null) => {
	const tempId = document.id;
	const path = uid ? `users/${uid}/${docType}` : docType;
	try {
		if (!document.id) throw Error('no document id to update');
		const newDoc = Object.assign(
			{},
			{
				...document,
				modifiedAt: Date.now()
			}
		);
		delete newDoc.id;
		await updateDoc(doc(db, path, tempId), newDoc);
		document.id = tempId;
		return document;
	} catch (err) {
		console.error(`error updating ${docType} document: ${err}`);
		console.error(`document you tried updating at path ${path}: `, document);
		document.id = tempId;
		return document;
	}
};

export function updateDocumentThunk(
	document,
	docType,
	reduxType,
	docAction,
	owner = false
) {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!state.user || !document) return;
		const newDoc = await updateDocument(
			document,
			docType,
			owner ? state.user.uid : null
		);
		if (docAction) {
			dispatch({
				type: docAction,
				payload: {
					[reduxType]: newDoc
				}
			});
		}
	};
}

export function handleError(error, dispatch) {
	if (
		error?.msg &&
		document.location.origin !== process.env.REACT_APP_PROD_ORIGIN
	)
		console.error(error.msg);
	dispatch({
		type: SET_ERROR,
		payload: {
			error: {
				msg: error.msg,
				acked: false
			}
		}
	});
}
