import { Button } from "@mantine/core";
import { useContext } from "react";
import { PreferencesContext } from "../../config/context/preferencesContext";

export const AcceptButton = ({ children, onSubmit, disabled = false }) => {
  const { preferences } = useContext(PreferencesContext);

  return (
    <Button
      variant={'primary'}
      onClick={onSubmit}
      disabled={disabled}
      size={'lg'}
      sx={{
        color: document.body.style.backgroundColor,
        backgroundColor: preferences?.dark ? "#343434" : "white",
        '&:hover': {
          backgroundColor: preferences?.dark ? "white" : "#343434",
          color: preferences?.dark ? "#343434" : "white",
        },
      }}
    >
      { children }
    </Button>
  );
}