import { useState } from 'react';
import { TextInput, createStyles, Select, Textarea } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';

const useStyles = createStyles((theme, { floating }) => ({
  root: {
    position: 'relative',
  },

  label: {
    position: 'absolute',
    zIndex: 2,
    top: 7,
    left: theme.spacing.sm,
    pointerEvents: 'none',
    color: floating
      ? theme.colorScheme === 'dark'
        ? theme.white
        : theme.black
      : theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[5],
    transition: 'transform 150ms ease, color 150ms ease, font-size 150ms ease',
    transform: floating ? `translate(-${theme.spacing.sm}px, -28px)` : 'none',
    fontSize: floating ? theme.fontSizes.xs : theme.fontSizes.sm,
    fontWeight: floating ? 500 : 400,
  },

  required: {
    transition: 'opacity 150ms ease',
    opacity: floating ? 1 : 0,
  },

  input: {
    '&::placeholder': {
      transition: 'color 150ms ease',
      color: !floating ? 'transparent' : undefined,
    },
  },
}));

export function FloatingLabelInput({
  autoFocus = false,
  label,
  placeholder,
  required = false,
  onChange,
  onEnter,
  value,
  ...props
}) {
  const [focused, setFocused] = useState(false);
  const focusTrapRef = useFocusTrap(autoFocus);
  const { classes } = useStyles({ floating: value?.trim().length !== 0 || focused });

  const floatingLabelInputEl = document.getElementById("floatingLabelInput");
  if (floatingLabelInputEl && autoFocus) {
    floatingLabelInputEl.setAttribute('data-autofocus', 'true');
  }

  const onKeyDown = event => {
    if (event.keyCode === 13) onEnter && onEnter();
  };

  return (
    <TextInput
      id={"floatingLabelInput"}
      ref={focusTrapRef}
      label={label}
      placeholder={placeholder}
      required={required}
      classNames={classes}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      autoComplete="nope"
      {...props}
    />
  );
}

export function FloatingLabelSelectInput({
  label,
  placeholder,
  required = false,
  onChange,
  value,
  ...props
}) {
  const [focused, setFocused] = useState(false);
  const { classes } = useStyles({ floating: value?.trim().length !== 0 || focused });

  return (
    <Select
      label={label}
      placeholder={placeholder}
      required={required}
      classNames={classes}
      value={value}
      onChange={onChange}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      autoComplete="nope"
      {...props}
    />
  );
}

export function FloatingLabelTextareaInput({
  label,
  placeholder,
  required = false,
  onChange,
  value,
  autoFocus = false,
  ...props
}) {
  const [focused, setFocused] = useState(false);
  const { classes } = useStyles({ floating: value?.trim().length !== 0 || focused });
  const focusTrapRef = useFocusTrap(autoFocus);

  const floatingLabelInputEl = document.getElementById("floatingLabelTextAreaInput");
  if (floatingLabelInputEl && autoFocus) {
    floatingLabelInputEl.setAttribute('data-autofocus', 'true');
  }

  return (
    <Textarea
      id={"floatingLabelTextAreaInput"}
      ref={focusTrapRef}
      label={label}
      placeholder={placeholder}
      required={required}
      classNames={classes}
      value={value}
      onChange={onChange}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      autoComplete="nope"
      {...props}
    />
  );
}