import React from "react";
import { useParams } from "react-router-dom";

import SecurePage from "../../config/auth/SecurePage";
import Planner from "../../components/Planners/Planner";

const PlannerPage = () => {
  let params = useParams();

  return (
    <SecurePage>
      <Planner id={params.id} />
    </SecurePage>
  );
};

export default PlannerPage;
