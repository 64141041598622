import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { getDocumentThunk } from '../../model/generic';
import { LOAD_PUBLIC_RECIPE } from '../../redux/actions';
import { useHeaderMenu } from '../../components/Layout/HeaderMenu';
import Recipe from '../../components/Recipes/Recipe';

const PublicRecipePage = () => {
	const { preferences } = useContext(PreferencesContext);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const publicRecipe = useSelector(state => state.publicRecipe);
	const HeaderMenu = useHeaderMenu({ hideHelp: true, isPublic: true });
	let { id } = useParams();

	document.body.style.backgroundColor = "white";

	useEffect(() => {
		dispatch(
			getDocumentThunk(
				id,
				'publicRecipes',
				'publicRecipe',
				LOAD_PUBLIC_RECIPE,
				null
			)
		);
	}, [dispatch, id]);

	useEffect(() => {
		if (publicRecipe?.error) {
			setError(true);
			setLoading(false);
			return;
		} else if (publicRecipe?.id !== id) {
			setLoading(false);
		} else if (publicRecipe?.id) {
			setLoading(false);
		}
	}, [id, publicRecipe]);

	return publicRecipe?.id ? <Recipe id={id} isPublic /> : <HeaderMenu hideHelp={true} disabled />;
};

export default PublicRecipePage;
