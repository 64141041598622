import React, { useContext } from "react";
import { PreferencesContext } from "../../config/context/preferencesContext";
import { useLagNavigate } from "../../hooks/useLagNavigate";
import { Box, createStyles, Title, useMantineTheme } from "@mantine/core";

const useStyles = createStyles((theme, { color = 'default', dark }) => {
  return ({
    box: {
      backgroundColor: theme.colors[color][theme.other.rowColorHueIndex],
      borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
      color: 'white',
      padding: '1rem',
      width: '100%',
    },

    title: {
      textAlign: 'center',
      fontWeight: 800,
      fontSize: 18,
      letterSpacing: -1,
      color: theme.white,
      marginBottom: theme.spacing.xs,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,

      [`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
        fontSize: 18,
        textAlign: 'center',
      },
    },
  });
});

export const ProfileRecipeListRow = ({ publicProfile, publicRecipe }) => {
  const { preferences } = useContext(PreferencesContext);
  const theme = useMantineTheme();
  const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
  const [clicked, onClick] = useLagNavigate(
    process.env.REACT_APP_LAG_NAV_MS,
    `${publicProfile?.name}'s Profile`,
  );

  return (
    <Box className={classes.box} sx={{ ...theme.other.lineItem }} onClick={() => onClick(`/noms/${publicRecipe.id}`)} mt={'lg'}>
      <Title align="center" order={4} className={classes.title}>
        {publicRecipe?.name}
      </Title>
    </Box>
  );
};