/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Center, Chip, Container, createStyles, Grid, List, NumberInput, Space, Stack, Stepper, Text, Title, useMantineTheme } from '@mantine/core';
import { PreferencesContext } from '../../config/context/preferencesContext';
import InfoBox from '../Layout/InfoBox';
import { FloatingLabelTextareaInput } from '../Inputs/FloatingLabelInput';
import useWindow from '../../hooks/useWindow';
import { cutOffText } from '../../utils/textHandling';
import { v4 as uuidv4 } from 'uuid';
import { byObjProperty } from '../../utils/sort';
import { IconPlus } from '@tabler/icons';
import { HelpWrapper } from '../Utils/HelpWrapper';


const useStyles = createStyles((theme, { color = 'default' }) => {
	return ({
		wrapper: {
			position: 'relative',
			width: '100%',
		},

		deleteButton: {
			backgroundColor: theme.colors['warning'][7]
		},

		box: {
			backgroundColor: theme.colors[color][6],
			borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
			color: 'white',
			padding: '1rem',
		},

		highlight: {
			color: 'white'
		}
	});
});

const RequiredIngredients = ({ color, step: { title, ingredients, duration} }) => {
	const ingredientNames = ingredients?.map(i => i.name);
	const durationLabels = [];
	durationLabels[0] = duration?.hours ? `${duration?.hours}h` : '';
	durationLabels[1] = duration?.minutes ? `${duration?.minutes}m` : '';
	durationLabels[2] = duration?.seconds ? `${duration?.seconds}s` : '';
	const durationLabelPadded = duration ? (
		<Text>
			<span style={{ color }}>Time</span>: {durationLabels.join(' ')}
		</Text>
	)  : '';

	return (
		<>
			{duration && durationLabelPadded}
			{ingredients?.length > 0 && <Text><span style={{ color }}>Ingredients</span>:</Text>}
			<List size="sm">
				{ingredientNames?.map((name, idx) => (
					<List.Item key={idx} pr={'md'}>
						<Text color={'dimmed'} sx={{ overflowWrap: 'break-word' }}>{name}</Text>
					</List.Item>
				))}
			</List>
			<Text><span style={{ color }}>Instruction</span>:</Text>
			<Text mb={'md'}>{title}</Text>
		</>
	);
}

const RecipeMethod = ({
	recipe,
	editable,
	isPublic,
	onEditMethod,
	profile,
	shared = false,
	addStep
}) => {
	const theme = useMantineTheme();
	const { preferences } = useContext(PreferencesContext);

	const secondaryShade = theme.colors[theme.primaryColor][theme.other.secondaryShade];

	const handleStepClick = stepIndex => {
		onEditMethod(recipe?.steps ? recipe.steps[stepIndex] : undefined);
	}

	const numSteps = recipe?.steps?.length || 0;
	
	const stepsComponent = (
		<Container p={'sm'} mt={'sm'} mb={'xl'}>
			<Stepper active={false} onStepClick={handleStepClick} orientation="vertical" radius={'md'}>
				{recipe?.steps?.map((step, idx) => (
					<Stepper.Step
						key={idx}
						label={`Step ${idx + 1}`}
						description={<RequiredIngredients color={theme.colors[theme.primaryColor][preferences.dark ? 5 : 9]} step={step} />}
						allowStepSelect={editable}
						sx={{ color: secondaryShade }}
					/>
				))}
				{editable && <Stepper.Step
					icon={
						// <HelpWrapper helpFeature={process.env.REACT_APP_HELP_RECIPE_METHOD_ADD_BUTTON}>
							<IconPlus size="1.1rem" />
						// </HelpWrapper>
					}
					label={numSteps === 0 && "You can add the steps to your recipe here"}
				/>}
			</Stepper>
			<Space h={'xl'} />
			<Space h={'xl'} />
			<Space h={'xl'} />
		</Container>
	);

	return (
		<>
			{isPublic && numSteps === 0 ? (
				<InfoBox text={`${isPublic ? `${profile?.name || "This mysterious chef"} has not included a method.`
					: "Add your recipe method here and we'll help with the cooking later."}`} />
			) : (
				stepsComponent
			)}
		</>
	);
};

export const MethodForm = ({
	method,
	methodId,
	step,
	onCancel,
	onSubmit,
	onDelete,
	ingredients,
}) => {
	const { preferences } = useContext(PreferencesContext);
	const [title, setTitle] = useState('');
	const { duration: { hours: initHours, minutes: initMinutes, seconds: initSeconds } } = method;
	const [hours, setHours] = useState(initHours);
	const [minutes, setMinutes] = useState(initMinutes);
	const [seconds, setSeconds] = useState(initSeconds);
	const [tempIngredients, setTempIngredients] = useState(method?.ingredients || []);
	const theme = useMantineTheme();
	const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
	const { width } = useWindow();

	useEffect(() => {
		if (!title && method?.title !== '') {
			setTitle(method?.title);
		}
	}, []);

	const onDeleteStep = () => {
		onDelete(method);
		onCancel();
	};

	const submitMethod = () => {
		// if (!method?.id) {
		// 	method.id = uuidv4();
		// }
		onSubmit({
			...method,
			id: method?.id,
			title, duration: { hours, minutes, seconds },
			ingredients: tempIngredients,
		});
		onCancel();
	};

	const toggleIngredient = i => {
		const ings = tempIngredients ? [...tempIngredients] : [];
		if (ings.findIndex(ing => ing.id === i.id) > -1) {
			setTempIngredients(ings.filter(ing => ing.id !== i.id) || []);
		} else {
			ings.push(i);
			setTempIngredients(ings);
		}
	}

	const ingredientsCopy = ingredients ? [...ingredients] : [];
	const methodForm = (
		<Box sx={{ maxWidth: 300 }} mx="auto" mb={"lg"}>
			<Title order={5} mt={"xs"} mb={"xs"} sx={{ textAlign: "center" }}>Method</Title>
			<FloatingLabelTextareaInput
				autoFocus={true}
				placeholder={"The next step in your recipe method."}
				value={title}
				onChange={(event) => {
					setTitle(event.currentTarget.value);
					if (event.keyCode === 13) {
						submitMethod();
					}
				}}
				minRows={12}
			/>
			{ingredientsCopy.length > 0 && <Title order={5} mt={"xs"} mb={"xs"} sx={{ textAlign: "center" }}>Required Ingredients</Title>}
			{ingredientsCopy?.sort(byObjProperty('name', true)).map(ingredient => {
				const ingredientAlreadyChecked = tempIngredients?.find(i => i.id === ingredient.id);
				return (
					<Button
						key={ingredient?.id}
						onClick={() => toggleIngredient(ingredient)}
						checked={ingredientAlreadyChecked}
						m={"3px"}
						variant={ingredientAlreadyChecked ? "filled" : "outline"}
						fullWidth
						sx={{ textOverflow: "ellipsis", overflow: "hidden", color: preferences.dark ? 'white' : ingredientAlreadyChecked ? 'white' : '#343434' }}
					>
						{cutOffText(ingredient?.name, width)}
					</Button>
				)
			})}
			<Title order={5} mt={"xs"} mb={"xs"} sx={{ textAlign: "center" }}>Duration</Title>
			<Grid columns={3}>
				<Grid.Col span={1}>
					<NumberInput
						value={method?.duration?.hours || 0}
						onChange={setHours}
						placeholder="Hours"
						label="Hours"
						size="xl"
					/>
				</Grid.Col>
				<Grid.Col span={1}>
					<NumberInput
						value={method?.duration?.minutes || 0}
						onChange={setMinutes}
						placeholder="Minutes"
						label="Minutes"
						size="xl"
					/>
				</Grid.Col>
				<Grid.Col span={1}>
					<NumberInput
						value={method?.duration?.seconds || 0}
						onChange={setSeconds}
						placeholder="Seconds"
						label="Seconds"
						size="xl"
					/>
				</Grid.Col>
			</Grid>
		</Box>
	);
	return (
		<>
			{methodForm}
			<Box>
				<Center>
					{methodId ? (
						<Button
							onClick={onDeleteStep}
							className={classes.deleteButton}
						>
							Delete
						</Button>
					) : null}
					{methodId ? <Space w={'md'} /> : null}
					<Button
						disabled={!title || title === ""}
						onClick={() => submitMethod()}
					>
						Save
					</Button>
				</Center>
			</Box>
		</>
	);
};

export default RecipeMethod;
