import * as actions from '../redux/actions';
import {
	createDocument,
	getDocument,
	getDocumentsByAttribute,
	getDocumentSingleton,
	updateDocument
} from './generic';
import { updateRecipe } from './recipes';

export function getPublicRecipeThunk(publicRecipeId) {
	return async function inner(dispatch, getState) {
		try {
			const publicRecipe = await getDocument(publicRecipeId, 'publicRecipes');
			dispatch({
				type: actions.LOAD_PUBLIC_RECIPE,
				payload: {
					publicRecipe
				}
			});
		} catch (e) {
			console.error(e);
		}
	};
}

export function createProfilesThunk() {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!state.user) return;
		let profile = await getDocumentSingleton('profiles', state.user.uid);
		const [publicProfileDoc, publicProfilesNextPage] = await getDocumentsByAttribute(
			'publicProfiles',
			{ field: 'profileId', value: profile.id },
			null,
			null,
			true,
			true,
		);
		let publicProfile = state.publicProfile || publicProfileDoc
		if (!profile?.id) {
			profile = await createDocument(
				{
					active: true,
					createdAt: Date.now(),
					name: 'Mystery Chef',
					public: true,
					recipesContributed: 0
				},
				'profiles',
				state.user.uid
			);
		}
		if (!publicProfile?.id) {
			publicProfile = await createDocument(
				{
					active: true,
					createdAt: profile.createdAt,
					profileId: profile.id,
					name: 'Mystery Chef',
					public: true,
					recipesContributed: 0
				},
				'publicProfiles',
				null,
				profile.id
			);
		}
		dispatch({
			type: actions.PROFILES_CREATED,
			payload: {
				profile,
				publicProfile
			}
		});
	};
}

async function syncProfiles(uid, profile, dispatch) {
	const profileId = profile.id;
	const newProfile = {
		...profile,
		modifiedAt: Date.now()
	};
	await updateDocument(newProfile, 'profiles', uid);
	newProfile.id = profileId;
	delete newProfile.publicProfileId;
	await updateDocument(newProfile, 'publicProfiles');
	dispatch({
		type: actions.PROFILE_UPDATED,
		payload: {
			profile: newProfile
		}
	});
}

export function syncPublicRecipeThunk() {
	return async function inner(dispatch, getState) {
		const state = getState();
		let recipe = Object.assign({}, state.recipe);
		if (!recipe || !state.user) return;
		let publicProfile = Object.assign({}, state.publicProfile);
		let profile = Object.assign({}, state.profile);
		recipe.recipeId = recipe.id;
		const existingPublicRecipe = await getDocument(recipe.id, 'publicRecipes');
		delete recipe.id;
		let publicRecipe = null;
		const createPublicRecipeDoc = { ...recipe, active: true, publicProfileId: publicProfile.id };
		const modifiedAt = publicRecipe?.modifiedAt;
		if (existingPublicRecipe) {
			publicRecipe = await updateDocument(
				{ ...createPublicRecipeDoc, id: existingPublicRecipe.id },
				'publicRecipes'
			);
		} else {
			publicRecipe = await createDocument(
				createPublicRecipeDoc,
				'publicRecipes',
				null,
				recipe.recipeId
			);
		}
		if (publicRecipe?.id) {
			await updateRecipe(state.user.uid, recipe, dispatch);
		}
		if (modifiedAt !== publicRecipe?.modifiedAt) {
			await syncProfiles(
				state.user.uid,
				{ ...profile, recipesContributed: profile.recipesContributed + 1 },
				dispatch
			);
		}
		dispatch({
			type: actions.PUBLIC_RECIPE_CREATED,
			payload: {
				publicRecipe: {
					...publicRecipe,
					id: publicRecipe.id
				}
			}
		});
	};
}

export const updatePublicRecipe = async publicRecipe => {
	const recipe = Object.assign(
		{},
		{
			...publicRecipe,
			modifiedAt: Date.now()
		}
	);
	try {
		await updateDocument(recipe, 'publicRecipes');

	} catch (err) {
		console.error(`error updating publicRecipe ${err}`);
	}
	return recipe;
};

export function updatePublicRecipeThunk(publicRecipe) {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!publicRecipe || !state.user) return;
		const modifiedAt = publicRecipe.modifiedAt;
		publicRecipe = await updatePublicRecipe(publicRecipe);
		if (publicRecipe.active) {
			dispatch({
				type: actions.LOAD_PUBLIC_RECIPE,
				payload: {
					publicRecipe
				}
			});
		} else {
			dispatch({
				type: actions.LOAD_PUBLIC_RECIPE,
				payload: {
					publicRecipe: null
				}
			});
		}
		if (modifiedAt !== publicRecipe?.modifiedAt) {
			const recipesContributed = publicRecipe.active
				? state.profile.recipesContributed + 1
				: state.profile.recipesContributed - 1;
			await syncProfiles(
				state.user.uid,
				{
					...state.profile,
					recipesContributed
				},
				dispatch
			);
		}
	};
}
