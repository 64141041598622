import { ActionIcon, Center, Container, createStyles, Grid, Title, useMantineTheme } from '@mantine/core';
import { IconX } from '@tabler/icons';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { updateShoppingListRecipesThunk } from '../../model/shoppingLists';

const useStyles = createStyles((theme, { color = 'default', dark }) => {
  return ({
    mealplanBox: {
      color: 'white',
      fontWeight: 'bold',
      display: 'flex',
      backgroundImage: `linear-gradient(-60deg, ${theme.colors[theme.primaryColor][4]} 0%, ${theme.colors[theme.primaryColor][7]
        } 100%)`,

      [theme.fn.smallerThan('sm')]: {
        flexDirection: 'column',
      },

      userSelect: 'none',
      borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
    },
    inner: {
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 'auto',
      color: 'white',
      backgroundColor: theme.colors[theme.primaryColor][6],
      borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
    },
  });
});

export const RecipeAdded = ({ recipe }) => {
  const { preferences } = useContext(PreferencesContext);
  const theme = useMantineTheme();
  const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);

  const handleSelection = recipe => {
    setClicked(old => !old);
    dispatch(updateShoppingListRecipesThunk({
      recipe, remove: true
    }));
  }

  return (
    <div onClick={() => handleSelection(recipe)}>
      <Grid
        className={classes.inner}
        columns={8}
        my={'sm'}
      >
        <Grid.Col span={7}>
          <Title align={'left'} order={5} className={classes.title}>{recipe.name}</Title>
        </Grid.Col>
        <Grid.Col span={1}>
          <Center onClick={() => handleSelection(recipe)}>
            <ActionIcon loading={clicked}>
              <IconX color={theme.white} size={20} />
            </ActionIcon>
          </Center>
        </Grid.Col>
      </Grid>
    </div>
  );
}
