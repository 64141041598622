import * as actions from "../redux/actions";
import { getShoppingList, updateShoppingList } from "./shoppingLists";
import { updatePublicShoppingListAsOwnerThunk } from "./shoppingListsPublic";

export function addShoppingListIngredientThunk(shoppingListId, ingredients) {
  return async function inner(dispatch, getState) {
    const state = getState();
    if (!state.user || !ingredients || !shoppingListId) return;
    const shoppingListRecipes = state.shoppingListRecipes;
    let shoppingList = await getShoppingList(state.user.uid, shoppingListId);
    shoppingList.ingredients.push(...ingredients);
    shoppingList = await updateShoppingList(state.user.uid, { ...shoppingList });
    if (shoppingList.publicShoppingListId) {
      await updatePublicShoppingListAsOwnerThunk({
        ...shoppingList,
        recipes: [...shoppingListRecipes?.map(r => ({ sortname: r.sortname }))],
      });
    }
    dispatch({
      type: actions.LOAD_INGREDIENTS,
      payload: {
        ingredients: shoppingList.ingredients
      }
    });
    dispatch({
      type: actions.LOAD_SHOPPING_LIST,
      payload: {
        shoppingList: shoppingList
      }
    });
  };
};

