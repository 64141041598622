import React from 'react';
import { IconArrowUp, IconBookmark, IconBookmarkOff, IconPlus, IconRotate, IconShare, IconUser, IconWand } from '@tabler/icons';
import { Button, useMantineTheme } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';

export const Profile = ({ fn, meta, themeId, transitionStyles, iconOverride: IconOverride }) => {
  const theme = useMantineTheme();
  return (
    <Button
      variant="gradient" gradient={{ from: theme.colors[theme.primaryColor][8], to: theme.colors[theme.primaryColor][8], deg: 105 }}
      leftIcon={IconOverride ? <IconOverride size={16} /> : <IconUser size={16} />}
      style={transitionStyles}
      onClick={fn || undefined}
    >
      {meta?.profileName}
    </Button>
  )
};

export const Add = ({ fn, transitionStyles }) => {
  const theme = useMantineTheme();
  return (
    <Button
      variant={'gradient'}
      gradient={{ from: theme.colors[theme.primaryColor][8], to: theme.colors[theme.primaryColor][8], deg: 105 }}
      leftIcon={<IconPlus size={16} />}
      style={transitionStyles}
      onClick={fn || undefined}
    >
      Add
    </Button>
  );
};

export const Share = ({ fn, transitionStyles }) => {
  const theme = useMantineTheme();
  return (
    <Button
      variant="gradient" gradient={{ from: theme.colors[theme.primaryColor][8], to: theme.colors[theme.primaryColor][8], deg: 105 }}
      leftIcon={<IconShare size={16} />}
      style={transitionStyles}
      onClick={fn || undefined}
    >
      Share
    </Button>
  );
};

export const Wizard = ({ fn, transitionStyles }) => {
  const theme = useMantineTheme();
  return (
    <Button
      variant="gradient" gradient={{ from: theme.colors[theme.primaryColor][8], to: theme.colors[theme.primaryColor][8], deg: 105 }}
      leftIcon={<IconWand size={16} />}
      style={transitionStyles}
      onClick={fn || undefined}
    >
      Start Cooking
    </Button>
  );
};

export const Top = ({ transitionStyles }) => {
  const theme = useMantineTheme();
  const [scroll, scrollTo] = useWindowScroll();
  return (
    <Button
      variant="gradient" gradient={{ from: theme.colors[theme.primaryColor][8], to: theme.colors[theme.primaryColor][8], deg: 105 }}
      leftIcon={<IconArrowUp size={16} />}
      style={transitionStyles}
      onClick={() => scrollTo({ y: 0 })}
    >
      Top
    </Button>
  );
};

export const Bookmark = ({ fn, meta, transitionStyles }) => {
  const theme = useMantineTheme();
  return (
    <Button
      variant="gradient" gradient={{ from: theme.colors[theme.primaryColor][8], to: theme.colors[theme.primaryColor][8], deg: 105 }}
      leftIcon={meta?.bookmarked
        ? <IconBookmarkOff size={16} />
        : <IconBookmark size={16} />
      }
      style={transitionStyles}
      onClick={fn || undefined}
    >
      {meta?.bookmarked ? "Unbookmark Recipe" : "Bookmark Recipe"}
    </Button>
  );
};

export const Reset = ({ fn, transitionStyles }) => {
  const theme = useMantineTheme();
  return (
    <Button
      variant={'gradient'}
      gradient={{ from: theme.colors[theme.primaryColor][8], to: theme.colors[theme.primaryColor][8], deg: 105 }}
      leftIcon={<IconRotate size={16} />}
      style={transitionStyles}
      onClick={fn || undefined}
    >
      Uncheck All
    </Button>
  );
};
