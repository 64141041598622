import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { TextInput, ActionIcon, useMantineTheme } from '@mantine/core';
import { IconSearch, IconArrowRight, IconArrowLeft } from '@tabler/icons';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { useLagNavigate } from '../../hooks/useLagNavigate';
import { useDebouncedValue } from '@mantine/hooks';

export function SearchBar({ search, style }) {
  const theme = useMantineTheme();
  const recipes = useSelector(state => state.recipes);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTags, setSearchTags] = useState([]);
  // const [clicked, onClick] = useLagNavigate(process.env.REACT_APP_LAG_NAV_MS);
  const [searching, setSearching] = useState(false);
  const [result, setResult] = useState('');
  let { preferences } = useContext(PreferencesContext);
  const [debounced] = useDebouncedValue(searchTags, 300);

  useEffect(() => {
    if (searching) {
      let result = '';
      if (!recipes || recipes.length === 0) result = 'No recipes found.';
      setResult(result);
      setSearching(false);
    } else {
      let result = '';
      if (!recipes || recipes.length === 0) result = 'No recipes found.';
      setResult(result);
    }
  }, [recipes, searching]);

  useEffect(() => {
    if (searchTerm !== '') {
      setSearchTags(searchTerm.toUpperCase().split(' '));
    } else {
      if (result !== '') setResult('');
    }
  }, [searchTerm, result]);

  const handleSearch = () => {
    setSearching(true);
    if (searchTerm !== '') search(searchTags);
  };

  useEffect(() => {
    handleSearch();
  }, [debounced]);

  return (
    <TextInput
      onChange={evt => setSearchTerm(evt.target.value)}
      icon={<IconSearch size={18} stroke={1.5} />}
      radius="sm"
      autoComplete='false'
      autoCorrect='false'
      type="text"
      size="md"
      rightSection={
        <ActionIcon
          size={32}
          radius="xl"
          variant="filled"
          aria-label="Search"
          disabled={searchTerm === ''}
        >
          {theme.dir === 'ltr' ? (
            <IconArrowRight size={18} stroke={1.5} />
          ) : (
            <IconArrowLeft size={18} stroke={1.5} />
          )}
        </ActionIcon>
      }
      placeholder="Search Recipes"
      style={style}
      rightSectionWidth={42}
    />
  );
}