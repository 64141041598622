/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_RECIPES, CLEAR_SHOPPING_LIST, LOAD_PLANNER_EVENT_RECIPE } from '../../redux/actions';
import {
	createShoppingListThunk,
	deleteShoppingListThunk,
	getShoppingListsThunk,
  updateShoppingListRecipesThunk,
} from '../../model/shoppingLists';
import {
  createRecipeThunk,
	deleteRecipeThunk,
  getRecipeRowsThunk,
} from '../../model/recipes';
import SideControls from '../Inputs/SidebarControls';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { useHeaderMenu } from '../Layout/HeaderMenu';
import { Box, Button, Center, Container, Grid, Loader, Modal, Skeleton, Space, useMantineTheme } from '@mantine/core';
import { BackButton } from '../Layout/BackButton';
import InfoBox from '../Layout/InfoBox';
import { TitleText } from '../Layout/TitleText';
import { RowButton } from '../Layout/RowButton';
import { useLagNavigate } from '../../hooks/useLagNavigate';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IconCheck, IconPlus } from '@tabler/icons';
import { NewItemModal } from '../Inputs/NewModal';
import { usePageTimers } from '../../hooks/usePageTimers';
import { getValidRecipeName } from '../../utils/textHandling';
import { useHotkeys } from '@mantine/hooks';

const GenericList = ({
  cols,
  listOnly = false,
  objectDataFn,
  objectType,
	pageThunks = undefined,
	rowNameFn = undefined,
  pickable = false,
  sidebar,
  swipeable,
	loading = false,
	skeletonRowCount = 3,
	hasMore = false,
	contentHeight = undefined,
	upperComponent = undefined,
}) => {
	const objectDetail = {
    getPageMeta: () => {
      let emptyMessage = '';
      let sourceText = '';
      let helpText = '';
			switch (objectType) {
				case 'shopping':
					emptyMessage = 'Add your favourite recipes here and we\'ll help you make shopping day a breeze.';
					sourceText = 'shopping list';
					helpText = process.env.REACT_APP_HELP_SHOPPING_LISTS_ADD_BUTTON;
          break;
				case 'recipe':
          emptyMessage = 'Add your favourite recipes here and we\'ll help you make shopping day a breeze.';
					helpText = process.env.REACT_APP_HELP_RECIPES_ADD_BUTTON;
          break;
				case 'eventRecipe':
          emptyMessage = 'Add your favourite recipes here and we\'ll help you build out a meal plan.';
          break;
				case 'planner':
          emptyMessage = 'Create a planner here and we\'ll help you plan out meals on a calendar and track any spending.';
					helpText = process.env.REACT_APP_HELP_PLANNERS_ADD_BUTTON;
          break;
				default:
					break;
        }
      return {
        emptyMessage,
        sourceText,
				helpText,
      };
    },
		getRowName: (obj) => {
      let name = '';
			switch (objectType) {
				case 'shopping':
					name = obj.name;
          break;
				case 'recipe':
          name = obj.name;
          break;
				case 'publicRecipe':
          name = obj.name;
          break;
				case 'eventRecipe':
          name = obj.name;
          break;
				case 'planner':
          name = rowNameFn(obj);
          break;
				default:
					break;
        }
      return name;
		},
		getPageTitle: (plural, lower) => {
      let title = '';
			switch (objectType) {
				case 'shopping':
					title = plural ? 'Shopping Lists' : 'Shopping List';
          break;
				case 'recipe':
          title =  plural ? 'Recipes' : 'Recipe';
          break;
				case 'publicRecipe':
          title =  plural ? 'Recipes' : 'Recipe';
          break;
				case 'eventRecipe':
          title =  plural ? 'Recipes' : 'Recipe';
          break;
				case 'planner':
          title = plural ? 'Planners' : 'Planner';
          break;
				default:
					break;
        }
      return lower ? title.toLowerCase() : title;
		},
		getPageUrl: () => {
			switch (objectType) {
				case 'shopping':
					return 'shopping';
				case 'recipe':
          return 'recipes';
				case 'publicRecipe':
          return 'noms';
				case 'eventRecipe':
          return 'recipes';
				case 'planner':
          return 'planners';
				default:
					break;
			}
		},
		getPageThunks: (row) => {
			switch (objectType) {
				case 'shopping':
					return {
            create: createShoppingListThunk,
            get: getShoppingListsThunk,
            delete: deleteShoppingListThunk,
            deleteLabel: 'Delete Shopping List',
          }
        case 'recipe':
          return {
            create: createRecipeThunk,
            get: () => getRecipeRowsThunk({
              nextPage: objectDataFn.next,
              pageSize: objectDataFn.pageSize,
            }),
            delete: deleteRecipeThunk,
            rowAction: updateShoppingListRecipesThunk,
            rowActionParamName: 'recipe',
            deleteLabel: 'Delete Recipe',
          }
        case 'publicRecipe':
          return {
						get: objectDataFn.getThunk,
            // rowAction: updateShoppingListRecipesThunk,
            rowActionParamName: 'publicRecipe',
            // deleteLabel: 'Delete Recipe',
          }
        case 'eventRecipe':
          return {
            create: undefined,
            get: () => getRecipeRowsThunk({
              nextPage: objectDataFn.next,
              pageSize: objectDataFn.pageSize,
            }),
            delete: undefined,
            rowAction: ({ eventRecipe }) => dispatch({ type: LOAD_PLANNER_EVENT_RECIPE, payload: { eventRecipe } }),
            rowActionParamName: 'eventRecipe',
            deleteLabel: undefined,
          }
        case 'planner':
          return pageThunks(row);
				default:
					break;
			}
		},
    getPageActions: () => {
			switch (objectType) {
				case 'shopping':
					return {
            clear: CLEAR_SHOPPING_LIST,
          }
				case 'recipe':
          return {
            clear: CLEAR_RECIPES,
          }
				case 'publicRecipe':
          return {
            clear: CLEAR_RECIPES,
          }
				case 'eventRecipe':
          return {
            clear: CLEAR_RECIPES,
          }
				case 'planner':
          return {
            clear: CLEAR_SHOPPING_LIST,
          }
				default:
					break;
			}
		},
    getObjectForm: () => {
      switch (objectType) {
				case 'shopping':
					return <NewItemModal source={'shopping'} onCancel={handleCancel} onSubmit={(name) => saveNewObject(name)} nameMaxLength={30} />;
				case 'recipe':
					return <NewItemModal source={'recipe'} onCancel={handleCancel} onSubmit={(name) => saveNewObject(name)} nameMaxLength={60} isValidFn={getValidRecipeName} />;
				case 'planner':
          return <NewItemModal source={'planner'} onCancel={handleCancel} onSubmit={(name) => saveNewObject(name)} nameMaxLength={30} />;
				default:
					break;
			}
    }
	};
	const { preferences } = useContext(PreferencesContext);
  const { initialLoadFinished } = usePageTimers();
  const [creatingList, setCreatingList] = useState(false);
	const HeaderMenu = useHeaderMenu({ help: true, disabled: false });
	const [clicked, onClick] = useLagNavigate(
		process.env.REACT_APP_LAG_NAV_MS,
		objectDetail.getPageTitle(true),
	);
	const theme = useMantineTheme();
  const dispatch = useDispatch();
	const objectList = useSelector(state => {
    let data = undefined;
    switch (objectType) {
      case 'shopping':
        data = state.shoppingLists;
        break;
      case 'recipe':
        data = state.recipes;
        break;
      case 'publicRecipe':
        data = state.searchPublicRecipes;
        break;
      case 'eventRecipe':
        data = state.recipes;
        break;
      case 'planner':
        data = state.planners;
        break;
      default:
        break;
    }
    return data;
  });
	useHotkeys([
    ['a', () => setCreatingList(true)],
  ]);

	useEffect(() => {
		dispatch({ type: objectDetail.getPageActions().clear, payload: {} });
		return () => {
			dispatch({ type: objectDetail.getPageActions().clear, payload: {} });
		};
	}, []);

	const numObjects = objectList?.length || 0;
  const message = <InfoBox text={objectDetail.getPageMeta().emptyMessage} />;
	const listsComponent = (
		<Grid.Col span={cols} key={objectDetail.getPageTitle(true, true)}>
			{!loading && initialLoadFinished && numObjects === 0 ? (
					<>
						{message}
						<Center>
							<Button onClick={() => setCreatingList(true)}>
								Get Started
							</Button>
						</Center>
					</>
				) : (
					<>
						<InfiniteScroll
							dataLength={objectList?.length || 0} // This is important field to render the next data
							next={objectDataFn?.nextPage ? objectDataFn?.nextPage : undefined}
							hasMore={hasMore}
							loader={<Center><Loader /></Center>}
							scrollThreshold={0.90}
							height={contentHeight}
						>
							{objectList?.map((obj, idx) => {
								const highlighted = objectDataFn?.highlightedListData?.findIndex(r => r.id === obj.id) > -1;
								const onDelete = () => dispatch(objectDetail.getPageThunks(obj).delete(obj));
								let onClickFn = () => onClick(`/${objectDetail.getPageUrl()}/${obj.id}`);

								if (pickable) {
									onClickFn = () => dispatch(
										objectDetail.getPageThunks(obj).rowAction({
											[objectDetail.getPageThunks(obj).rowActionParamName]: obj,
											remove: highlighted,
										})
									);
								}

								let rightIcon = highlighted
									? <IconCheck style={{ width: '2rem', color: 'white', }} size={20} order={1} />
									: <IconPlus style={{ width: '2rem', }} size={20} order={1} />;

								if (!pickable) rightIcon = null;

								return (
									<RowButton
										key={idx}
										title={objectDetail.getRowName(obj)}
										onClickFn={onClickFn}
										rowTypeLabel={objectDetail.getPageTitle()}
										rightIcon={pickable && {
											iconComponent: rightIcon,
											highlighted,
										}}
										deleteAction={{
											deleteLabel: objectDetail.getPageThunks(obj).deleteLabel,
											deleteFn: onDelete,
										}}
										swipeable={swipeable}
									/>
								);
							})}
						</InfiniteScroll>
					</>
				)}
		</Grid.Col>
	);

	const saveNewObject = (newObjectName) => {
		if (!newObjectName || newObjectName === '') return;
		dispatch(objectDetail.getPageThunks().create({ name: newObjectName }));
		setCreatingList(false);
		dispatch(objectDetail.getPageThunks().get());
	};

	const handleCancel = () => setCreatingList(false);

  const modalForm = objectDetail.getObjectForm();
	const createObjectModal = (
		<Modal
			overlayColor={preferences.dark ? theme.colors.dark[9] : theme.colors.gray[2]}
			overlayOpacity={0.55}
			overlayBlur={3}
			opened={creatingList}
			onClose={handleCancel}
			title={`Create ${objectDetail.getPageTitle()}`}
			closeOnEscape
			zIndex={4000}
			closable={"false"}
		>
      {modalForm}
		</Modal>
	);

	const skeletonRows = Array.apply(null, Array(skeletonRowCount)).map((el, idx) => (
		<Grid.Col key={idx} pl={0} pr={0} pt={'sm'}>
			<Skeleton visible height={48} radius="sm" />
		</Grid.Col>
	))
	const skeleton = (
		<Grid mr={'auto'} ml={'auto'} sx={{ width: '100%' }}>
			{skeletonRows}
		</Grid>
	);
	const skeletonCols = (
		<Grid.Col mr={'auto'} ml={'auto'} sx={{ width: '100%' }} span={cols}>
			{skeletonRows}
		</Grid.Col>
	);
  let content = initialLoadFinished ? <Grid>{listsComponent}</Grid> : skeleton;
  if (sidebar) {
    content = (
			<Box sx={{ position: 'relative' }}>
				<Grid>
					{preferences.rightHanded ? (initialLoadFinished && !loading ? listsComponent : skeletonCols) : sidebar}
					{preferences.rightHanded ? sidebar : (initialLoadFinished && !loading ? listsComponent : skeletonCols)}
				</Grid>
			</Box>
    );
  }
	const contentHarness = (
		<>
			{upperComponent}
			<Space h={"sm"} />
			{content}
		</>
	);
  const windowedMode = (
		<>
			<HeaderMenu />
			<Container px={0} sx={{ ...theme.other.wrapper }} size={1400}>
				<Container my="xs">
					<BackButton defaultFrom={'Menu'} />
					<Space h={"xs"} />
					<TitleText title={objectDetail.getPageTitle(true)} />
					{contentHarness}
					<SideControls
						add={() => setCreatingList(true)}
						source={objectDetail.getPageMeta().sourceText}
						adding={creatingList}
						helpAdd={objectDetail.getPageMeta().helpText}
					/>
					<Space h={"xl"} />
					<Space h={"xl"} />
					<Space h={"xl"} />
					<Space h={"xl"} />
					<Space h={"xl"} />
				</Container>
			</Container>
			{createObjectModal}
		</>
	);

	return listOnly ? contentHarness : windowedMode;
};

export default GenericList;
