import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Center, Container, createStyles, Loader, LoadingOverlay, Modal, SegmentedControl, Space, Stepper, Tabs, Text, TextInput, Title, useMantineTheme } from '@mantine/core';
import QRCode from 'react-qr-code';
import { LagLoader } from "../Utils/LagLoader";
import { ShareProgress } from "./ShareProgress";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconCircleCheck, IconCopy, IconLink, IconLock, IconMailOpened, IconShare, IconUserCheck, IconX } from "@tabler/icons";
import { createPlannerShareLinkThunk } from "../../model/planners";
import { CLEAR_PLANNER_SHARE_LINK } from "../../redux/actions";
import { ChefList } from "../ChefList/ChefList";
import useWindow from "../../hooks/useWindow";

const mapRolesToChefs = (userRoleArray, roleType) => {
  const chefs = [];
  userRoleArray.map(userRole => {
    const userChef = {
      name: userRole.name,
      publicProfileId: userRole.id,
      role: roleType,
    };
    chefs.push(userChef);
  });
  return chefs;
}

export const PlannerShareModal = ({ visible, onCancel, preferences }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const planner = useSelector(state => state.planner);
  const tempAccess = useSelector(state => state.tempAccess);
  const plannerShareLink = useSelector(state => state.plannerShareLink);
  const publicProfile = useSelector(state => state.publicProfile);
  const [pinEditable, setPinEditable] = useState(false);
  const [role, setRole] = useState('viewer');
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState();
  const [activeTab, setActiveTab] = useState('access');
  const { width } = useWindow();


  useEffect(() => {
    if (plannerShareLink) {
      setTimeout(() => setLoading(false), 500);
    }
  }, [plannerShareLink]);

  const envDomain =
		document.location.origin === 'http://localhost:3000'
			? 'http://192.168.0.136:3000/'
			: 'https://nomicon.app/';
  const buildSharingUrl = () => {
    return `${envDomain}planners/${plannerShareLink?.id}/share`;
  }
  const handleCancel = () => {
    setStep(0);
    dispatch({ type: CLEAR_PLANNER_SHARE_LINK, payload: {} });
    onCancel();
  };
  const roleText = () => {
    let text = undefined;
    let title = undefined;
    switch (role) {
      case 'admin':
        text = 'This will allow them to promote or demote other chefs, change the planner name and create share links.\nThere can be more than one head Chef.';
        title = 'Admin';
        break;
      case 'editor':
        text = 'This will allow them to add, change and delete events on the planner.';
        title = 'Editor';
        break;
      case 'viewer':
        text = 'This will allow them to view the planner events only.';
        title = 'Viewer';
        break;
      default:
        text = '';
    }
    return [text, title];
  };
  const copyLink = () => {
		var url = document.getElementById('url').value;
		navigator.clipboard
			.writeText(url)
			.then(() => {
				showNotification({
					id: 'share-planner-process',
					disallowClose: true,
					autoClose: 2500,
					title: "Link Copied!",
					color: 'teal',
					icon: <IconCheck />,
					loading: false,
				});
			})
			.catch(() => {
				setPinEditable(true);
				showNotification({
					id: 'share-planner-process',
					disallowClose: true,
					autoClose: 5000,
					title: "Oops!",
					message: "Hmm - Something stopped us from copying this for you. 🤔",
					color: 'red',
					icon: <IconX />,
					loading: false,
				});
			});
	};

  const qrCode = (
    <>
      <div
        style={{
          border: `3px solid ${preferences.primary}`,
          borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
          background: 'white',
          padding: '16px',
          height: 'auto',
          margin: '0 auto',
          maxWidth: 124,
          width: '100%'
        }}
      >
        <QRCode
          size={256}
          style={{
            height: 'auto',
            maxWidth: '100%',
            width: '100%'
          }}
          value={buildSharingUrl()}
          viewBox={`0 0 256 256`}
        />
      </div>
      <Space h={'md'} />
      <Center>
        <Button
          key='submit'
          disabled={false}
          variant='Filled'
          onClick={copyLink}
          leftIcon={<IconCopy />}
        >
          Copy
        </Button>
      </Center>
    </>
  );

  const chefs = [];
  if (planner) {
    chefs.push(...mapRolesToChefs(planner.admins, 'Admin', 'admin'));
    chefs.push(...mapRolesToChefs(planner.editors, 'Editor', 'editor'));
    chefs.push(...mapRolesToChefs(planner.viewers, 'Viewer', 'viewer'));
  }

  const sharingCreatedScreen = (
    <>
      <Box p={'sm'}>
        {/* <Text>Share</Text> */}
				<Text>Show this QR code to someone you'd like to access your planner. Or you can send them the URL below.</Text>
			</Box>
			<Box>
				<TextInput
					id='role'
					name='role'
					disabled={!pinEditable}
					autoComplete={'false'}
					type={'text'}
					placeholder={`Planner Role`}
					value={roleText(role)[1]}
					icon={<IconLock />}
				/>
				<TextInput
					id='url'
					name='url'
					disabled={!pinEditable}
					autoComplete={'false'}
					type={'text'}
					placeholder={`Share URL`}
					value={plannerShareLink && buildSharingUrl()}
					icon={<IconLink />}
				/>
				<Space h={'md'} />
			</Box>
    </>
  );
  const handleRole = role => setRole(role);
  const handleShareStep = () => {
    dispatch(createPlannerShareLinkThunk(planner, role));
    setLoading(true);
    setStep(1);
  };
  const createShareScreen = (
    <>
      <Stepper active={step} completedIcon={<IconCircleCheck />}>
        <Stepper.Step icon={<IconUserCheck size="1.1rem" />} label="Step 1" description="Choose an Access Level">
          <Box>
            <Text>Access Level</Text>
            <Box sx={{ height: '100px' }}>
              <Text m='md' size={'sm'} color="dimmed">{roleText(role)[0]}</Text>
            </Box>
            <SegmentedControl
              fullWidth
              color={theme.primaryColor}
              value={role}
              label="Role Selection"
              onChange={handleRole}
              data={[
                { label: 'Admin', value: 'admin' },
                { label: 'Editor', value: 'editor' },
                { label: 'Viewer', value: 'viewer' },
              ]}
            />
            <Space h={'md'} />
            {role !== '' && <Button sx={{ float: 'right' }} onClick={handleShareStep}>Next</Button>}
          </Box>
        </Stepper.Step>
        <Stepper.Step icon={<IconMailOpened size="1.1rem" />} label="Step 2" description="Send Share Link">
          {sharingCreatedScreen}
          {loading ? <Center><Loader size={'xl'}/></Center> : qrCode}
        </Stepper.Step>
      </Stepper>
    </>
  );

  return (
    <Modal
      overlayColor={preferences.dark ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      opened={visible}
      onClose={handleCancel}
      title={"Share Planner"}
      closeOnEscape
      zIndex={4000}
      closable={"false"}
    >
      <Tabs
        defaultValue={'access'}
        onTabChange={(tab) => setActiveTab(tab)}
        value={activeTab}
        color={{ color: theme.colors[theme.primaryColor][2] }}
      >
        <Tabs.List position={width > 800 ? "center" : undefined} grow>
          <Tabs.Tab
            value="access"
            icon={<IconLock size={14} />}
            sx={{ fontWeight: activeTab === 'access' ? 'bold' : undefined }}
          >
            Access List
          </Tabs.Tab>
          <Tabs.Tab
            value="share"
            icon={<IconShare size={14} />}
            sx={{ fontWeight: activeTab === 'share' ? 'bold' : undefined }}
          >
            Share Planner
          </Tabs.Tab>
          {/* TODO: show list of active links so we can cancel them: they will expire after 24hrs for now */}
          {/* <Tabs.Tab
            value="status"
            icon={<IconShare size={14} />}
            sx={{ fontWeight: activeTab === 'status' ? 'bold' : undefined }}
          >
            Share Link Status
          </Tabs.Tab> */}
        </Tabs.List>
        <Tabs.Panel value='access'>
          <Space h={'xl'} />
          <ChefList data={chefs} owner={planner?.owner} publicProfile={publicProfile} planner={planner} />
        </Tabs.Panel>
        <Tabs.Panel value='share'>
          <Space h={'xl'} />
          {createShareScreen}
        </Tabs.Panel>
      </Tabs>
    </Modal>
  );
};
