import Symbol_observable from 'symbol-observable'; // required to fix warning about devtools
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

import Home from "./pages/index";
import LogoutPage from "./pages/auth/logout";
import TestPage from "./pages/test";
import MenuPage from "./pages/menu";
import RecipePage from "./pages/recipes/recipe";
import RecipesPage from "./pages/recipes/recipes";
import ProfilePage from "./pages/profiles/profile";
import PublicShoppingListPage from "./pages/shoppingLists/publicShoppingList";
import PlannersPage from "./pages/planners/planners";
import PublicRecipePage from "./pages/recipes/publicRecipe";
import ShoppingListPage from "./pages/shoppingLists/shoppingList";
import ShoppingListsPage from "./pages/shoppingLists/shoppingLists";
import SigninPage from "./pages/auth/signin";
import SignupPage from "./pages/auth/signup";
import VerifyPage from "./pages/auth/verify";
import BetaPage from "./pages/sorry/beta";
import { Preferences } from "./config/context/preferencesContext";
import { keyframes, MantineProvider } from "@mantine/core";
import { cleanNotifications, NotificationsProvider, showNotification } from "@mantine/notifications";
import PlannerPage from "./pages/planners/planner";
import PlannerSharePage from "./pages/planners/plannerShare";
import { useEffect } from "react";
import { IconCheck, IconX } from "@tabler/icons";
import { CLEAR_NOTIFICATION } from "./redux/actions";
import { useInterval } from "@mantine/hooks";
import { useColorBackground } from './hooks/useColorBackground';

export const rippleAnimation = keyframes({
	to: {
		opacity: 0,
		transform: "scale(2)",
	}
});
export const swingAnimation = keyframes({
	"0%": { transform: "rotate(-45deg)" },
	"5%": { transform: "rotate(-36deg)" },
	"10%": { transform: "rotate(-27deg)" },
	"15%": { transform: "rotate(-18deg)" },
	"20%": { transform: "rotate(-9deg)" },
	"25%": { transform: "rotate(0deg)" },
	"30%": { transform: "rotate(9deg)" },
	"35%": { transform: "rotate(18deg)" },
	"40%": { transform: "rotate(27deg)" },
	"45%": { transform: "rotate(36deg)" },
	"50% ": { transform: "rotate(45deg)" },
	"55% ": { transform: "rotate(36deg)" },
	"60% ": { transform: "rotate(27deg)" },
	"65% ": { transform: "rotate(18deg)" },
	"70% ": { transform: "rotate(9deg)" },
	"75% ": { transform: "rotate(0deg)" },
	"80% ": { transform: "rotate(-9deg)" },
	"85% ": { transform: "rotate(-18deg)" },
	"90% ": { transform: "rotate(-27deg)" },
	"95% ": { transform: "rotate(-36deg)" },
	"100% ": { transform: "rotate(-45deg)" },
});

function App() {
	const notification = useSelector(state => state.notification);
	const user = useSelector(state => state.user);
	useColorBackground(!user?.uid);
	const dispatch = useDispatch();
	// Can"t get preferences via useContext here for some reason - webpack erorrs.
	const { dark, themeId } = useSelector(state => {
		if (!state.preferences?.themeId) {
			return {
				dark: false,
				themeId: process.env.REACT_APP_DEFAULT_THEME_ID
			}
		} else {
			return state.preferences;
		}
	});

	useEffect(() => {
		if (notification === undefined) return;
		cleanNotifications();
		const {
			id,
			type,
			message,
			title,
		} = notification;
		if (type === "error") {
      showNotification({
        id,
        autoClose: 3500,
        title: title,
        message,
        color: "red",
        icon: <IconX />,
        loading: false,
      });
    } else if (type === "loading") {
      showNotification({
        id,
				autoClose: false,
        title,
        message,
        color: "teal",
        loading: true,
      });
    } else {
      showNotification({
        id,
        autoClose: 2000,
        title,
        message,
        color: "green",
        icon: <IconCheck />,
        loading: false,
      });
    }
		dispatch({ type: CLEAR_NOTIFICATION, payload: {} });
	}, [dispatch, notification]);

	// // prevents screen from starting white then transitioning
	// if (colorIdx !== -1) {
	// 	if (!document.body.style.transition) {
	// 		document.body.style.transition = "background 3s linear";
	// 	}
	// }

	return (
		<Preferences>
			<MantineProvider
				withGlobalStyles
				withNormalizeCSS
				theme={{
					fontFamily: "Work Sans, sans-serif",
					colorScheme: dark ? "dark" : "light",
					primaryColor: themeId.trim(),
					colors: {
						default: ["#dafbff", "#aff4fd", "#83effa", "#55eef6", "#2fdcf2", "#1cb6d9", "#0982aa", "#005379", "#002d4a", "#000e1b"],
						burnt: ["#ffedde", "#f9d3b7", "#f0ba8c", "#eaa361", "#e39036", "#c96a1c", "#9d4914", "#712e0d", "#451503", "#1c0200"],
						cyberpunk: ["#f7e5ff", "#deb7fd", "#c988f7", "#b959f2", "#ab2bed", "#8512d4", "#5d0ca6", "#3b0778", "#1f034a", "#09001d"],
						greenfield: ["#e5fcea", "#bff1c9", "#98e6a5", "#70dc7e", "#48d255", "#30b937", "#249027", "#196719", "#0c3e10", "#001603"],
						marooned: ["#feebf9", "#e8cbe0", "#d4abca", "#c18bb4", "#ae6a9d", "#955183", "#753e66", "#542c4a", "#35192e", "#170513"],
						duskarooned: ["#feebf9", "#e8cbe0", "#d4abca", "#c18bb4", "#ae6a9d", "#955183", "#753e66", "#542c4a", "#35192e", "#170513"],
						faded: ["#dafffb", "#affdfb", "#83f7fa", "#55eef6", "#2ff2ef", "#1cd9ca", "#09aa92", "#00795f", "#004a36", "#001b12"],
						oceanic: ["#dafbff", "#aff4fd", "#83effa", "#55eef6", "#2fdcf2", "#1cb6d9", "#0982aa", "#005379", "#002d4a", "#000e1b"],
						beached: ["#dafbff", "#aff4fd", "#83effa", "#55eef6", "#2fdcf2", "#1cb6d9", "#0982aa", "#005379", "#002d4a", "#000e1b"],
						arctic: ["#dafbff", "#aff4fd", "#83effa", "#55eef6", "#2fdcf2", "#1cb6d9", "#0982aa", "#005379", "#002d4a", "#000e1b"],
						warning: ["#ffe4e3", "#ffb9b4", "#fb8d85", "#f76054", "#f43424", "#db1a0b", "#ab1208", "#7a0c04", "#4c0500", "#200000"],
						// new colors: 20230604
						autumn2023: ["#E2E0E0","#D0CACA","#BFB5B5","#B1A1A1","#A58E8E","#9C7B7B","#966868","#836262","#735B5B","#655454","#5A4D4D","#4F4747","#474040"],
						greenery2023: ["#EAEDE6","#C1CCB3","#A0B584","#85A859","#677D4B","#505E3F","#3E4733","#303629","#262921","#1D1F1B"],
						aqua2023: ["#E0F2EC","#A7D7C6","#7BBFA7","#59A88C","#42876E","#316C57","#245644","#1A4536","#12382B","#0D2D22"],
						winter2023: ["#C9DAE9","#9AB9D2","#769DBC","#5985A8","#446C8C","#345874","#284861","#1E3A51","#162F43","#102738"],
						purple2023: ["#D3CBE5","#AA97D1","#8769C3","#6942B8","#563A8E","#46316F","#392A56","#2E2343","#251E34","#1E1929"],
						pink2023: ["#E9C9E2","#D29AC6","#BC76AD","#A85997","#8C447C","#743466","#612854","#511E46","#43163A","#381030"],
						summer2023: ["#E9C9C9","#D29A9A","#BC7676","#A85959","#8C4444","#743434","#612828","#511E1E","#431616","#381010"],
					},
					title: {
						userSelect: "none",
						msUserSelect: "none",
						MozUserSelect: "none",
						WebkitUserSelect: "none",
					},
					loader: "oval",
					components: {
						// Button:{
						// 	defaultProps: {
						// 		sx: {
						// 			color: document.body.style.backgroundColor,
						// 			backgroundColor: "white",
						// 			'&:hover': {
						// 				backgroundColor: "#343434",
						// 				color: 'white',
						// 			},
						// 			'&[data-disabled]': {
						// 				color: '#878787',
						// 				'& svg': { stroke: document.body.style.backgroundColor },
						// 			},
						// 		}
						// 	}
						// },
						ActionIcon: {
							defaultProps: {
								radius: "lg",
							},
						},
						Tabs: {
							defaultProps: {
								variant: "pills",
							},
						},
						TextInput: {
							defaultProps: {
								size: "lg",
							},
						},
						Notification: {
							defaultProps: {
								position: "bottom-center",
							}
						},
					},
					other: {
						rowItemPaddingModifier: 0.8,
						lineItem: {
							cursor: "pointer",
						},
						fancyHeader: {
							margin: 0,
							color: "white",
							// textShadow: "0 0 0.05em #fff, 0 0 0.2em #fe05e1, 0 0 0.3em #fe05e1",
							letterSpacing: "1.5px",
						},
						detailBarItem: {
							position: "absolute",
							marginTop: "0.8rem",

							[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
								marginTop: "0rem",
								position: "default",
							},
						},
						backBtn: {
							top: 0,
							left: "13.5%",
							zIndex: 500,

							[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
								display: "block",
								left: 10,
								paddingTop: "0.2rem",
								paddingBottom: "0.2rem",
							},
							[`@media (max-width: ${process.env.REACT_APP_SCREEN_MD_BREAKPOINT}px)`]: {
								display: "block",
								left: "1%",
								paddingTop: "0.2rem",
								paddingBottom: "0.2rem",
							},
						},
						innerContainer: {
							margin: "auto",
							width: "75%",

							"@media (max-width: 1200px)": {
								width: "80%",
							},
							[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
								width: "100%",
							},
						},
						title: {
							textAlign: "left",
							fontWeight: 800,
							fontSize: 32,
							fontFamily: `Greycliff CF, Work Sans, sans-serif`,
							margin: "none",
							// color: dark ? 'white' : colors.gray[7],

							[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
								fontWeight: 600,
								fontSize: 18,
							},
						},
						wrapper: {
							position: "relative",
							maxWidth: "46rem",

							[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
								// paddingBottom: 60,
								width: "100%",
							},
							[`@media (min-width: ${process.env.REACT_APP_SCREEN_MD_BREAKPOINT}px)`]: {
								// paddingBottom: 60,
								width: "64rem",
								maxWidth: "none",
							},
						},
						secondaryShade: dark ? 4 : 8,
						breakpoint: [`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`],
						preventSelection: {
							userSelect: "none",
							msUserSelect: "none",
							MozUserSelect: "none",
							WebkitUserSelect: "none",
						},
						rowColorHueIndex: 6,
						rowColorDarkHueIndex: 8,
						colorMap: [
							"default",
							"burnt",
							"cyberpunk",
							"greenfield",
							"marooned",
							"duskarooned",
							"faded",
							"oceanic",
							"beached",
							"arctic",
						],
						defaultColorMap: [
							"autumn2023",
							"greenery2023",
							"aqua2023",
							"winter2023",
							"pink2023",
							"summer2023",
						]
					},

					shadows: {
						md: "1px 1px 3px rgba(0, 0, 0, .25)",
						xl: "5px 5px 3px rgba(0, 0, 0, .25)",
					},

					headings: {
						fontFamily: "Work Sans, sans-serif",
						sizes: {
							h1: { fontSize: 30 },
						},
					},
				}}
			>
				<NotificationsProvider position={"bottom-center"} zIndex={8000}>
					<BrowserRouter>
						<Routes>
							<Route exact path="/logout" element={<LogoutPage />} />
							<Route exact path="/beta" element={<BetaPage />} />
							<Route exact path="/sorry" element={<Home error404 />} />
							<Route exact path="/verify" element={<VerifyPage />} />
							{document.location.origin !== process.env.REACT_APP_PROD_ORIGIN
								&& <Route exact path="/test" element={<TestPage />} />
							}
							<Route exact path="/menu" element={<MenuPage />} />
							<Route exact path="/buddy/:id" element={<PublicShoppingListPage />} />
							<Route exact path="/noms/:id" element={<PublicRecipePage />} />
							<Route exact path="/profiles/:id" element={<ProfilePage />} />
							<Route exact path="/planners" element={<PlannersPage />} />
							<Route exact path="/planners/:id/share" element={<PlannerSharePage />} />
							<Route exact path="/planners/:id" element={<PlannerPage />} />
							<Route exact path="/recipes" element={<RecipesPage />} />
							<Route exact path="/recipes/:id" element={<RecipePage />} />
							<Route exact path="/shopping" element={<ShoppingListsPage />} />
							<Route exact path="/shopping/:id" element={<ShoppingListPage />} />
							<Route exact path="/shoppinglists" element={<ShoppingListsPage />} />
							<Route exact path="/shoppinglists/:id" element={<ShoppingListPage />} />
							<Route exact path="/auth/signin" element={<SigninPage />} />
							<Route exact path="/auth/signup" element={<SignupPage />} />
							<Route exact path="/" element={<Home />} />
							<Route path="/" element={<Home />} />
							<Route path="*" element={<Home />} />
						</Routes>
					</BrowserRouter>
				</NotificationsProvider>
			</MantineProvider>
		</Preferences >
	);
}

export default App;
