import React from "react";
import { useParams } from "react-router-dom";

import Recipe from "../../components/Recipes/Recipe";
import SecurePage from "../../config/auth/SecurePage";

const RecipePage = () => {
  let params = useParams();

  return (
    <SecurePage>
      <Recipe id={params.id} />
    </SecurePage>
  );
};

export default RecipePage;
