import React from 'react';
import { Box, Center, createStyles, Text } from '@mantine/core';

const useStyles = createStyles(theme => {
  return ({
    box: {
      borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
      width: '100%',
    },

    title: {
      textAlign: 'left',
      fontSize: 25,
      letterSpacing: '1px',
      fontWeight: 'normal',
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,

      [`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
        fontSize: 20,
        textAlign: 'left',
      },
    },
  });
});

const InfoBox = ({ text = '' }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.box} p={'lg'}>
      <Center>
        <Center p={'xl'}>
          <Text
            sx={{ textAlign: 'center' }}
            className={classes.title}
          >
            {text}
          </Text>
        </Center>
      </Center>
    </Box>
  );
};

export default InfoBox;
