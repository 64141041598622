import React, { memo, useContext } from 'react';

import { PreferencesContext } from '../../config/context/preferencesContext';
import { Affix, Button, createStyles, Transition, useMantineTheme } from '@mantine/core';
import { useLagNavigate } from '../../hooks/useLagNavigate';
import * as Controls from './Controls';
import { IconBookmark, IconBookmarkOff, IconCheck, IconChefHat, IconPlus, IconRotate, IconShare, IconWand } from '@tabler/icons';
import { HelpWrapper, sendHelp } from '../Utils/HelpWrapper';

const SIDE_MARGIN_XXXL = 44;
const SIDE_MARGIN_XXL = 33;
const SIDE_MARGIN_XL = 22;
const SIDE_MARGIN_LG = 4;
const SIDE_MARGIN_MD = 2;
const SIDE_MARGIN_SM = 1;
const VERT_MARGIN_TOP = '6.5rem';
const VERT_MARGIN_MID = '4rem';
const VERT_MARGIN_BOT = '1.5rem';
const useStyles = createStyles((theme, { rightHanded }) => {
	return ({
		btn: {
			left: rightHanded ? null : `${SIDE_MARGIN_LG}em !important`,
			right: rightHanded ? `${SIDE_MARGIN_LG}em !important` : null,

			[`@media screen and (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				left: rightHanded ? null : `${SIDE_MARGIN_SM}em !important`,
				right: rightHanded ? `${SIDE_MARGIN_SM}em !important` : null,
			},
			[`@media screen and (max-width: ${process.env.REACT_APP_SCREEN_MD_BREAKPOINT}px) and (min-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				left: rightHanded ? null : `${SIDE_MARGIN_MD}em !important`,
				right: rightHanded ? `${SIDE_MARGIN_MD}em !important` : null,
			},
			[`@media screen and (min-width: ${process.env.REACT_APP_SCREEN_XL_BREAKPOINT}px)`]: {
				left: rightHanded ? null : `${SIDE_MARGIN_XL}em !important`,
				right: rightHanded ? `${SIDE_MARGIN_XL}em !important` : null,
			},
			[`@media screen and (min-width: ${process.env.REACT_APP_SCREEN_XXL_BREAKPOINT}px)`]: {
				left: rightHanded ? null : `${SIDE_MARGIN_XXL}em !important`,
				right: rightHanded ? `${SIDE_MARGIN_XXL}em !important` : null,
			},
			[`@media screen and (min-width: ${process.env.REACT_APP_SCREEN_XXXL_BREAKPOINT}px)`]: {
				left: rightHanded ? null : `${SIDE_MARGIN_XXXL}em !important`,
				right: rightHanded ? `${SIDE_MARGIN_XXXL}em !important` : null,
			},
		},
		btnTop: {
			marginBottom: VERT_MARGIN_TOP,
		},
		btnMid: {
			marginBottom: VERT_MARGIN_MID,
		},
		btnBot: {
			marginBottom: VERT_MARGIN_BOT,
		},
	});
});

const POSITIONS = {
	0: "BOT",
	1: "MID",
	2: "TOP",
}

const AffixedButton = ({
	label,
	mounted,
	position = 0,
	transition = "fade",
	classes,
	fn,
	meta,
}) => {
	const theme = useMantineTheme();
	const { help } = useContext(PreferencesContext);
	const classNames = [classes.btn];
	switch (POSITIONS[position]) {
		case "TOP":
			classNames.push(classes.btnTop);
			break;
		case "MID":
			classNames.push(classes.btnMid);
			break;
		default:
			classNames.push(classes.btnBot);
	}

	const { icon: BtnIcon, hideHelp, helpFeature } = meta;
	let fnProper = fn;
	if (help.showHelp) fnProper = () => sendHelp(helpFeature, null, null, help);

	return (
		<Affix className={classNames.join(' ')}>
			<HelpWrapper hide={hideHelp} feature={helpFeature}>
				<Transition transition={transition} mounted={mounted}>
					{(transitionStyles) => (
						<Button
							variant="gradient"
							gradient={{
								from: theme.colors[theme.primaryColor][8],
								to: theme.colors[theme.primaryColor][8],
								deg: 105,
							}}
							leftIcon={<BtnIcon size={16} />}
							style={transitionStyles}
							onClick={fnProper || undefined}
						>
							{label}
						</Button>
					)}
				</Transition>
			</HelpWrapper>
		</Affix>
	)
};

const SideControls = ({
	add,
	adding,
	bookmark,
	isPublic = false,
	startRef,
	preferencesOverride,
	profile,
	reset,
	search,
	share,
	source,
	hideAdd = false,
	helpAdd,
	hideShare = true,
	wizard
}) => {
	let { preferences } = useContext(PreferencesContext);
	const theme = useMantineTheme();
	if (preferencesOverride) preferences = preferencesOverride;
	const { classes } = useStyles({ rightHanded: preferences.rightHanded });
	// eslint-disable-next-line
	const [clicked, onProfileClick] = useLagNavigate(
		process.env.REACT_APP_LAG_NAV_MS,
		profile?.fromLabel,
	);

	let shareHelp = undefined;
	switch (source) {
		case 'recipe':
			shareHelp = process.env.REACT_APP_HELP_RECIPE_SHARE_VIA_NOMICON
			break;
		case 'shopping':
			shareHelp = process.env.REACT_APP_HELP_SHOPPING_LIST_SHARE_VIA_NOMICON
			break;
		case 'planner':
			shareHelp = process.env.REACT_APP_HELP_PLANNER_SHARE
			break;
		default:
			shareHelp = undefined;
	}

	const buttonsMeta = [
		{
			label: profile?.name || 'Mystery Chef',
			mounted: !!profile?.id,
			control: Controls.Profile,
			fn: () => onProfileClick(`/profiles/${profile?.id}`),
			meta: {
				icon: IconChefHat,
			}
		},
		{
			label: `${!!bookmark?.bookmarked ? "Bookmarked!" : "Bookmark Recipe"}`,
			mounted: !!bookmark?.show,
			control: Controls.Bookmark,
			fn: bookmark?.fn,
			meta: {
				bookmarked: !!bookmark?.bookmarked,
				icon: !!bookmark?.bookmarked
					? IconCheck
					: IconBookmark,
			},
		},
		{
			label: 'Add',
			mounted: !!add,
			control: Controls.Add,
			fn: add,
			meta: {
				icon: IconPlus,
				helpFeature: helpAdd,
			}
		},
		{
			label: 'Share',
			mounted: !!share && !hideShare,
			control: Controls.Share,
			fn: share,
			meta: {
				icon: IconShare,
				hideHelp: hideShare,
				helpFeature: shareHelp,
			}
		},
		{
			label: 'Start Cooking',
			mounted: !!wizard,
			control: Controls.Wizard,
			fn: wizard,
			meta: {
				icon: IconWand,
				helpFeature: process.env.REACT_APP_HELP_RECIPE_WIZARD,
			},
		},
		{
			label: 'Reset',
			mounted: !!reset,
			control: Controls.Reset,
			fn: reset,
			meta: {
				icon: IconRotate,
				helpFeature: process.env.REACT_APP_HELP_SHOPPING_LIST_RESET,
			}
		},
		// {
		// 	label: 'top',
		// 	mounted: !!startRef && scroll.y > 0,
		// 	control: Controls.Top,
		// 	fn: null,
		// meta: {
		// 	icon: IconArrowUp,
		// },
		// },
	];

	const buttons = [];
	buttonsMeta.forEach(btn => {
		if (buttons.length === 3) return;
		if (btn.mounted) buttons.push(btn);
	});

	return buttons.map(
		(btn, idx) =>
			<AffixedButton
				w={'100%'}
				key={`${btn.label}_${idx}`}
				label={btn.label}
				mounted={btn.mounted}
				position={idx}
				primaryColor={theme.primaryColor}
				classes={classes}
				control={btn.control}
				fn={btn.fn}
				meta={btn.meta}
			/>
	);;
};

export default memo(SideControls);
