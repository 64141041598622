import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Center, Modal, useMantineTheme } from "@mantine/core";
import {
  createPublicShoppingListThunk,
  deletePublicShoppingListThunk,
} from "../../model/shoppingListsPublic";
import { LagLoader } from "../Utils/LagLoader";
import { ShareProgress } from "./ShareProgress";

export const ShareModal = ({ visible, onCancel, preferences }) => {
  const theme = useMantineTheme();
  const [showSharePage, setShowSharePage] = useState(false);
  const [shareEnabled, setShareEnabled] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const publicShoppingList = useSelector(state => state.publicShoppingList);
  const shoppingList = useSelector(state => state.shoppingList);
  const shoppingListRecipes = useSelector(state => state.shoppingListRecipes);

  useEffect(() => {
    if (!shoppingList?.publicShoppingListId && deleting) setDeleting(false);
  }, [shoppingList]);

  useEffect(() => {
    setShowSharePage(!!publicShoppingList?.id)
    return () => { setShowSharePage(false); }
  }, [publicShoppingList]);

  useEffect(() => {
    if (shareEnabled && !showSharePage) {
      shareViaNomicon();
    }
  }, [shareEnabled]);

  const onDelete = () => {
    setDeleting(true);
    dispatch(deletePublicShoppingListThunk(publicShoppingList?.id));
  }
  const generatePin = () => {
    let pin = "";
    for (let i = 0; i < 4; i++) pin = pin + Math.floor(Math.random() * 10)
    return pin;
  }
  const shareViaNomicon = () => {
    const shoppingListExtra = {
      ...shoppingList,
      recipes: shoppingListRecipes?.map(r => ({ sortname: r.sortname })),
    };
    dispatch(createPublicShoppingListThunk(shoppingListRecipes?.length > 0 ? shoppingListExtra : shoppingList, {
      pin: generatePin(),
      preferences
    }));
    setShowSharePage(!showSharePage);
  };

  const progress = (
    <Box>
      <LagLoader preferences={preferences} ms={1000}>
        <Center>
          <ShareProgress
            onDelete={onDelete}
            preferences={preferences}
            publicShoppingListId={publicShoppingList?.id}
          />
        </Center>
      </LagLoader>
    </Box>
  );

  const startButtons = (
    <Center>
      <Button
        key="submit"
        disabled={false}
        onClick={shareViaNomicon}
      >
        Share via Nomicon
      </Button>
    </Center>
  );

  return (
    <Modal
      overlayColor={preferences.dark ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      opened={visible}
      onClose={onCancel}
      title={"Share Shopping List"}
      closeOnEscape
      zIndex={4000}
      closable={"false"}
    >
      {showSharePage && !deleting ? progress : startButtons}
    </Modal>
  );
};
