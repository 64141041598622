import { Indicator, useMantineTheme } from '@mantine/core';
import { cleanNotifications, showNotification } from '@mantine/notifications';
import { IconQuestionMark } from '@tabler/icons';
import React, { useContext } from 'react';
import { PreferencesContext } from '../../config/context/preferencesContext';

export const sendHelp = (feature, featureRef, terms, { showHelp, currFeature, setCurrFeature }) => {
  if (!feature || !showHelp) return;
  // featureRef.current.style.borderColor = 'white';
  setCurrFeature(feature);
  cleanNotifications();
  let helpText = '';

  switch (feature) {
    // HEADER MENU
    case process.env.REACT_APP_HELP_HEADER_BACK_BUTTON:
      helpText = 'This button will take you to the previous screen.';
      break;
    case process.env.REACT_APP_HELP_HEADER_SETTINGS_MENU:
      helpText = 'This will open a settings page where you can personalise your Nomicon experience.';
      break;

    // MENU
    case process.env.REACT_APP_HELP_MENU_SHOPPING_LISTS:
      helpText = 'This will take you through to your shopping lists.';
      break;
    case process.env.REACT_APP_HELP_MENU_SHOPPING_LIST_LATEST:
      helpText = 'This will take you through to your latest shopping list.';
      break;
    case process.env.REACT_APP_HELP_MENU_RECIPES:
      helpText = 'This will take you to your recipe book.';
      break;
    case process.env.REACT_APP_HELP_MENU_PLANNERS:
      helpText = 'This will take you through to your planners.';
      break;

    // PLANNERS
    case process.env.REACT_APP_HELP_PLANNERS_ADD_BUTTON:
      helpText = 'This button will create a new planner for you.';
      break;
    case process.env.REACT_APP_HELP_PLANNER_ADD_BUTTON:
      helpText = 'This button will create a new event to add to your planner.';
      break;
    case process.env.REACT_APP_HELP_PLANNER_SHARE:
      helpText = 'This button will allow you to invite other people to your planner.';
      break;
    case process.env.REACT_APP_HELP_PLANNER_TITLE:
      helpText = 'This is the name of your plan.';
      break;

    // RECIPES
    case process.env.REACT_APP_HELP_RECIPES_ADD_BUTTON:
      helpText = 'This button will start a new recipe for you.';
      break;
    case process.env.REACT_APP_HELP_RECIPES_INDEX_SCROLL:
      helpText = 'Jump to a certain letter in your recipe book.';
      break;
    case process.env.REACT_APP_HELP_RECIPES_RECIPE_LIST_ITEMS:
      helpText = 'Each row shown here represents a recipe that you have either created yourself, or bookmarked from another member of the Nomicon community.';
      break;
    case process.env.REACT_APP_HELP_RECIPES_SEARCH_BAR:
      helpText = 'You can use this to search for your recipes - it will start searching for you, automatically, as you type.';
      break;
    case process.env.REACT_APP_HELP_RECIPES_SWIPE_TO_DELETE:
      helpText = 'You can swipe these rows to delete them.';
      break;
    case process.env.REACT_APP_HELP_RECIPE_TABS:
      helpText = 'You can switch between displaying the Ingredients, Method or Settings of your recipe using these tabs.';
      break;

    // RECIPE
    case process.env.REACT_APP_HELP_RECIPE_ADD_BUTTON:
      helpText = 'Use this button to add ingredients to your recipe; you can add one at a time, or many at once.';
      break;
    case process.env.REACT_APP_HELP_RECIPE_METHOD_ADD_BUTTON:
      helpText = 'Use this button to add a new step to your recipes method.';
      break;
    case process.env.REACT_APP_HELP_RECIPE_COPY:
      helpText = 'This will copy the link to your devices clipboard - you can then paste it to your friends and family.';
      break;
    case process.env.REACT_APP_HELP_RECIPE_INGREDIENTS_TAB:
      helpText = 'This tab displays all of the ingredients of your recipe.';
      break;
    case process.env.REACT_APP_HELP_RECIPE_METHOD_TAB:
      helpText = 'This tab houses your recipes method steps.';
      break;
    case process.env.REACT_APP_HELP_RECIPE_QR_CODE:
      helpText = 'Show this to someone you think could use your awesome recipe (if they deserve it). They just need to scan it with their phone\'s camera or QR reader app to view your recipe on Nomicon; they don\'t need an account to use it.';
      break;
    case process.env.REACT_APP_HELP_RECIPE_TITLE:
      helpText = 'This is the name of your recipe.';
      break;
    case process.env.REACT_APP_HELP_RECIPE_SETTINGS_TAB:
      helpText = 'This tab allows you to delete, or unbookmark, your recipe if you wish.';
      break;
    case process.env.REACT_APP_HELP_RECIPE_WIZARD:
      helpText = 'This button will assist you with the cooking by stepping you through this recipes method in realtime.';
      break;

    // RECIPE MODALS
    case process.env.REACT_APP_HELP_RECIPE_SHARE_VIA_NOMICON:
      helpText = 'You can share your recipe to other people with a link or QR code. Visitors of your recipe will be able to bookmark it in their personal Nomicon.';
      break;
    case process.env.REACT_APP_HELP_RECIPE_STOP_SHARING:
      helpText = 'This will make your recipe private again. Visitors who had already bookmarked it will still have an old copy available.';
      break;

    // SHOPPING LISTS
    case process.env.REACT_APP_HELP_SHOPPING_LISTS_ADD_BUTTON:
      helpText = 'This button will start a new shopping list for you.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LISTS_SWIPE_TO_DELETE:
      helpText = 'You can swipe these rows to delete them.';
      break;

    // SHOPPING LIST
    case process.env.REACT_APP_HELP_SHOPPING_LIST_ADD_BUTTON:
      helpText = 'Use this button to add ingredients to your shopping list; you can add one at a time, or many at once.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_DRAGGABLE_ROW:
      helpText = 'You can drag your items to reorder them; and to create new item groups.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_INGREDIENTS_TAB:
      helpText = 'This tab displays all of the ingredients of your shopping list.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_NEW_RECIPE:
      helpText = 'This button will take you to your recipes list so you can quickly create a new recipe.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_MEAL_PLAN_TAB:
      helpText = 'This tab allows you to browse your recipes; we will automatically add ingredients to your shopping list from the recipes that you select.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_RECIPE_SCROLLER:
      helpText = 'You will find tags appear here as you add recipes to your shopping list. You can tap to remove them from your list.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_REORDERABLE_GROUPS:
      helpText = 'You can reorder items by dragging them up and down. Create new groups by dragging an item toward the top of the list. Rearrange your groups with the arrow buttons.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_RESET:
      helpText = 'This button will untick all of your ingredients.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_SETTINGS_TAB:
      helpText = 'This tab allows you to delete your shopping list if you wish.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_SHARE_BUTTON:
      helpText = 'This opens a screen that allows you to share your shopping list to a buddy. It will provide you with two methods of sharing: QR code or a PIN protected link. Your shopping buddy just needs to either scan the QR code, or navigate to the link, in order to access a copy of your list.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_TITLE:
      helpText = 'This is the name of your shopping list.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_TABS:
      helpText = 'These tabs will help you navigate between your shopping list, and a meal planner where you can add your recipes to it.';
      break;

    // SHOPPING LIST MODALS
    case process.env.REACT_APP_HELP_SHOPPING_LIST_SHARE_VIA_NOMICON:
      helpText = 'This will make your shopping list shareable. We\'ll give you a link to your shopping list that you can forward on to a buddy.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_COPY:
      helpText = 'This will copy the link and PIN to your devices clipboard - you can then paste it to your shopping buddy.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_QR_CODE:
      helpText = 'Show this to your shopping buddy if someone else is going down the shops for you. They just need to scan it with their phone\'s camera, or QR reader app, to view your shopping list on Nomicon; they don\'t need an account to use it and they\'ll be able to tick off items as they go.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_BUDDY_PROGRESS:
      helpText = 'This will show you your buddies progress while they\'re at the shops.';
      break;
    case process.env.REACT_APP_HELP_SHOPPING_LIST_STOP_SHARING:
      helpText = 'This will make your shopping list private again and prevent your buddy from accessing it after they close it.';
      break;

    // PUBLIC SHOPPING LIST
    case process.env.REACT_APP_HELP_PROFILE_BUTTON:
      helpText = `This will take you through to ${terms?.displayName}'s profile.`;
      break;

    // PUBLIC RECIPE
    case process.env.REACT_APP_HELP_BOOKMARK_RECIPE:
      helpText = `This will add a bookmark for ${terms?.displayName} in your personal Nomicon.`;
      break;

    // DEFAULT
    default:
      break;
  }
  if (helpText) {
    showNotification({
      // transitionDuration: 50,
      id: `help-menu-${feature.replace(' ', '-')}`,
      disallowClose: false,
      autoClose: false,
      title: helpText,
      color: 'teal',
      icon: <IconQuestionMark size={20} />,
      loading: false,
      onClose: () => setCurrFeature(''),
    });
  }
}

export const HelpWrapper = ({
  children,
  feature,
  featureRef,
  offset = 5,
  terms = {},
  position = 'top-start',
  hide = false,
}) => {
  const { help } = useContext(PreferencesContext);
  const theme = useMantineTheme();

  return !hide && help?.showHelp ? (
    <Indicator
      color={feature === help?.currFeature ? 'teal' : (theme.primaryColor === 'orange' ? 'red' : 'orange')}
      label={<IconQuestionMark size={20} />}
      position={help?.showHelp ? position : 'none'}
      size={30}
      offset={offset}
      disabled={!help?.showHelp}
      onClick={() => sendHelp(feature, featureRef, terms, help)}
    >
      {children}
    </Indicator>
  ) : children;
}
