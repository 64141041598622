import { useEffect, useState } from 'react';

export function usePageTimers() {
  const [initialLoadFinished, setInitialLoadFinished] = useState(false);

  useEffect(() => {
    if (!initialLoadFinished) {
      setTimeout(() => {
        setInitialLoadFinished(true);
      }, 800);
    }
  }, []);

  return {
    initialLoadFinished
  };
};
