import moment from 'moment';

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
const dayNames = ["Sun.", "Mon.", "Tue.", "Wed.", "Thur.", "Fri.", "Sat."];

export const getDayStringFromDate = (date) => {
  return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;;
}

export const getMonthStringFromDate = (date) => {
  if (!date) return;
  return monthNames[date.getMonth()];
};

export const getLongDayStringFromDate = (date) => {
  return `${dayNames[date.getDay()]} ${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;;
}

export const timestampToJS = (timestamp) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(timestamp);
  return date.toLocaleDateString(undefined, options);
};

export const daysSinceCreation = (createdAt) => {
  return moment.unix(moment().unix()).diff(moment.unix(createdAt / 1000), 'days');
};

export const secondsSinceCreation = (createdAt) => {
  const now = new Date();
  // e.g. ms - 1655337600011
  const msDiff = Math.abs(createdAt - now.getTime());
  return Math.round(msDiff / ( 1000 ));
};