// import app from "firebase/app";
// import app from 'firebase/compat/app';
import { deleteDoc, doc } from 'firebase/firestore';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../config/firebase/firebase';
import * as actions from '../redux/actions';
import { createDocument, getDocument, updateDocument } from './generic';

import { updateShoppingList } from './shoppingLists';

export const getPublicShoppingList = async (publicShoppingListId, pin) => {
	try {
		const publicShoppingList = await getDocument(publicShoppingListId, 'publicShoppingLists');
		publicShoppingList.error = false;
		if (publicShoppingList.pin !== pin) {
			publicShoppingList = { error: true };
		} else {
			if (!publicShoppingList?.started) {
				await updatePublicShoppingList(
					{
						...publicShoppingList,
						started: true
					},
					true
				);
			}
		}
		return publicShoppingList;
	} catch (err) {
		console.error('Error getting public shoppingLists: ', err);
	}
};

export function getPublicShoppingListThunk(publicShoppingListId, pin) {
	return async function inner(dispatch, getState) {
		try {
			let publicShoppingList = await getPublicShoppingList(
				publicShoppingListId,
				pin
			);
			if (!publicShoppingList?.id) publicShoppingList = { error: true };
			dispatch({
				type: actions.LOAD_PUBLIC_SHOPPING_LIST,
				payload: {
					publicShoppingList
				}
			});
		} catch (e) {
			console.error(e);
		}
	};
}

// export const getPublicShoppingListAsOwner = async publicShoppingListId => {
// 	try {
// 		const publicShoppingList = await db
// 			.collection('publicShoppingLists')
// 			.doc(publicShoppingListId)
// 			.get()
// 			.then(snapshot => {
// 				if (!snapshot.empty) {
// 					return { id: snapshot.id, ...snapshot.data(), error: false };
// 				}
// 			});
// 		return publicShoppingList;
// 	} catch (err) {
// 		console.error('Error getting public shoppingLists: ', err);
// 	}
// };

export function getPublicShoppingListAsOwnerThunk(publicShoppingListId) {
	return async function inner(dispatch, getState) {
		try {
			if (!publicShoppingListId) return;
			const publicShoppingList = await getDocument(
				publicShoppingListId, 'publicShoppingLists'
			);
			dispatch({
				type: actions.LOAD_PUBLIC_SHOPPING_LIST_AS_OWNER,
				payload: {
					publicShoppingList
				}
			});
		} catch (e) {
			console.error(e);
		}
	};
}

export const createPublicShoppingList = async publicShoppingList => {
	// TODO: add more rules around how/when we can create public shopping lists
	// 1 per shopping list, etc. Maybe per user too.
	try {
		// BUG
		// WHAT:  if publicShoppingList.recipes is undefined, the add fails & the share modal
		//				hits an infinite loop for some reason.
		// TODO: 	try to share a shopping list and watch this cause an
		//				infinite render loop on the shopping list share modal page.
		// TODO: 	prevent infinite loop.
		if (publicShoppingList?.recipes === undefined) delete publicShoppingList.recipes;
		// /BUG
		const publicShoppingListDoc = await createDocument({
			...publicShoppingList,
			name: publicShoppingList?.name,
			sortname: publicShoppingList?.name?.toUpperCase(),
			ingredients: publicShoppingList.ingredients,
			shoppingListId: publicShoppingList.shoppingListId,
			createdAt: Date.now()
		}, 'publicShoppingLists')
		return publicShoppingListDoc;
	} catch (err) {
		console.error('Error adding public publicShoppingList: ', err);
	}
};

export function createPublicShoppingListThunk(shoppingList, metadata) {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!state.user || !shoppingList || shoppingList.ingredients?.length === 0) return;
		const publicProfile = state.publicProfile;
		let publicShoppingList = {
			name: shoppingList.name,
			shoppingListId: shoppingList.id,
			ingredients: shoppingList.ingredients,
			sections: shoppingList.sections,
			recipes: shoppingList.recipes,
			publicProfileId: publicProfile?.id,
			...metadata,
			createdAt: Date.now()
		};
		publicShoppingList = await createPublicShoppingList(publicShoppingList);
		if (publicShoppingList?.id) {
			shoppingList.publicShoppingListId = publicShoppingList.id;
			await updateShoppingList(state.user.uid, shoppingList);
		}
		dispatch({
			type: actions.PUBLIC_SHOPPING_LIST_CREATED,
			payload: {
				publicShoppingList
			}
		});
	};
}

export const deletePublicShoppingList = async publicShoppingListId => {
	// const db = app.firestore();
	try {
		await deleteDoc(doc(db, `publicShoppingLists`, publicShoppingListId));
		return true;
	} catch (err) {
		console.error(`Error deleting public ShoppingList: ${err}`);
		return false;
	}
};

export function deletePublicShoppingListThunk(publicShoppingListId) {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!state.user || !publicShoppingListId) return;
		await deletePublicShoppingList(publicShoppingListId);
		const updatedShoppingList = await updateShoppingList(state.user.uid, {
			...state.shoppingList,
		});
		delete updatedShoppingList.publicShoppingListId;
		dispatch({
			type: actions.CLEAR_PUBLIC_SHOPPING_LIST,
			payload: {}
		});
		dispatch({
			type: actions.LOAD_SHOPPING_LIST,
			payload: {
				shoppingList: updatedShoppingList
			}
		});
	};
}

export const updatePublicShoppingList = async (
	publicShoppingList,
	preserveOrder = false
) => {
	const sections = [];
	const validatedIngredients = publicShoppingList?.ingredients?.map(
		(ing, idx) => {
			ing.droppableId = ing.droppableId ? ing.droppableId : 'miscellaneous';
			if (
				ing?.droppableId &&
				sections.findIndex(
					section => section.droppableId === ing.droppableId
				) === -1
			) {
				sections.push({
					droppableId: ing.droppableId,
					idx: sections.length
				});
			}
			return {
				...ing,
				id: ing.id ? ing.id : uuidv4(),
				sortname: ing.sortname ? ing.sortname : ing.name?.toUpperCase(),
				bagged: ing.bagged ? ing.bagged : false
			};
		}
	);
	const slist = Object.assign(
		{},
		{
			...publicShoppingList,
			ingredients: validatedIngredients,
			sections,
			modifiedAt: Date.now()
		}
	);
	try {
		await updateDocument(slist, 'publicShoppingLists');
	} catch (err) {
		console.error(`error updating public shopping list ${err}`);
	}
	return slist;
};

export function updatePublicShoppingListThunk(publicShoppingList) {
	return async function inner(dispatch, getState) {
		if (!publicShoppingList) return;
		publicShoppingList = await updatePublicShoppingList(publicShoppingList);
		dispatch({
			type: actions.LOAD_PUBLIC_SHOPPING_LIST,
			payload: {
				publicShoppingList
			}
		});
	};
}

export async function updatePublicShoppingListAsOwnerThunk({
	publicShoppingListId,
	ingredients,
	sections,
	recipes,
}) {
	if (!publicShoppingListId) return;
	// const publicShoppingList = await getPublicShoppingListAsOwner(
	// 	publicShoppingListId
	// );
	const publicShoppingList = await getDocument(
		publicShoppingListId, 'publicShoppingLists'
	);
	if (!publicShoppingList) return;
	const updatedIngredients = ingredients.map(ing => {
		const existingIng = publicShoppingList?.ingredients.find(
			i => i.id === ing.id
		);
		if (existingIng) {
			ing = {
				...existingIng,
				droppableId: ing.droppableId
			};
		} else {
			ing = {
				...ing,
				bagged: false,
				idx: publicShoppingList?.ingredients
					? publicShoppingList?.ingredients.length
					: -1,
				modifiedAt: Date.now(),
				acked: false
			};
		}
		return ing;
	});
	publicShoppingList.recipes = recipes || [];
	await updatePublicShoppingList(
		{
			...publicShoppingList,
			sections,
			ingredients: updatedIngredients,
			modifiedAt: Date.now()
		},
		true
	);
}
