import React, { useContext, useState } from 'react';
import { createStyles, Header, Image, Modal, Burger, Container, useMantineTheme, Grid, Button, Overlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { cleanNotifications } from '@mantine/notifications';
import { PreferencesContext } from '../../config/context/preferencesContext';
import Account from '../Account/Account';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const useStyles = createStyles((theme, { color = 'default', dark, transparent }) => ({
  dark,
  header: {
    backgroundColor: transparent || color === 'loading' ? "inherit" : theme.fn.variant({ variant: 'filled', color, }).background,
    borderBottom: 0,
  },

  burger: {
    order: 2,
  },

  inner: {
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  linkLabel: {
    marginRight: 5,
  },

  preventSelection: {
    ...theme.other.preventSelection,
  }
}));

export function useHeaderMenu({ disabled = false, links, hideHelp = false, transparent = false }) {
  const { preferences, help } = useContext(PreferencesContext);
  const [opened, { toggle }] = useDisclosure(false);
  const theme = useMantineTheme();
  const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark, transparent });
  const [accountOpened, setAccountOpened] = useState(false);
  const isAuthenticated = useSelector(state => !!state.user?.uid);
  const navigate = useNavigate();
  const toggleAccountModal = () => {
    toggle();
    setAccountOpened(!accountOpened);
  }
  if (!links) links = [{ label: 'Settings', links: [{ action: toggleAccountModal, label: 'Settings' }] }];

  const closeOverlay = () => {
    help?.setCurrFeature('');
    help?.setShowHelp(false);
    cleanNotifications();
  };

  const handleLogoClick = () => {
    if (!disabled) navigate('/');
  };

  const HeaderComponent = ({ hideHelp }) => (
    <Header height={56} className={classes.header}>
      {help?.showHelp && !hideHelp && <Overlay onClick={help?.currFeature ? help?.closeNotification : closeOverlay} opacity={0.6} color="#000" zIndex={100} />}
      <Modal
        overlayColor={preferences.dark ? theme.colors.dark[9] : theme.colors.gray[2]}
        overlayOpacity={0.55}
        overlayBlur={3}
        opened={accountOpened}
        onClose={toggleAccountModal}
        title="Settings"
        closeOnEscape
        zIndex={4000}
      >
        <Account />
      </Modal>
      <Container>
        <div className={classes.inner}>
          <Grid columns={3}>
            <Grid.Col span={2}>
              <div style={{ width: 70 }} onClick={handleLogoClick}>
                <Image
                  sx={{ cursor: disabled ? 'default' : 'pointer' }}
                  className={classes.preventSelection}
                  alt="nomicon logo"
                  width={160}
                  src={require("../../assets/nomicon-logo.png")}
                />
              </div>
            </Grid.Col>
          </Grid>
          {help && !hideHelp ? (
            <div style={{ marginLeft: 'auto' }}>
              <Button
                className={classes.helpButton}
                variant={'filled'}
                size={'sm'}
                onClick={() => help?.setShowHelp(!help?.showHelp)}
              >
                Help
              </Button>
            </div>
          ) : null
          }
          {isAuthenticated ? (
            <Burger
              opened={opened}
              onClick={toggleAccountModal}
              color="#fff"
            />
          ) : null}
        </div>
      </Container>
    </Header>
  );

  return HeaderComponent;
}
