import * as actions from '../redux/actions';
import { authoriseUserThunk } from './auth';
import { syncRecipeLetters } from './recipes';
import { getSettings } from './users';
import { db } from '../config/firebase/firebase';
import {
	collection,
	getDocs,
	limit,
	query,
	where,
} from "firebase/firestore";

export const upgradeUserVersion = async uid => {
	const settings = await getSettings(uid);
	if (settings?.dbVersion < process.env.REACT_APP_RECIPE_LETTER_DB_VERSION) {
		await syncRecipeLetters(
			uid,
			process.env.REACT_APP_RECIPE_LETTER_DB_VERSION
		);
		await authoriseUserThunk();
	}
	if (
		settings?.dbVersion <
		process.env.REACT_APP_RECIPE_INGREDIENTS_ARRAY_DB_VERSION
	) {
		await authoriseUserThunk();
	}
	if (settings?.dbVersion < process.env.REACT_APP_RECIPE_TAGS_DB_VERSION) {
		await syncRecipeLetters(uid, process.env.REACT_APP_RECIPE_TAGS_DB_VERSION);
		await authoriseUserThunk();
	}
};

export const getCurrentVersion = async () => {

	try {
		let currentVersion = {};
		const q = query(
			collection(db, 'versions'),
			where('active', '==', true),
			limit(1)
		);
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(version => {
			currentVersion = { id: version.id, ...version.data() };
		});
		return currentVersion;
	} catch (e) {
		throw e;
	}
};

export function getVersionsThunk() {
	return async function inner(dispatch, getState) {
		try {
			const state = getState();
			if (!state?.user) return;
			const version = await getCurrentVersion(state.user.uid);
			dispatch({
				type: actions.LOAD_VERSIONS,
				payload: {
					version: version?.active ? version : { error: true }
				}
			});
		} catch (e) {
			// console.error(e);
		}
	};
}
