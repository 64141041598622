import * as actions from "./actions";
import { byObjProperty } from "../utils/sort";

const initialState = {
  user: null,
  isLoggedIn: false,
  board: null,
  stagedTaskList: null,
  recipeLetter: 'A',
};

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USER:
      return { user: state.user, isLoggedIn: state.isLoggedIn, ...state };

    case actions.CLEAR_NOTIFICATION:
      return Object.assign({}, state, {
        ...state,
        notification: undefined,
      });
    case actions.LOAD_NOTIFICATION:
      const isLoading = action.payload?.type === 'loading';
      return Object.assign({}, state, {
        ...state,
        notification: action.payload,
        isLoading,
      });

    case actions.SET_ERROR:
      const { error } = action.payload;
      return Object.assign({}, state, {
        ...state,
        error,
      });

    case actions.CLEAR_RECIPE:
      return Object.assign({}, state, {
        ...state,
        recipe: null,
        publicRecipe: null,
      });

    case actions.CLEAR_RECIPES:
      return Object.assign({}, state, {
        ...state,
        recipes: null,
        publicRecipes: null,
      });

    case actions.CLEAR_PREFERENCES:
      return Object.assign({}, state, {
        ...state,
        settings: null,
        preferences: null,
        sendHome: false,
        user: null,
        isLoggedIn: false,
        isLoggedOut: false,
      });

    case actions.LOAD_RECIPE:
      const { recipe } = action.payload;
      return Object.assign({}, state, {
        ...state,
        recipe,
      });

    case actions.WINDOW_DIMENSIONS_CHANGED:
      const { windowDimensions } = action.payload;
      return Object.assign({}, state, {
        ...state,
        windowDimensions,
      });

    case actions.LOAD_RECIPES_INFINITE:
      const initRecipeList = action.payload?.recipeLetter !== state.recipeLetter ? [] : state.recipes;
      const existingRecipes = Array.from(initRecipeList || []);
      existingRecipes.push(...action.payload?.recipes);
      return Object.assign({}, state, {
        ...state,
        recipes: existingRecipes,
        recipeLetter: action.payload?.recipeLetter,
        nextRecipePage: action.payload?.nextRecipePage,
      });

    case actions.LOAD_RECIPES:
      const { recipes, recipeLetter } = action.payload;
      return Object.assign({}, state, {
        ...state,
        recipes,
        recipeLetter,
      });

    case actions.LOAD_RECIPES_META:
      return Object.assign({}, state, {
        ...state,
        recipesMeta: action.payload.recipesMeta,
      });

    case actions.LOAD_THEMES:
      const { themes } = action.payload;
      return Object.assign({}, state, {
        ...state,
        themes
      });

    case actions.LOAD_USER:
      const { settings } = action.payload;
      return Object.assign({}, state, {
        ...state,
        settings,
      });

    case actions.LOAD_AUTHORISATION:
      return Object.assign({}, state, {
        ...state,
        auth: action.payload.auth,
        profile: action.payload.profile,
        publicProfile: action.payload.publicProfile,
        recipesMeta: action.payload.recipesMeta,
        version: action.payload.version,
      });

    case actions.LOAD_VERSIONS:
      const { version } = action.payload;
      return Object.assign({}, state, {
        ...state,
        version,
      });

    case actions.PROFILE_UPDATED:
      const { profile } = action.payload;
      return Object.assign({}, state, {
        ...state,
        profile,
      });

    case actions.LOAD_RECIPE_PUBLIC_PROFILE:
      const { recipePublicProfile } = action.payload;
      return Object.assign({}, state, {
        ...state,
        recipePublicProfile,
      });

    case actions.LOAD_SEARCH_PUBLIC_PROFILE:
      const { searchPublicProfile } = action.payload;
      return Object.assign({}, state, {
        ...state,
        searchPublicProfile,
      });

    case actions.LOAD_SEARCH_PUBLIC_RECIPES:
      const { searchPublicRecipes } = action.payload;
      return Object.assign({}, state, {
        ...state,
        searchPublicRecipes,
      });

    case actions.LOAD_PUBLIC_PROFILE:
      const { publicProfile } = action.payload;
      return Object.assign({}, state, {
        ...state,
        publicProfile,
      });

    case actions.LOAD_PREFERENCES:
      const { preferences } = action.payload;
      return Object.assign({}, state, {
        ...state,
        preferences,
      });

    case actions.LOAD_SELECTED_RECIPES:
      const { shoppingListRecipes } = action.payload;
      const sorted = shoppingListRecipes?.sort(byObjProperty("sortname"))
      return Object.assign({}, state, {
        ...state,
        shoppingListRecipes: sorted
      });

    case actions.CLEAR_INGREDIENTS:
      return Object.assign({}, state, {
        ...state,
        ingredients: null,
      });

    case actions.CLEAR_NEXT:
      return Object.assign({}, state, {
        ...state,
        nextPage: null,
      });

    case actions.LOAD_INGREDIENTS:
      const { ingredients } = action.payload;
      return Object.assign({}, state, {
        ...state,
        ingredients,
      });

    case actions.CLEAR_SHOPPING_LIST:
      return Object.assign({}, state, {
        ...state,
        shoppingList: null,
        publicShoppingList: null,
        shoppingListRecipes: null
      });

    case actions.LOAD_SHOPPING_LIST_LATEST:
      const { shoppingListLatest } = action.payload;
      return Object.assign({}, state, {
        ...state,
        shoppingListLatest,
      });

    case actions.LOAD_SHOPPING_LIST:
      const { shoppingList } = action.payload;
      return Object.assign({}, state, {
        ...state,
        shoppingList,
      });

    case actions.LOAD_SHOPPING_LISTS:
      const { shoppingLists } = action.payload;
      return Object.assign({}, state, {
        ...state,
        shoppingLists,
      });

    case actions.PUBLIC_RECIPE_CREATED:
      return Object.assign({}, state, {
        ...state,
        publicRecipe: action.payload.publicRecipe
      });

    case actions.LOAD_PUBLIC_RECIPE:
      const { publicRecipe } = action.payload;
      return Object.assign({}, state, {
        ...state,
        publicRecipe,
      });

    case actions.PUBLIC_SHOPPING_LIST_CREATED:
      return Object.assign({}, state, {
        ...state,
        publicShoppingList: action.payload.publicShoppingList
      });

    case actions.LOAD_PUBLIC_SHOPPING_LIST:
      const { publicShoppingList } = action.payload;
      return Object.assign({}, state, {
        ...state,
        publicShoppingList,
        ingredients: publicShoppingList.ingredients,
      });

    case actions.LOAD_PUBLIC_SHOPPING_LIST_AS_OWNER:
      return Object.assign({}, state, {
        ...state,
        publicShoppingList: action.payload.publicShoppingList,
      });

    case actions.CLEAR_PUBLIC_SHOPPING_LIST:
      return Object.assign({}, state, {
        ...state,
        publicShoppingList: null,
      });
    
    case actions.CLEAR_PLANNER_EVENT:
      return Object.assign({}, state, {
        ...state,
        plannerEvent: undefined,
      });
    case actions.CLEAR_PLANNER_EVENTS:
      return Object.assign({}, state, {
        ...state,
        plannerEvents: undefined,
      });
    case actions.CLEAR_PLANNER_EVENT_RECIPE:
      return Object.assign({}, state, {
        ...state,
        eventRecipe: undefined,
      });
    case actions.CLEAR_PLANNER_SHARE_ACKNOWLEDGED:
      return Object.assign({}, state, {
        ...state,
        plannerShareLinkAcknowledged: undefined,
      });
    case actions.CLEAR_PLANNER_SHARE_LINK:
      return Object.assign({}, state, {
        ...state,
        plannerShareLink: undefined,
      });
    case actions.LOAD_PLANNER_SHARE_ACKNOWLEDGED:
      const { plannerShareLinkAcknowledged } = action.payload;
      return Object.assign({}, state, {
        ...state,
        plannerShareLinkAcknowledged,
      });
    case actions.LOAD_PLANNER_EVENT_RECIPE:
      const { eventRecipe } = action.payload;
      return Object.assign({}, state, {
        ...state,
        eventRecipe: eventRecipe,
      });
    case actions.LOAD_PLANNER_SHARE_LINK:
      const { plannerShareLink } = action.payload;
      return Object.assign({}, state, {
        ...state,
        plannerShareLink: plannerShareLink,
      });
    case actions.LOAD_PLANNER_RESPONSE:
      const { plannerResponse } = action.payload;
      return Object.assign({}, state, {
        ...state,
        plannerCreationResponse: plannerResponse,
      });
    case actions.LOAD_PLANNER_EVENT:
      const { plannerEvent } = action.payload;
      return Object.assign({}, state, {
        ...state,
        plannerEvent,
      });
    case actions.LOAD_PLANNER_EVENTS:
      const { plannerEvents } = action.payload;
      return Object.assign({}, state, {
        ...state,
        plannerEvents,
      });
    case actions.LOAD_PLANNER_ACCESS_UPDATED:
      const { plannerAccessResponse } = action.payload;
      return Object.assign({}, state, {
        ...state,
        plannerAccessResponse,
      });
    case actions.LOAD_PLANNER:
      const { planner } = action.payload;
      return Object.assign({}, state, {
        ...state,
        planner,
      });
    case actions.LOAD_PLANNERS:
      const { planners } = action.payload;
      return Object.assign({}, state, {
        ...state,
        planners,
      });
    case actions.CLEAR_PLANNER_RESPONSE:
      return Object.assign({}, state, {
        ...state,
        plannerCreationResponse: null,
      });
    case actions.CLEAR_PLANNER:
      return Object.assign({}, state, {
        ...state,
        planner: null,
      });
    case actions.CLEAR_PLANNERS:
      return Object.assign({}, state, {
        ...state,
        planners: null,
      });
    case actions.DATABASE_WORKING:
      const { databaseWorking } = action.payload;
      return Object.assign({}, state, {
        ...state,
        databaseWorking,
      });

    case actions.SET_USER:
      return {
        user: action.payload.user,
        isLoggedIn: action.payload.isLoggedIn,
        isLoggedOut: action.payload.isLoggedOut || false,
        sessionExpired: action.payload.sessionExpired || false,
      };

    case actions.SIGNOUT_USER:
      return { user: null, isLoggedIn: false, settings: null };

    default:
      return state;
  }
};
