import React, { useContext } from 'react';
import styles from '../../styles/Home.module.scss';
import { ActionIcon, Box, createStyles, Grid, Text, Title, useMantineTheme } from '@mantine/core';
import { INGREDIENT_TYPES } from '../Ingredient/Ingredient';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { IconToolsKitchen } from '@tabler/icons';
import { useSelector } from 'react-redux';


const useStyles = createStyles((theme, { color = 'default', dark }) => {
	return ({
		box: {
			...theme.other.preventSelection,
			backgroundColor: theme.colors[color][theme.other.rowColorHueIndex],
			borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
			color: 'white',
			padding: '1rem',
		},
	});
});

const PlannerEventRow = ({
	name,
	createdBy,
	onClick,
  showButton,
  onClickButton,
}) => {
	let { preferences } = useContext(PreferencesContext);
	const theme = useMantineTheme();
	const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
	const publicProfile = useSelector(state => state.publicProfile);

	return (
		<Box
			className={classes.box}
			sx={{ ...theme.other.lineItem, width: '100%', }}
		>
      <Grid grow columns={12}>
        <Grid.Col span={10} onClick={onClick}>
          <Title order={4}>{name}</Title>
        </Grid.Col>
        {showButton && createdBy === publicProfile.id && (
          <Grid.Col span={'auto'} onClick={onClickButton}>
            <ActionIcon radius={'sm'} variant={'filled'} color='white'>
              <IconToolsKitchen color={'white'}/>
            </ActionIcon>
          </Grid.Col>
        )}
      </Grid>
		</Box>
	);
};

export default PlannerEventRow;
