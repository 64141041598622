import { createStyles, Card, Text, UnstyledButton, Title } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { HelpWrapper } from '../Utils/HelpWrapper';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[10],
    height: '100%',
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: theme.radius.md,
    width: '100%',
    transition: 'box-shadow 150ms ease, transform 100ms ease',

    '&:hover': {
      boxShadow: `${theme.shadows.md} !important`,
      transform: 'scale(1.05)',
    },
  },
}));

const MenuOption = ({ idx, item }) => {
  const { classes, theme } = useStyles();
  const [clicked, setClicked] = useState(false);
  const color = theme.colorScheme === 'dark' ? 'white' : 'black'; //theme.colors.gray[7]

  useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        item.action();
      }, 400);
    }
  }, [clicked]);

  const { feature, ref, showHelp, position } = item.help;

  return (
    <HelpWrapper
      feature={feature}
      featureRef={ref}
      showHelp={showHelp}
      position={position}
    >
      <Card
        key={idx}
        withBorder
        radius="md"
        className={classes.card}
        p={0}
        mt={idx === 0 ? '0' : 'lg'}
        sx={{ backgroundColor: clicked ? theme.colors[theme.primaryColor][6] : undefined }}
      >
          <UnstyledButton
            key={item.title}
            className={classes.item}
            onClick={() => setClicked(true)}
            p={'2.5rem'}
          >
            <item.icon
              size={48}
              color={clicked ? 'white' : color}
            />
            <Title
              order={2}
              mt={7}
              sx={{
                fontWeight: 'light',
                letterSpacing: '0.2rem',
              }}
              color={clicked ? 'white' : color}
            >
              {item.title}
            </Title>
          </UnstyledButton>
      </Card>
    </HelpWrapper>
  );
};


export function ActionsGrid({ menuItems }) {

  const items = menuItems.map((item, idx) => <MenuOption key={idx} idx={idx} item={item} />);

  return items;
}
