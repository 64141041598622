import { v4 as uuidv4 } from 'uuid';
import * as actions from '../redux/actions';
import { updatePublicShoppingListAsOwnerThunk } from './shoppingListsPublic';
import {
	createDocument,
	getDocument,
	handleError,
	updateDocument,
} from './generic';
import { db } from '../config/firebase/firebase';
import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	limit,
	orderBy,
	query,
	updateDoc
} from 'firebase/firestore';
import sendNotification from '../components/Utils/notificationHandler';
import { byObjProperty } from '../utils/sort';

const ERROR_MSGS = {
	shoppingList404: "We couldn't find that shopping list. 😬",
	recipeNoName: "We can't create a recipe without a name."
};

export function updateShoppingListThunk(shoppingList) {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!state.user || !shoppingList) return;
		const shoppingListRecipes = state.shoppingListRecipes;
		const slist = await updateDocument(shoppingList, 'shoppingLists', state.user.uid,);
		if (state.shoppingList.publicShoppingListId) {
			await updatePublicShoppingListAsOwnerThunk({ ...shoppingList, recipes: shoppingListRecipes });
		}
		dispatch({
			type: actions.LOAD_SHOPPING_LIST,
			payload: { shoppingList: slist }
		});
	};
}

export function updateShoppingListIngredientsThunk(id, ingredients) {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!state.user || !ingredients || !id) return;
		const shoppingList = await updateShoppingList(state.user.uid, {
			...state.shoppingList,
			ingredients
		});
		dispatch({
			type: actions.LOAD_SHOPPING_LIST,
			payload: { shoppingList }
		});
		dispatch({
			type: actions.LOAD_INGREDIENTS,
			payload: {
				ingredients: shoppingList.ingredients
			}
		});
	};
}

export async function getShoppingListRecipes(uid, recipes) {
	// TODO: refactor how we get list recipes added to a shopping list
	// this current approach is Linear (O(n)), each recipe added will invoke a getDocument call on each page load
	// suggest we add recipes to the shopping list with ID + name so we don't need to do this
	const shoppingListRecipes = [];
	for (let i = 0; i < recipes?.length; i++) {
		if (recipes[i]) {
			const recipe = await getDocument(
				recipes[i].id,
				'recipes',
				uid,
			);
			if (recipe) shoppingListRecipes.push(recipe);
		}
	}
	return shoppingListRecipes;
};

export function getShoppingListRecipesThunk() {
	return async function inner(dispatch, getState) {
		try {
			const state = getState();
			if (!state.user || !state.shoppingList || state.shoppingList.recipes === null) return;
			const shoppingListRecipes = await getShoppingListRecipes(
				state.user.uid, state.shoppingList.recipes
			);
			dispatch({
				type: actions.LOAD_SELECTED_RECIPES,
				payload: {
					shoppingListRecipes
				}
			});
			return shoppingListRecipes;
		} catch (e) {
			console.error(e);
		}
	};
}

export const getShoppingLists = async uid => {
	try {
		const q = query(
			collection(db, `users/${uid}/shoppingLists`),
			orderBy('createdAt', 'desc')
		)
		const querySnapshot = await getDocs(q);
		const shoppingLists = [];
		querySnapshot.forEach((shoppingList) => {
			shoppingLists.push({ id: shoppingList.id, ...shoppingList.data() });
		});
		return shoppingLists;
	} catch (e) {
		console.error('Error getting documents: ', e);
	}
};

export function getShoppingListsThunk() {
	return async function inner(dispatch, getState) {
		try {
			const state = getState();
			if (!state.user) return;
			const shoppingLists = await getShoppingLists(state.user.uid);
			dispatch({
				type: actions.LOAD_SHOPPING_LISTS,
				payload: {
					shoppingLists
				}
			});
		} catch (e) {
			console.error(e);
		}
	};
}

export const getLatestShoppingList = async uid => {
	try {
		const q = query(
			collection(db, `users/${uid}/shoppingLists`),
			limit(1),
			orderBy('createdAt', 'desc')
		)
		const querySnapshot = await getDocs(q);
		const shoppingLists = [];
		querySnapshot.forEach((shoppingList) => {
			shoppingLists.push({ id: shoppingList.id, ...shoppingList.data() });
		});
		return shoppingLists.length > 0 ? shoppingLists[0] : null;
	} catch (e) {
		console.error('Error getting documents: ', e);
	}
};

export function getLatestShoppingListThunk() {
	return async function inner(dispatch, getState) {
		try {
			const state = getState();
			if (!state.user) return;
			const shoppingListLatest = await getLatestShoppingList(state.user.uid);
			dispatch({
				type: actions.LOAD_SHOPPING_LIST_LATEST,
				payload: {
					shoppingListLatest,
				}
			});
		} catch (e) {
			console.error(e);
		}
	};
}

export const getShoppingList = async (uid, shoppingListId) => {
	try {
		const shoppingList = await getDocument(shoppingListId, 'shoppingLists', uid);
		if (shoppingList === undefined) throw ERROR_MSGS.shoppingList404;
		return shoppingList;
	} catch (err) {
		throw err;
	}
};

export function getShoppingListThunk(shoppingListId) {
	return async function inner(dispatch, getState) {
		try {
			const state = getState();
			if (!state.user) return;
			const shoppingList = await getDocument(
				shoppingListId,
				'shoppingLists',
				state.user.uid,
			);
			if (!shoppingList) throw ERROR_MSGS.shoppingList404;
			dispatch({
				type: actions.LOAD_SHOPPING_LIST,
				payload: {
					shoppingList
				}
			});
		} catch (err) {
			if (err === ERROR_MSGS.shoppingList404) {
				handleError(
					{
						msg: err
					},
					dispatch
				);
			}
		}
	};
}

export const createShoppingList = async (uid, shoppingList) => {
	try {
		const shoppingListDoc = await createDocument({
			name: shoppingList.name,
			sortname: shoppingList.name?.toUpperCase(),
			ingredients: [],
			sections: [],
			createdAt: Date.now()
		}, 'shoppingLists', uid);
		return shoppingListDoc;
	} catch (err) {
		console.error('Error adding document: ', err);
	}
};

export function createShoppingListThunk(shoppingList) {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!shoppingList || !state.user) return;
		sendNotification(dispatch, "create", "loading", "shopping");
		shoppingList = await createShoppingList(state.user.uid, shoppingList);
		sendNotification(dispatch, "create", "success", "shopping");

		dispatch({
			type: actions.LOAD_SHOPPING_LIST,
			payload: {
				shoppingList
			}
		});
	};
}

export const deleteShoppingList = async (uid, shoppingListId) => {
	try {
		await deleteDoc(doc(db, `users/${uid}/shoppingLists`, shoppingListId));
		return true;
	} catch (err) {
		console.error(`Error deleting shoppingList: ${err}`);
		return false;
	}
};

export function deleteShoppingListThunk(shoppingList) {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!shoppingList || !state.user) return;
		await deleteShoppingList(state.user.uid, shoppingList.id);
		const shoppingLists = await getShoppingLists(state.user.uid);
		dispatch({
			type: actions.LOAD_SHOPPING_LISTS,
			payload: {
				shoppingLists
			}
		});
		dispatch({
			type: actions.CLEAR_SHOPPING_LIST,
			payload: {}
		});
	};
}

/**
 * 
 * @param {*} uid 
 * @param {*} shoppingList 
 * @returns 
 * 
 * This function handles section creation, deletion and order.
 * 
 * If no ingredient exists for a section, it is deleted.
 * If a new ingredient is added with a new droppableId, it is created and goes to the top.
 * 
 */
export const updateShoppingList = async (uid, shoppingList) => {
	const sections = [...shoppingList.sections];
	const validatedDroppableIds = [];
	const validatedIngredients = shoppingList?.ingredients?.map((ing, idx) => {
		ing.droppableId = ing?.droppableId ? ing?.droppableId : 'miscellaneous';
		if (
			validatedDroppableIds.findIndex(
				droppableId => droppableId.droppableId === ing?.droppableId
			) === -1
		) {
			validatedDroppableIds.push(ing.droppableId);
		}
		if (
			sections.findIndex(
				section => section.droppableId === ing?.droppableId
			) === -1
		) {
			// we're adding a new section - it goes to the top
			sections.map((section) => {
				section.idx = section.idx + 1;
				return section;
			})
			sections.push({
				droppableId: ing.droppableId,
				idx: 0
			});
		}

		return {
			...ing,
			id: ing.id ? ing.id : uuidv4(),
			sortname: ing.sortname ? ing.sortname : ing.name?.toUpperCase(),
			bagged: ing.bagged ? ing.bagged : false
		};
	});
	const shoppingListCopy = Object.assign(
		{},
		{
			...shoppingList
		}
	);
	const shoppingListId = shoppingListCopy.id;
	delete shoppingListCopy.id;
	const slist = Object.assign(
		{},
		{
			...shoppingListCopy,
			ingredients: validatedIngredients,
			sections: sections.filter(s => validatedDroppableIds.includes(s.droppableId)).sort(byObjProperty('idx')),
			modifiedAt: Date.now()
		}
	);
	try {
		const path = uid ? `users/${uid}/shoppingLists` : 'shoppingLists';
		await updateDoc(doc(db, path, shoppingListId), slist);
		slist.id = shoppingListId;
	} catch (err) {
		console.error(`error updating shopping list ${err}`);
		slist.id = shoppingListId;
	}

	return slist;
};

export const syncShoppingListRecipeIngredients = async (
	uid,
	shoppingList,
	recipe,
	remove
) => {
	try {
		let ings = [];
		if (remove) {
			ings = shoppingList?.ingredients?.filter(
				ing => ing.recipeId !== recipe.id
			);
		} else {
			const recipeIngs = recipe.ingredients;
			const validatedRecipeIngs = recipeIngs?.filter(ing => !ing.hidden).map(ing => {
				return {
					...ing,
					recipeId: recipe.id,
					droppableId: recipe.name
				};
			});
			if (shoppingList.ingredients === undefined) {
				shoppingList.ingredients = validatedRecipeIngs || [];
			} else if (validatedRecipeIngs) {
				shoppingList.ingredients.push(...validatedRecipeIngs);
			}
			ings = shoppingList.ingredients;
		}

		let updatedSections = shoppingList.sections;
		if (remove) {
			const indexOfSection = updatedSections.findIndex(
				section => section.droppableId === recipe.name
			);
			if (indexOfSection > -1) {
				updatedSections.splice(indexOfSection, 1);
			}
		} else {
			if (updatedSections) {
				const indexOfSection = updatedSections.findIndex(
					section => section.droppableId === recipe.name
				);
				if (indexOfSection === -1) {
					updatedSections.push({
						droppableId: recipe.name,
						idx: shoppingList?.sections.length
					});
				}
			}
		}
		const slist = Object.assign(
			{},
			{
				...shoppingList,
				ingredients: ings?.map(i => Object.assign({}, { ...i })),
				sections: updatedSections?.map(s => Object.assign({}, { ...s })),
				modifiedAt: Date.now()
			}
		);
		await updateDocument(slist, 'shoppingLists', uid);
		return slist;
	} catch (err) {
		console.error(`error syncing recipe ingredients to shopping list ${err}`);
	}
};

export function updateShoppingListRecipesThunk({ recipe, remove = false }) {
	return async function inner(dispatch, getState) {
		const state = getState();
		if (!recipe || !state.user) return;
		const shoppingList = { ...state.shoppingList };
		if (remove) {
			const i = shoppingList.recipes.findIndex(r => r.id === recipe.id);
			if (i < 0) return;
			shoppingList.recipes.splice(i, 1);
		} else {
			if (shoppingList?.recipes) {
				shoppingList.recipes.push({ id: recipe.id, name: recipe.sortname });
			} else {
				shoppingList.recipes = [{ id: recipe.id, name: recipe.sortname }];
			}
		}
		const slist = await syncShoppingListRecipeIngredients(
			state.user.uid,
			shoppingList,
			recipe,
			remove
		);
		dispatch({
			type: actions.LOAD_SHOPPING_LIST,
			payload: {
				shoppingList: slist
			}
		});
		dispatch(getShoppingListRecipesThunk());
		const shoppingListRecipes = await getShoppingListRecipes(state.user.uid, state.shoppingList?.recipes);
		if (state.shoppingList.publicShoppingListId) {
			await updatePublicShoppingListAsOwnerThunk({
				...state.shoppingList,
				recipes: [...shoppingListRecipes?.map(r => ({ sortname: r.sortname }))],
			});
		}
	};
}
