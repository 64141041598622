import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withFirebase } from '../../config/context/firebaseContext';
import * as actions from '../../redux/actions';
import { useHeaderMenu } from '../Layout/HeaderMenu';
import { Box, Button, Center, createStyles, Grid, Space, Text, TextInput, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons';
import { PreferencesContext } from '../../config/context/preferencesContext';
import {
  doConfirmPasswordReset,
  doSignInWithEmailAndPassword,
} from '../../config/firebase/firebase';
import useWindow from '../../hooks/useWindow';

const useStyles = createStyles((theme, { color }) => {
  return ({
    wrapper: {
      position: 'relative',
      paddingTop: 20,
      paddingBottom: 80,

      [`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
        paddingTop: 40,
        paddingBottom: 60,
      },
    },
    section: {
      // background: theme.colors[theme.primaryColor][8],
      width: '100%',
      margin: 'auto',
    },
    skewed: {
      transform: 'skewY(-7deg)',
    },
    backdrop: {
      // background: theme.colors[theme.primaryColor][8],
      height: '100vh',
      width: '100vw',
      position: 'fixed',
    },
    clipped: {
      padding: '3.5rem',
      // background: theme.colors[theme.primaryColor][8],
    },
    clippedHeader: {
      padding: '2rem',
      // background: theme.colors[theme.primaryColor][8],
      clipPath: 'polygon(0 0, 100% 0, 100% 85%, 0 100%)',
    },
    header: {
      background: 'red',
      backgroundSize: 'cover',
      backgroundPosition: 'top',
      position: 'relative',
    },

    box: {
      // backgroundColor: theme.primaryColor,
      borderRadius: process.env.REACT_APP_BORDER_RADIUS_MD,
      color: 'white',
      padding: '1rem',
    },

    inner: {
      position: 'relative',
    },

    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: 62,
      fontWeight: 900,
      lineHeight: 1.1,
      margin: 0,
      padding: 0,
      color: theme.white,

      [theme.other.breakpoint]: {
        fontSize: 42,
        lineHeight: 1.2,
      }
    }
  });
});

const VerifyForm = ({ firebase, email, oobCode }) => {
  const [password, setPassword] = useState('');
  const [forgottenPassword, setForgottenPassword] = useState(false);
  const [showSubscribedMsg, setShowSubscribedMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HeaderMenu = useHeaderMenu({ help: true, disabled: false, transparent: true });
  const { preferences } = useContext(PreferencesContext);
  const { classes } = useStyles({ color: preferences.themeId });
  const { width } = useWindow();


  useEffect(() => {
    if (showSubscribedMsg) {
      showNotification({
        id: 'subscribe-process',
        disallowClose: false,
        autoClose: 5000,
        title: 'Your account is ready! 🎉 You can now sign in below.',
        color: 'teal',
        icon: <IconCheck />,
        loading: false,
      });
    }
  }, [showSubscribedMsg]);

  const onVerify = async evt => {
    setLoading(true);
    let verified = false;
    try {
      await doConfirmPasswordReset(oobCode, password);
      verified = true;
      showNotification({
        id: 'change-password',
        disallowClose: false,
        autoClose: 3000,
        title: "Password Updated",
        message: "We'll sign you in now!",
        color: 'teal',
        icon: <IconCheck />,
        loading: true,
      });
    } catch (err) {
      console.error(err);
      verified = false;
      showNotification({
        id: 'change-password',
        disallowClose: true,
        autoClose: 5000,
        title: "Oops!",
        message: "We couldn't update your password just now - please try again later.",
        color: 'red',
        icon: <IconX />,
        loading: false,
      });
    }

    if (verified) {
      // sign user in and nav to menu
      doSignInWithEmailAndPassword(email, password)
        .then(authUser => {
          dispatch({
            type: actions.SET_USER,
            payload: { user: authUser.user, isLoggedIn: true, version: null }
          });
          setLoading(false);
          navigate('/', { replace: true });
        })
        .catch(err => {
          setLoading(false);
          if (err)
            showNotification({
              id: 'subscribe-process',
              disallowClose: true,
              autoClose: 5000,
              title: "Oops!",
              message: "We couldn't log you in with those details.",
              color: 'red',
              icon: <IconX />,
              loading: false,
            });
        });
    } else {
      setLoading(false);
    }
  };

  const isInvalid = oobCode === '' || password === '';

  return (
    <div>
      <HeaderMenu hideHelp={true} disabled />
      <Center>
        <Grid>
          <Grid.Col>
            <Box sx={{ width: '50%', margin: 'auto', marginTop: '3rem' }}>
              <h1 style={{ textAlign: 'center' }} className={[classes.title, classes.section].join(' ')}>
                Account Recovery.
              </h1>
            </Box>
          </Grid.Col>
          <Grid.Col>
            <Box ml={'lg'} mr={'lg'}>
              <Text sx={{ color: 'white', textAlign: 'center' }}>
                Enter your new password.
              </Text>
            </Box>
          </Grid.Col>
          <Grid.Col>
            <Center>
              <TextInput
                required
                id='password'
                name='password'
                autoComplete='current-password'
                onChange={(event) => setPassword(event.currentTarget.value)}
                placeholder={`Enter a New Password`}
                value={password}
                type={"password"}
                px={width > 800 ? 'md' : 'xl'}
                mt={"lg"}
                label={<span style={{ color: 'white' }}>Password</span>}
                sx={{ minWidth: "6rem" }}
              />
            </Center>
          </Grid.Col>
          <Grid.Col>
            <Center mt={'xl'}>
              <Button
                onClick={() => navigate(`/`)}
                variant={'subtle'}
								sx={{
                  color: 'white',
                  '&:hover': {
										backgroundColor: "#343434",
										color: 'white',
									},
                }}
              >
                Back
              </Button>
              <Space w={'lg'} />
              <Button
                onClick={onVerify}
                disabled={isInvalid || loading}
                variant={'primary'}
								sx={{
									color: document.body.style.backgroundColor,
									backgroundColor: "white",
                  '&:hover': {
										backgroundColor: "#343434",
										color: 'white',
									},
								}}
                loading={loading}
              >
                Change Password
              </Button>
            </Center>
          </Grid.Col>
        </Grid>
      </Center>
    </div>
  );
};

export default withFirebase(VerifyForm);
