import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getDocumentsByAttributeThunk,
	getDocumentThunk
} from '../../model/generic';
import {
	LOAD_SEARCH_PUBLIC_PROFILE,
	LOAD_SEARCH_PUBLIC_RECIPES
} from '../../redux/actions';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { ProfileRecipeListRow } from './ProfileRecipeListRow';
import { useHeaderMenu } from '../Layout/HeaderMenu';
import { Center, Container, createStyles, Image, Grid, useMantineTheme, Title } from '@mantine/core';
import { AccountProfile } from '../Account/AccountProfile';
import { BackButton } from '../Layout/BackButton';
import GenericList from '../GenericList/GenericList';
import useWindow from '../../hooks/useWindow';


const useStyles = createStyles((theme, { color = 'default', dark }) => {
	return ({
		detailBarItem: {
			...theme.other.detailBarItem,
		},

		backBtn: {
			...theme.other.backBtn,
		},
		wrapper: {
			position: 'relative',
			paddingTop: 20,
			paddingBottom: 80,
			paddingLeft: 20,
			paddingRight: 20,

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				paddingBottom: 60,
				paddingLeft: 42,
				paddingRight: 42,
			},
		},
		title: {
			textAlign: 'center',
			fontWeight: 800,
			fontSize: 40,
			letterSpacing: -1,
			color: theme.white,
			marginBottom: theme.spacing.xs,
			fontFamily: `Greycliff CF, ${theme.fontFamily}`,

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				fontSize: 28,
				textAlign: 'center',
			},
		},
		innerContainer: {
			...theme.other.innerContainer,
		},
	});
});

const Profile = ({ id }) => {
	const { preferences } = useContext(PreferencesContext);
	const theme = useMantineTheme();
	const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
	const dispatch = useDispatch();
	const searchPublicProfile = useSelector(state => state.searchPublicProfile);
	const searchPublicRecipes = useSelector(state => state.searchPublicRecipes);
	const nextPage = useSelector(state => state.nextPage);
	const HeaderMenu = useHeaderMenu({ hideHelp: true, disabled: true });
	const [loading, setLoading] = useState(true);
	const { height } = useWindow();
	const pageSize = height > 840
		? process.env.REACT_APP_PAGE_SIZE_DESKTOP
		: process.env.REACT_APP_PAGE_SIZE_MOBILE;

	useEffect(() => {
		dispatch(
			getDocumentThunk(
				id,
				'publicProfiles',
				'searchPublicProfile',
				LOAD_SEARCH_PUBLIC_PROFILE
			)
		);
		dispatch(
			getDocumentsByAttributeThunk(
				'publicRecipes',
				{ field: 'publicProfileId', value: id },
				'searchPublicRecipes',
				LOAD_SEARCH_PUBLIC_RECIPES,
				null,
				'sortname',
				{ nextPage, pageSize: pageSize },
			)
		);
		return () => {
			dispatch({
				type: LOAD_SEARCH_PUBLIC_PROFILE,
				payload: {
					searchPublicProfile: null
				}
			});
			dispatch({
				type: LOAD_SEARCH_PUBLIC_RECIPES,
				payload: {
					searchPublicRecipes: null
				}
			});
		};
	}, [dispatch, id]);

	const totalRecipeCount = searchPublicProfile?.recipesContributed || 0;
  const hasMore = searchPublicRecipes?.length < totalRecipeCount;
	document.body.style.backgroundColor = "white";

	return (
		<>
			<HeaderMenu hideHelp={true} disabled />
			<Container px={0} sx={{ ...theme.other.wrapper }}>
				<Container my={'xs'}>
					<BackButton defaultFrom={'Recipe List'} defaultTo={'/recipes'} />
					<Grid columns={1}>
						<Grid.Col>
							<Title align="center" weight={500} mt="sm" order={1}>
								{searchPublicProfile?.name}
							</Title>
						</Grid.Col>
					</Grid>
					<GenericList
							listOnly
							// upperComponent={<SearchBar search={onSearch} />}
							loading={loading}
							// cols={12}
							objectType={'publicRecipe'}
							// sidebar={recipeIndexScroll}
							// skeletonRowCount={3}
							hasMore={hasMore}
							objectDataFn={{
								nextPage,
								pageSize,
								totalRecords: totalRecipeCount,
								getThunk: getDocumentsByAttributeThunk(
									'publicRecipes',
									{ field: 'publicProfileId', value: id },
									'searchPublicRecipes',
									LOAD_SEARCH_PUBLIC_RECIPES,
									null,
									'sortname',
									{ nextPage, pageSize: pageSize },
								)
							}}
						/>
				</Container>
			</Container>
		</>
	);
};

export default Profile;
