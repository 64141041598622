import { useInterval } from "@mantine/hooks";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";


export const useColorBackground = (on = false) => {
  const [colorIdx, setColorIdx] = useState(-1);
	const colors = ["#7B8CDE", "#508AA8", "#6E9887", "#B27C66", "#654F6F", "#A288E3"];
	const colorInterval = useInterval(() => {
		const colorCount = colors.length;
		const randomColorIdx = Math.floor(Math.random() * colorCount);
		setColorIdx((s) => randomColorIdx);
	}, colorIdx === -1 ? 6000 : 6000);

  useEffect(() => {
		setColorIdx(0);
		colorInterval.start();
		return () => {
			colorInterval.stop();
			setColorIdx(-1);
		};
	}, []);

  if (on) {
    document.body.style.background = colors[colorIdx === -1 ? 0 : colorIdx];
		document.body.style.transition = colorIdx === -1 ? "" : "background 3s linear";
	} else {
    document.body.style.transition = "background 1s linear";
		document.body.style.background = "";
	}

  // prevents screen from starting white then transitioning
	if (colorIdx !== -1) {
		if (!document.body.style.transition) {
			document.body.style.transition = "background 3s linear";
		}
	}

  return undefined;
}