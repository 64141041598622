import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SecurePage from "../../config/auth/SecurePage";
import Planner from "../../components/Planners/Planner";
import PlannerShare from "../../components/Planners/PlannerShare";
import { useSelector } from "react-redux";

const PlannerSharePage = () => {
  const params = useParams();
  const user = useSelector(state => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(`/?p=${params.id}`)
    }
  }, [user]);

  return (
    <PlannerShare id={params.id} />
  );
};

export default PlannerSharePage;
