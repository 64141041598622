/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  createStyles,
  Grid,
  Loader,
  Modal,
  Space,
  Switch,
  Table,
  Tabs,
  Text,
  Title,
  useMantineTheme
} from '@mantine/core';
import { useFullscreen, useHotkeys } from '@mantine/hooks';
import { IconBasket, IconFileText } from '@tabler/icons';

import SideControls from '../Inputs/SidebarControls';
import { IngredientForm } from '../Ingredient/Ingredient';
import {
  deleteRecipeThunk,
  getRecipesMetaThunk,
  getRecipeThunk,
  removeBookmarkThunk,
  syncRecipe,
} from '../../model/recipes';

import { PreferencesContext } from '../../config/context/preferencesContext';
import RecipeMethod, { MethodForm } from './RecipeMethod';
import { useHeaderMenu } from '../Layout/HeaderMenu';
import { ShareRecipeModal } from '../Inputs/ShareRecipeModal';
import { BackButton } from '../Layout/BackButton';
import { DangerousActionModal } from '../Inputs/DangerousActionModal';
import RecipeRow from './RecipeRow';
import useWindow from '../../hooks/useWindow';
import InfoBox from '../Layout/InfoBox';
import { HelpWrapper } from '../Utils/HelpWrapper';
import { RecipeWizard } from './RecipeWizard';
import { getPublicRecipeThunk } from '../../model/publicRecipes';
import { getDocumentThunk, updateDocumentThunk } from '../../model/generic';
import { LOAD_RECIPE, LOAD_RECIPE_PUBLIC_PROFILE } from '../../redux/actions';
import { TitleText } from '../Layout/TitleText';
import { db } from '../../config/firebase/firebase';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { useColorBackground } from '../../hooks/useColorBackground';

const useStyles = createStyles((theme, { color = 'default', dark }) => {
	return ({
		ingredientColumn: {
			width: "60%",
		},
    ingredientHideColumn: {
      textAlign: "center",
    }
	});
});

const Recipe = ({ id, isPublic = false }) => {
  const user = useSelector(state => state.user);
  const publicProfile = useSelector(state => state.publicProfile);
  let recipeDocRec = '';
  if (user) {
    recipeDocRec = `users/${user.uid}/recipes`;
  }
  if (isPublic) {
    recipeDocRec = 'publicRecipes';
  }
  let docDb = null;
  if (recipeDocRec) {
    docDb = doc(db, recipeDocRec, id);
  }
  const [recipe] = useDocumentData(docDb);
  const recipePublicProfile = useSelector(state => state.recipePublicProfile);
  const recipesMeta = useSelector(state => state.recipesMeta);
  const [loading, setLoading] = useState(true);
  const isAuthor = user && publicProfile ? recipe?.publicProfileId === publicProfile.id : false;
  

  useEffect(() => {
    dispatch(getRecipeThunk(id));
    dispatch(getPublicRecipeThunk(id));
    if (!isPublic) dispatch(getRecipesMetaThunk());
  }, []);

  useEffect(() => {
    if (loading) {
      setTimeout(() => setLoading(false), process.env.REACT_APP_LAG_NAV_MS);
    }
  }, [recipe]);

  const getAuthorPublicProfile = publicProfileId => {
    dispatch(
      getDocumentThunk(
        publicProfileId,
        'publicProfiles',
        'recipePublicProfile',
        LOAD_RECIPE_PUBLIC_PROFILE
      )
    );
  }
  useEffect(() => {
    if (recipePublicProfile) return;
    if (recipe?.publicProfileId) {
      getAuthorPublicProfile(recipe.publicProfileId);
    } else if (recipe?.publicProfileId) {
      getAuthorPublicProfile(recipe.publicProfileId);
    }
  }, [recipe]);

  const { preferences, setScrollLocked } = useContext(PreferencesContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const [activeTab, setActiveTab] = useState('ingredients');
  const [sharingRecipe, setSharingRecipe] = useState(false);
  const [addingIngredient, setAddingIngredient] = useState(false);
  const [editingIngredient, setEditingIngredient] = useState(null);
  const [editExisting, setEditExisting] = useState(false);
  const [addingMethod, setAddingMethod] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [methodId, setMethodId] = useState(null);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const HeaderMenu = useHeaderMenu({ help: true, disabled: false });
  const { width } = useWindow();
  const { toggle } = useFullscreen();
  const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
  useColorBackground(false);
  useHotkeys([
    ['a', () => addFn()],
    ['s', () => shareRecipe()],
  ]);

  const ingredientList =
    recipe?.ingredients &&
    recipe?.ingredients.map((ing, idx) => (
      <RecipeRow
        key={idx}
        listItem={ing}
        onClick={() => {
          if (!isPublic) {
            setEditExisting(true);
            setEditingIngredient(ing);
            setAddingIngredient(true);
          }
        }}
      />
    ));
  
  const ingredientRows = () => {
    if (recipe?.ingredients) {
      return recipe?.ingredients.map((ingredient) => (
          <tr key={ingredient.id} style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <td className={classes.ingredientColumn}>{ingredient.name}</td>
            <td className={classes.ingredientHideColumn}>
              <Checkbox
                mt={"md"}
                mb={"md"}
                checked={ingredient.hidden}
                onChange={(event) => {
                  ingredient.hidden = event.currentTarget.checked;
                  editIngredient([ingredient]);
                }}
                sx={{ textAlign: "center", }}
              />
            </td>
          </tr>
        ));
    }
  };
  const ingredientTable = recipe?.ingredients && (
    <Table>
      <thead>
        <tr>
          <th className={classes.ingredientColumn}>Ingredient</th>
          <th><Text className={classes.ingredientHideColumn}>Hide from Shopping List</Text></th>
        </tr>
      </thead>
      <tbody>{ingredientRows()}</tbody>
    </Table>
  )

  const removeBookmark = () => {
    dispatch(removeBookmarkThunk({ id, ...recipe }));
  };
  const bookmarked = recipesMeta?.savedRecipeIds?.indexOf(id) > -1;

  const shareRecipe = () => {
    setSharingRecipe(true);
  };

  const addIngredient = () => {
    setAddingIngredient(true);
  };

  const addIngredients = ingredients => {
    if (id) {
      const ings = recipe?.ingredients
        ? [...recipe?.ingredients, ...ingredients]
        : ingredients;
      onSaveOrder(ings);
    }
    setAddingIngredient(false);
  };

  const cancelIngredient = () => {
    setEditExisting(false);
    setEditingIngredient(null);
    setAddingIngredient(!addingIngredient);
  };

  const cancelMethod = () => {
    setMethodId(null);
    setAddingMethod(false);
  };

  const editMethod = method => {
    setMethodId(method?.id);
    setAddingMethod(true);
  };

  const onDelete = () => {
    const itemIdx = recipe.ingredients.findIndex(
      i => i.id === editingIngredient.id
    );
    recipe.ingredients.splice(itemIdx, 1);
    onSaveOrder(recipe.ingredients);
  };

  const onDeleteRecipe = () => {
    dispatch(deleteRecipeThunk({ id, ...recipe }));
    navigate('/recipes');
  };

  const onSaveOrder = ings => {
    if (id) {
      const processedIngs = ings.map(i => ({ ...i, id: uuidv4() }));
      syncRecipe(dispatch, {
        ...recipe,
        id,
        ingredients: processedIngs
      });
    }
  };

  const editIngredient = ings => {
    const editedItem = ings[0];
    const newIngs = recipe.ingredients.map(i => {
      if (i.id === editedItem.id) {
        return Object.assign({}, editedItem);
      }
      return i;
    });
    onSaveOrder(newIngs);
  };

  const deleteMethod = method => {
    let newSteps = Array.from(recipe?.steps || []);
    newSteps = newSteps.filter(s => s.id !== method.id);
    syncRecipe(dispatch, {
      id,
      ...recipe,
      steps: newSteps
    })
  };
  const submitMethod = method => {
    let newSteps = Array.from(recipe?.steps || []);
    if (method.id) {
      newSteps = recipe.steps.map(s => {
        if (s?.id === methodId) {
          return Object.assign({}, method);
        } else {
          return s;
        }
      });
    } else {
      newSteps.push({
        ...method,
        id: uuidv4(),
      });
    }
    syncRecipe(dispatch, {
      id,
      ...recipe,
      steps: newSteps
    })
  };

  const onCancel = () => {
    setScrollLocked(false);
    toggle();
  };

  const startWizard = () => {
    setScrollLocked(true);
    toggle();
  };

  const renameRecipe = (name) => {
    dispatch(
      updateDocumentThunk({ id, ...recipe, name}, 'recipes', 'recipe', LOAD_RECIPE, true)
    );
  }

  const ingredientModal = (
    <Modal
      overlayColor={preferences.dark ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      opened={addingIngredient}
      onClose={cancelIngredient}
      title={editExisting ? 'Edit Ingredient' : 'Add Ingredient'}
      closeOnEscape
      zIndex={4000}
      closable={"false"}
    >
      <IngredientForm
        source={'ingredient'}
        ingredient={editingIngredient}
        onCancel={cancelIngredient}
        onSubmit={editExisting ? editIngredient : addIngredients}
        onDelete={onDelete}
        editing={editExisting}
      />
    </Modal>
  );

  const selectedStepNumber = () => {
    let stepNumber = recipe?.steps?.length + 1 || 1;
    recipe?.steps?.map((m, idx) => {
      if (m?.id === methodId) {
        stepNumber = idx + 1;
      }
    });
    return stepNumber;
  }
  const toggleIngredient = (method, ingredient) => {
    const currentStep = recipe?.steps?.find(m => m?.id === methodId);
    let modifiedIngredients = currentStep?.ingredients ? [...currentStep.ingredients] : [];
    const ingredientAlreadyChecked = !!currentStep?.ingredients?.find(i => i.id === ingredient.id);
    if (ingredientAlreadyChecked) {
      modifiedIngredients = modifiedIngredients.filter(i => i.id !== ingredient.id);
    } else {
      modifiedIngredients.push(ingredient);
    }
    submitMethod({ ...method, ingredients: modifiedIngredients });
	}
  const method = Object.assign({
    duration: { hours: 0, minutes: 0, seconds: 0 }
  }, recipe?.steps?.find(m => m?.id === methodId) || {});
  const methodModal = (
    <Modal
      overlayColor={preferences.dark ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      opened={addingMethod}
      onClose={cancelMethod}
      title={`Step ${selectedStepNumber()}`}
      closeOnEscape
      zIndex={4000}
      closable={"false"}
    >
      <MethodForm
        step={selectedStepNumber()}
        method={method}
        methodId={methodId}
        onCancel={cancelMethod}
        onSubmit={submitMethod}
        onDelete={deleteMethod}
        ingredients={recipe?.ingredients}
        toggleIngredient={toggleIngredient}
      />
    </Modal>
  );

  const numIngredients = recipe?.ingredients ? recipe?.ingredients?.length : 0;
  const addFn = activeTab === 'method' ? () => setAddingMethod(true) : addIngredient;
  const profileName = recipePublicProfile?.name || 'Mystery Chef';

  const methodTab = isPublic && !recipe?.steps ? null : (
    <Tabs.Tab
      value="method"
      icon={<IconFileText size={14} />}
      sx={{ fontWeight: activeTab === 'method' ? 'bold' : undefined }}
    >
      Method
    </Tabs.Tab>
  );

  const recipeMethodPanel = (
    <>
      <RecipeMethod
        editable={!isPublic && !bookmarked}
        profile={recipePublicProfile}
        shared={recipe?.publicProfileId}
        onEditMethod={editMethod}
        isPublic={isPublic}
        recipe={recipe}
        addStep={() => setAddingMethod(true)}
      />
      <Space h={"xl"} />
      <Space h={"xl"} />
      <Space h={"xl"} />
      <Space h={"xl"} />
      <Space h={"xl"} />
    </>
  );
  const recipeIngredientPanel = (
    <>
      {!isPublic && recipe?.ingredients?.length > 0 && (
        <Switch
          p={'sm'}
          checked={showAdvanced}
          labelPosition={preferences.rightHanded ? 'left' : 'right'}
          onChange={(event) => setShowAdvanced(event.currentTarget.checked)}
          label={"Advanced View"}
          sx={{ textAlign: preferences.rightHanded ? 'right' : 'left' }}
        />
      )}
      {showAdvanced ? ingredientTable : ingredientList}
      {numIngredients === 0 ? (
        <InfoBox text={'Add some ingredients to your recipe - We\'ll help you put them on your shopping list later.'} />
      ) : null}
      {/* <Space h={"xl"} /> */}
      {/* activeTab === 'method' ? process.env.REACT_APP_HELP_RECIPE_METHOD_ADD_BUTTON : process.env.REACT_APP_HELP_RECIPE_ADD_BUTTON */}
      {!isPublic && (
        <Center mt={'sm'}>
          <HelpWrapper feature={process.env.REACT_APP_HELP_RECIPE_ADD_BUTTON}>
				    <Button sx={{ color: preferences.dark ? '#fff' : '#343434'}} variant={'outline'} onClick={addIngredient}>Add Ingredient</Button>
          </HelpWrapper>
			  </Center>
      )}
      <Space h={"xl"} />
      <Space h={"xl"} />
      <Space h={"xl"} />
      <Space h={"xl"} />
      <Space h={"xl"} />
    </>
  )

  let recipeComponent = (
    <Tabs
      defaultValue={'ingredients'}
      onTabChange={setActiveTab}
      value={activeTab}
      color={{ color: theme.colors[theme.primaryColor][2] }}
    >
      <Tabs.List position={width > 800 ? "center" : undefined} grow>
        <Tabs.Tab
          value="ingredients"
          icon={<IconBasket size={14} />}
          sx={{ fontWeight: activeTab === 'ingredients' ? 'bold' : undefined }}
        >
          Ingredients
        </Tabs.Tab>
        {methodTab}
        {/* {(recipe?.isOwner || !isPublic) && <Tabs.Tab value="meta" icon={<IconSettings size={14} />}></Tabs.Tab>} */}
      </Tabs.List>
      <Tabs.Panel value='ingredients'>
        {recipeIngredientPanel}
      </Tabs.Panel>
      <Tabs.Panel value='method'>
        {recipeMethodPanel}
      </Tabs.Panel>
    </Tabs>
  );

  if (width > 800) {
    recipeComponent = (
      <Grid>
        <Grid.Col span={6}>
          <Title order={4} sx={{ textAlign: "center" }}>Ingredients</Title>
          {recipeIngredientPanel}
        </Grid.Col>
        <Grid.Col span={6}>
          <Title order={4} sx={{ textAlign: "center" }}>Method</Title>
          {recipeMethodPanel}
        </Grid.Col>
      </Grid>
    );
  }

  return (
    <>
      <HeaderMenu hideHelp={isPublic} />
      <Container px={0} sx={{ ...theme.other.wrapper }}>
        <Container my={'xs'}>
          <BackButton defaultFrom={'Recipe List'} replace={!isPublic} />
          <Space h={"xs"} />
          <HelpWrapper
            feature={process.env.REACT_APP_HELP_RECIPE_TITLE}
            position={'top-center'}
          >
            <TitleText
              title={recipe?.name}
              renameFn={!isPublic && renameRecipe}
              source={'recipe'}
              maxLength={process.env.REACT_APP_MAX_LENGTH_RECIPE_NAME}
            />
          </HelpWrapper>
          <Space h={"md"} />
          {!loading && recipeComponent}
          {ingredientModal}
          {methodModal}
        </Container>
      </Container>
      {/* <RecipeWizard onCancel={onCancel} steps={recipe?.steps} /> */}
      <ShareRecipeModal
        visible={sharingRecipe}
        onCancel={() => setSharingRecipe(false)}
        preferences={preferences}
      />
      <DangerousActionModal
        title={recipe?.publicProfileId ? "Unbookmark Recipe" : "Delete Recipe"}
        buttonLabel={recipe?.publicProfileId ? "Unbookmark" : "Delete"}
        onDelete={onDeleteRecipe}
        onCancel={() => setDeleting(false)}
        visible={deleting}
      />
      <SideControls
        isPublic={isPublic}
        // add={(!isPublic && width <= 800 && activeTab !== 'meta' && addFn) || undefined}
        // wizard={activeTab === 'method' && recipe?.steps?.length > 0 && startWizard}
        adding={addingIngredient}
        share={(!isPublic && numIngredients > 0 && shareRecipe) || undefined}
        profile={isPublic && {
          id: recipe?.publicProfileId,
          name: profileName,
          fromLabel: recipe?.name
        }}
        source={'recipe'}
        helpAdd={activeTab === 'method' ? process.env.REACT_APP_HELP_RECIPE_METHOD_ADD_BUTTON : process.env.REACT_APP_HELP_RECIPE_ADD_BUTTON}
        hideShare={activeTab !== 'ingredients' || (activeTab === 'ingredients' && numIngredients === 0)}
        bookmark={{
          bookmarked,
          show: isPublic && !isAuthor,
          fn: bookmarked ? removeBookmark : () => navigate(`/?r=${id}`),
        }}
      />
    </>
  );
};

export default Recipe;
