import { LOAD_NOTIFICATION } from "../../redux/actions";

export const NOTIFICATION_ACTIONS = {
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
}

const messageData = {
  shopping: {
    create: {
      loading: "One moment while we create your shopping list.",
      success: "Your shopping list is ready.",
      error: "We weren't able to create your shopping list, please try again.",
    },
  },
  recipe: {
    copying: {
      loading: "One moment while we bookmark this recipe.",
      success: "Recipe is now bookmarked.",
      error: "We weren't able to bookmark this recipe, please try again.",
    },
    create: {
      loading: "One moment while we create your recipe.",
      success: "Your recipe is ready.",
      error: "We weren't able to create your recipe, please try again.",
    }
  },
  planner: {
    create: {
      loading: "One moment while we create your planner.",
      success: "Your planner is ready.",
      error: "We weren't able to create your planner, please try again.",
    },
    read: {
      loading: "One moment while we get your planners.",
      success: "Your planners are ready.",
      error: "We weren't able to get your planners, please try again.",
    },
    share: {
      loading: "One moment while we create your planner link.",
      success: "Your planner link is ready.",
      error: "We weren't able to create a share link for your planner, please try again.",
    },
  },
};

const actionTypes = {
  create: {
    id: "create-item",
  },
  delete: {
    id: "delete-item",
  },
  read: {
    id: "read-item",
  },
  share: {
    id: "share-item",
  },
};

/**
 * 
 * @param {string} action: one of ["create"|"delete"]
 * @param {string} type: one of ["success"|"error"|"loading"]
 * @param {string} source: one of ["recipe"|"shopping"|"planning"]
 * @param {string} title: optionally add a title for the notification.
 * 
 */
export default function sendNotification(dispatch, action, type, source, title = undefined) {
  const messages = messageData[source];
  const notification = {
    id: actionTypes[action]?.id,
    type,
    message: messages[action][type],
    title,
  };
  dispatch({ type: LOAD_NOTIFICATION, payload: { ...notification } });
}
