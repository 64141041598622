import React, { useEffect, useState } from "react";

import SecurePage from "../../config/auth/SecurePage";
import GenericList from "../../components/GenericList/GenericList";
import { useDispatch, useSelector } from "react-redux";
import useWindow from "../../hooks/useWindow";
import { copyRecipeThunk, getRecipeRowsThunk } from "../../model/recipes";
import { Grid } from "@mantine/core";
import { HelpWrapper } from '../../components/Utils/HelpWrapper';
import { RecipeScroll } from "../../components/Recipes/RecipeScroll";
import { CLEAR_RECIPE, LOAD_RECIPES } from "../../redux/actions";
import { SearchBar } from "../../components/Inputs/SearchBar";
import { getDocumentsByArrayThunk } from "../../model/generic";
import { useLocation, useParams } from "react-router-dom";
import { extractParamsFromLocation } from "../../utils/mappings";

const RecipesPage = () => {
  const location = useLocation();
  const params = extractParamsFromLocation(location);
  const recipes = useSelector(state => state.recipes);
  const recipeLetter = useSelector(state => state.recipeLetter);
  const recipesMeta = useSelector(state => state.recipesMeta);
  const nextRecipePage = useSelector(state => state.nextRecipePage);
  const user = useSelector(state => state.user);
  const [recipeBookmarked, setRecipeBookmarked] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { height } = useWindow();
  const pageSize = height > 840
		? process.env.REACT_APP_PAGE_SIZE_DESKTOP
		: process.env.REACT_APP_PAGE_SIZE_MOBILE;
  
  const nearestLetter = () => {
    const recipeCountObj = recipesMeta?.recipeCounts;
    if (!recipeCountObj) return;
    const recipeCountObjKeys = Object.keys(recipeCountObj);
    recipeCountObjKeys.sort();
    let closestLetterFound = undefined;
    recipeCountObjKeys.forEach(key => {
      if (!recipeLetter && recipeCountObj[key] > 0) {
        closestLetterFound = key;
      }
    });
    getRecipe(closestLetterFound);
  }
  const getRecipe = letter => {
    setLoading(true);
    dispatch(getRecipeRowsThunk({
      recipeLetter: letter || 'A',
      pageSize,
    }));
  };

  useEffect(() => {
    dispatch({ type: CLEAR_RECIPE, payload: {} });
    return () => setRecipeBookmarked(false);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (recipesMeta && !recipeBookmarked) {
      if (params?.r && !recipesMeta.savedRecipeIds?.includes(params?.r)) {
        dispatch(copyRecipeThunk(params?.r));
      }
      setRecipeBookmarked(true);
    }
  }, [recipesMeta]); // eslint-disable-line

  useEffect(() => {
		if (loading) {
			setTimeout(() => {
				setLoading(false);
			}, 500);
		}
	}, [recipeLetter]); // eslint-disable-line

  useEffect(() => {
    if (recipeLetter) {
      dispatch(getRecipeRowsThunk({
        recipeLetter: recipeLetter,
        pageSize,
      }));
    } else {
      nearestLetter();
    }
  }, []); // eslint-disable-line
  const nextPage = () => {
    dispatch(getRecipeRowsThunk({
      next: nextRecipePage,
      recipeLetter: recipeLetter,
      pageSize,
    }));
  };
  const onSearch = tagArray => {
    dispatch(getDocumentsByArrayThunk(
      "recipes",
      { field: "tags", array: tagArray },
      "recipes",
      LOAD_RECIPES,
      user.uid,
    ));
  };
  const totalRecipeCount = recipesMeta?.recipeCounts[recipeLetter] || 0;
  const hasMore = recipes?.length < totalRecipeCount;

  const recipeIndexScroll = (
		<Grid.Col span={2}>
			<HelpWrapper
				feature={process.env.REACT_APP_HELP_RECIPES_INDEX_SCROLL}
				position={'top-center'}
			>
				<RecipeScroll
					changeRecipeLetter={getRecipe}
					recipeLetter={recipeLetter}
					recipeIndex={recipesMeta?.recipeCounts}
				/>
			</HelpWrapper>
		</Grid.Col>
	);

  return (
    <SecurePage>
      <GenericList
        upperComponent={<SearchBar search={onSearch} />}
        loading={loading}
        cols={10}
        objectType={'recipe'}
        sidebar={recipeIndexScroll}
        skeletonRowCount={recipesMeta?.recipeCounts[recipeLetter] || 3}
        hasMore={hasMore}
        objectDataFn={{
          nextPage,
          pageSize,
          totalRecords: recipesMeta?.recipeCounts[recipeLetter] || 0
        }}
      />
    </SecurePage>
  );
};

export default RecipesPage;
