// sample data
/**
--storable types
2 tsp salt
2 tsp fresh ground black pepper
¼ cup rice

-- standard amounts
4 sprigs thyme
3 bay leaves
¼ shallot
3 garlic cloves, peeled and finely chopped
10 pearl onions, peeled

-- digit ranges
8 ounces cured pork or 5-6 slices bacon, cut in fine strips
Optional: 6-7 sprigs parsley, for garnish
4-5 medium carrots, peeled and roughly chopped

3g saffron

-- conversions
2 cups (500ml) red wine
2 tbsp (30ml) brandy
2 cups (500ml) beef broth
2 tbsp (50g) tomato paste
¼ cup (32g) flour
2.5 to 3 lbs (1.1-1.4 kg) stewing beef, cubed
4 tbsp (57g) unsalted butter, divided
*/

// standard amounts
// storable types - need to trim spaces on result
export const regStandardAmount = /([¼½¾⅐⅑⅒⅓⅔⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞↉]*|\d*) /gi;

// conversions
export const regConversionAmounts = /([¼½¾⅐⅑⅒⅓⅔⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞↉]|\d).*(\d(centimetres|centimetre|cm|millilitres|ml|inches|inch|in|grams|gram|g|oz|ounces|teaspoon|teaspoons|tsp|tablespoon|tablespoons|tbsp)|(centimetres|centimetre|cm|cm\)|millilitres\)|ml\)|grams\)|g\)|ounces\)|oz\)|teaspoon\)|teaspoons\)|tsp\)|tablespoon\)|tablespoons\)|tbsp\)))/gi;

// interesting one: (([¼½¾⅐⅑⅒⅓⅔⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞↉]|\d)-([¼½¾⅐⅑⅒⅓⅔⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞↉]|\d)|([¼½¾⅐⅑⅒⅓⅔⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞↉]|\d).*(g |ml|cup|cups|ounces|oz|tsp|tbsp))|(([¼½¾⅐⅑⅒⅓⅔⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞↉]|\d))
// digit ranges and storable
export const regFractionOrDigitsOrDigitRange = /(([¼½¾⅐⅑⅒⅓⅔⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞↉]|\d)-([¼½¾⅐⅑⅒⅓⅔⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞↉]|\d) {2}|([¼½¾⅐⅑⅒⅓⅔⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞↉]|\d).*(g |ml|cups|cup|ounces|oz|teaspoon|teaspoons|tsp|tablespoon|tablespoons|tbsp))|(([¼½¾⅐⅑⅒⅓⅔⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞↉]|\d))/gi;

export const regFractions = /([¼½¾⅐⅑⅒⅓⅔⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞↉]|\d)/gi;

export const getValidRecipeName = (recipeName) => {
  if (!recipeName) return;
  const regRecipeCharacters = /([^a-zA-Z0-9]+)/g;
  return recipeName.replace(regRecipeCharacters, '');
}

export const cutOffText = (text, windowWidth) => {
	const maxWidth = 35;
	if (windowWidth < 800) {
		const shortText = text.substring(0, maxWidth);
		return text.length > maxWidth ? shortText + "..." : shortText;
	}
	return text;
}

export const textToIngredients = (text) => {
  if (!text || text === "") return;

  // split text into newlines and loop them
  const ingredients = text.match(/[^\r\n]+/g).map(line => Object.assign({}, { name: line }));

  return ingredients;
}

export const ingredientsToText = (ingredients) => {
  if (!ingredients || ingredients === "") return;

  const ingredientsTextArray = ingredients.map(i => {
    let ingTextRow = i.name;
    if (i.amount) ingTextRow = ingTextRow + ' - ' + i.amount + ' ' + i.type
    return ingTextRow;
  });

  return ingredientsTextArray.join('\n');
}

// const buildIngredientFromLine = line => {
//   let amount = null;
//   let name = null;

  // console.log(`line ${JSON.stringify(line)}`);
  // if (line[0] | 0) {
  //   if (regConversionAmounts.test(line)) {
  //     // e.g. 2 cups (500ml) beef broth
  //     console.log(`regConversionAmounts`);
  //     amount = line.match(regConversionAmounts)[0].trim();
  //     name = line.substring(amount.length, line.length).replace(") ", "");
  //   } else if (regFractionOrDigitsOrDigitRange.test(line)) {
  //     // e.g. ¼ cup rice or 2 tsp salt OR 4-5 medium carrots
  //     console.log(`regFractionOrDigitsOrDigitRange`);
  //     amount = line.match(regFractionOrDigitsOrDigitRange)[0].trim();
  //     name = line.substring(amount.length, line.length);
  //   } else if (regStandardAmount.test(line)) {
  //      // e.g. 4 sprigs thyme
  //     console.log(`regStandardAmount`);
  //     amount = line.match(regStandardAmount)[0].trim();
  //     name = line.substring(amount.length, line.length);
  //   }
  // } else {
  //   if (regFractionOrDigitsOrDigitRange.test(line)) {
  //     // e.g. ¼ cup rice or 2 tsp salt OR 4-5 medium carrots
  //     console.log(`regFractionOrDigitsOrDigitRange`);
  //     amount = line.match(regFractionOrDigitsOrDigitRange)[0].trim();
  //     name = line.substring(amount.length, line.length);
  //   } else {
  //     amount = "";
  //     name = line;
  //   }
  // }

//   return {
//     amount: amount.trim(),
//     name: name.trim(),
//   };
// }

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
