import { createStyles, Box, List, ThemeIcon, Text } from '@mantine/core';
import { IconBolt } from '@tabler/icons';
import { useContext } from 'react';
import { PreferencesContext } from '../config/context/preferencesContext';
import useWindow from '../hooks/useWindow';

export const BREAKPOINT = [`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`];

const useStyles = createStyles((theme, { color = 'default' }) => ({
  wrapper: {
    position: 'relative',
    boxSizing: 'border-box',
    // background: theme.colors[theme.primaryColor][8],
  },
  section: {
    // background: theme.colors[theme.primaryColor][8],
    width: '100%',
    margin: 'auto',
  },
  clipped: {
    // padding: '3.5rem',
    // background: theme.colors[theme.primaryColor][8],
    // clipPath: 'polygon(0 15%, 100% 0, 100% 85%, 0 100%)',
    // clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 69% 100%, 43% 83%, 0 83%)',
  },

  inner: {
    position: 'relative',
    paddingTop: 200,
    paddingBottom: 120,

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 62,
    fontWeight: 900,
    lineHeight: 1.1,
    margin: 0,
    padding: 0,
    color: theme.white,

    [BREAKPOINT]: {
      fontSize: 42,
      lineHeight: 1.2,
    },
  },

  description: {
    marginTop: theme.spacing.xl,
    fontSize: 24,

    [BREAKPOINT]: {
      fontSize: 18,
    },
  },

  controls: {
    marginTop: theme.spacing.xl * 2,

    [BREAKPOINT]: {
      marginTop: theme.spacing.xl,
    },
  },

  control: {
    height: 54,
    paddingLeft: 38,
    paddingRight: 38,

    [BREAKPOINT]: {
      height: 54,
      paddingLeft: 18,
      paddingRight: 18,
      flex: 1,
    },
  },
}));

export function Hero() {
  const { preferences } = useContext(PreferencesContext);
  const { classes } = useStyles({ color: preferences.themeId });
  const { width } = useWindow();
  const textSize = width > 800 ? "lg" : "md";

  return (
    <div className={classes.wrapper}>
      <Box sx={{ width: width > 800 ? '50%' : '80%', margin: 'auto', marginTop: '3rem' }}>
        <h1 style={{ textAlign: 'left' }} className={[classes.title, classes.section].join(' ')}>
          Automate dinner.
        </h1>
        <List
          mt={"md"}
          spacing="md"
          size="sm"
          icon={
            <ThemeIcon mt={"xs"} size={width > 800 ? 30 : 25} radius="xl" variant="white" sx={{ color: document.body.style.backgroundColor }}>
              <IconBolt size={width > 800 ? 20 : 18} stroke={3} />
            </ThemeIcon>
          }
        >
          <List.Item sx={{ color: 'white' }}>
            <Text size={textSize}>Build a library of your favourite recipes</Text>
          </List.Item>
          <List.Item sx={{ color: 'white' }}>
            <Text size={textSize}>Easily create shopping lists from the ingredients your recipes call for</Text>
          </List.Item>
          <List.Item sx={{ color: 'white' }}>
            <Text size={textSize}>Share your recipes and shopping lists with friends and family</Text>
          </List.Item>
          <List.Item sx={{ color: 'white' }}>
            <Text size={textSize}>Create a shareable meal plan and track your household food budget</Text>
          </List.Item>
        </List>
      </Box>
    </div>
  );
};
