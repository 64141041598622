import React, { useContext } from "react";
import { Droppable } from "react-beautiful-dnd";
import { PreferencesContext } from '../../config/context/preferencesContext';
import DraggableRow from "./DraggableRow";
import { ActionIcon, Box, Center, Container, Grid, Space, Text, useMantineTheme } from "@mantine/core";
import { IconChevronDown, IconChevronUp } from "@tabler/icons";

const DroppableBox = ({
  keyId,
  mainActionFn,
  droppableId,
  droppableName,
  droppableItems,
  isFirst,
  isLast,
  isPublic = false,
  preferencesOverride = null,
  moveSection,
  baggedItem,
  showDragActions
}) => {
  let { preferences } = useContext(PreferencesContext);
  const theme = useMantineTheme();

  if (preferencesOverride) preferences = preferencesOverride;

  return (
    <div key={keyId}>
      <Container p={0}>
        <Grid
          mb={'sm'}
          align={'center'}
          gutter={'lg'}
        // px={'sm'}
        >
          {preferences.rightHanded && (
            <Grid.Col span={10} py={0}>
              <Text
                sx={{
                  color: theme.colorScheme === 'dark' ? theme.white : theme.black,
                  textAlign: 'left',
                }}
              >
                {droppableName}
              </Text>
            </Grid.Col>
          )}
          <Grid.Col span={1} p={0}>
            {!isPublic && droppableId !== 'new section' ? (
              <ActionIcon onClick={() => moveSection("down")} disabled={isLast}>
                <IconChevronDown size={16} />
              </ActionIcon>
            ) : null}
          </Grid.Col>
          <Grid.Col span={1} p={0}>
            {!isPublic && droppableId !== 'new section' ? (
              <ActionIcon onClick={() => moveSection("up")} disabled={isFirst}>
                <IconChevronUp size={16} />
              </ActionIcon>
            ) : null}
          </Grid.Col>
          {!preferences.rightHanded && (
            <Grid.Col span={10} pr={'sm'} py={0}>
              <Text
                sx={{
                  color: theme.colorScheme === 'dark' ? theme.white : theme.black,
                  textAlign: 'left',
                }}
              >
                {droppableName}
              </Text>
            </Grid.Col>
          )}
        </Grid>
      </Container>
      <Center sx={{ height: droppableId === 'new section' ? '1.6rem' : '0.5rem' }}>
        {droppableId === 'new section' ? <Space h={'lg'} /> : null}
        <Text sx={{ textAlign: 'center', letterSpacing: '1px', fontWeight: 'normal' }}>
          {!isPublic && droppableId === 'new section' && showDragActions ? ('Drag here to create a group') : ''}
        </Text>
      </Center>
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => {
          return (
            <Box
              p={0}
              ref={provided.innerRef}
            >
              {droppableItems
                && Array.isArray(droppableItems)
                ? droppableItems?.map((listItem, idx) => {
                  return (
                    <DraggableRow
                      key={droppableId + idx}
                      draggableId={droppableId + idx}
                      index={idx}
                      listItem={listItem}
                      baggedItem={baggedItem}
                      preferencesOverride={preferencesOverride}
                      isPublic={false}
                      mainActionFn={mainActionFn}
                    />
                  );
                }) : null}
              {provided.placeholder}
            </Box>
          );
        }}
      </Droppable>
      <Space h={'sm'} />
    </div>
  );
}

export default DroppableBox;
