import React from "react";
import { useLocation } from "react-router-dom";
import SignupForm from "../../components/SignupForm/SignupForm";
import { extractParamsFromLocation } from "../../utils/mappings";

const SignupPage = () => {
  const location = useLocation();
  const params = extractParamsFromLocation(location);

  return <SignupForm recipeId={params["r"]} plannerId={params["p"]} />;
};

export default SignupPage;
