import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Carousel } from '@mantine/carousel';
import { useFullscreen } from '@mantine/hooks';
import { Affix, Center, Container, Divider, Grid, Progress, ScrollArea, Space, Title, Transition, useMantineTheme } from '@mantine/core';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { IconArrowBackUp, IconCheck, IconChevronLeft, IconChevronRight, IconX } from '@tabler/icons';
import useWindow from '../../hooks/useWindow';

const RecipeWizardStep = ({ preferences, step, theme, onCancel, idx }) => {
  return (
    <Carousel.Slide>
      <Container
        sx={{
          background: preferences.dark ? theme.colors.dark[9] : theme.white,
          width: '100vw',
          height: '100vh',
          paddingTop: '20%',
          paddingLeft: '2rem',
          paddingRight: '2rem',
        }}
      >
        <Affix
          zIndex={10001}
          position={{
            top: '1%',
            left: preferences.rightHanded ? null : 10,
            right: preferences.rightHanded ? 10 : null,
          }}
        >
          <Transition transition="fade" mounted={true}>
            {(transitionStyles) => (
              <IconX onClick={onCancel} />
            )}
          </Transition>
        </Affix>
        <Title>Step {idx + 1}</Title>
        <Space h={'xl'} />
        <Divider size={'md'} color={theme.primaryColor} />
        <ScrollArea sx={{ height: '100%' }}>
          <Grid p={'md'} columns={24} sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <Grid.Col span={24}>
              <Center>
                <Title>
                  {step?.title}
                </Title>
              </Center>
            </Grid.Col>
            <Space h={'xl'} />
            <Grid.Col span={24}>
              <Center>
                {/* <Button color={theme.primaryColor} onClick={onCancel}>Cancel</Button> */}
              </Center>
            </Grid.Col>
          </Grid>
          <Space h={'xl'} />
          <Space h={'xl'} />
        </ScrollArea>
      </Container>
    </Carousel.Slide>
  )
};

export const RecipeWizard = ({ onCancel, show, steps }) => {
  const { fullscreen } = useFullscreen();
  const { width, height } = useWindow();
  const { preferences } = useContext(PreferencesContext);
  const theme = useMantineTheme();

  const [scrollProgress, setScrollProgress] = useState(0);
  const [embla, setEmbla] = useState(null);

  const handleScroll = useCallback(() => {
    if (!embla) return;
    const progress = Math.max(0, Math.min(1, embla.scrollProgress()));
    setScrollProgress(progress * 100);
  }, [embla, setScrollProgress]);

  useEffect(() => {
    if (embla) {
      embla.on('scroll', handleScroll);
      handleScroll();
    }
  }, [embla]);

  return fullscreen || width > 800 ? (
    <>
      <Affix
        zIndex={10001}
        position={{
          top: '1%',
          left: preferences.rightHanded ? null : 10,
          right: preferences.rightHanded ? 10 : null,
        }}
      >
        <Transition transition="fade" mounted={true}>
          {(transitionStyles) => (
            <IconX onClick={onCancel} />
          )}
        </Transition>
      </Affix>
      <Affix
        zIndex={10001}
        sx={{ width: '100vw' }}
        position={{
          bottom: '1%',
        }}
      >
        <Transition transition="fade" mounted={true}>
          {(transitionStyles) => (
            <Progress
              value={scrollProgress}
              styles={{ bar: { transitionDuration: '0ms' }, root: { maxWidth: 320 } }}
              size="sm"
              mt="xl"
              mx="auto"
            />
          )}
        </Transition>
      </Affix>
      <Carousel
        sx={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: '0',
          left: '0',
          zIndex: '10000'
        }}
        getEmblaApi={setEmbla}
        mx="auto"
        nextControlIcon={embla?.canScrollNext() ? <IconChevronRight /> : <IconCheck onClick={onCancel} />}
        previousControlIcon={embla?.canScrollPrev() ? <IconChevronLeft /> : <IconArrowBackUp onClick={onCancel} />}
      >
        {steps?.map((step, idx) => (
          <RecipeWizardStep
            key={idx}
            idx={idx}
            onCancel={onCancel}
            preferences={preferences}
            step={step}
            theme={theme}
          />
        ))}
      </Carousel>
    </>
  ) : null;
};
