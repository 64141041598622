import * as actions from '../redux/actions';
import { db } from '../config/firebase/firebase';
import {
	addDoc,
	collection,
	getDocs,
	query,
} from "firebase/firestore";
import { updateDocument } from './generic';

export const createPreferences = async (uid, preferences) => {
	try {
		delete preferences.id;
		const prefRef = await addDoc(collection(db, `users/${uid}/preferences`), {
			...preferences,
			createdAt: Date.now()
		});
		preferences.id = prefRef.id;
		return preferences;
	} catch (e) {
		console.error('Error adding preferences: ', e);
	}
};

export const updatePreferences = async (uid, prefId, preferences) => {
	const preferencesUpdated = Object.assign(
		{},
		{
			id: prefId,
			...preferences,
			modifiedAt: Date.now()
		}
	);
	await updateDocument(preferencesUpdated, 'preferences', uid);
	return preferencesUpdated;
};

export const getPreferences = async uid => {
	let pref = {};
	try {
		const q = query(collection(db, `users/${uid}/preferences`));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(preference => {
			pref = { id: preference.id, ...preference.data() };
		});
	} catch (e) {
		console.error('Error getting user preferences: ', e);
		throw e;
	}
	return pref;
};

export function updatePreferencesThunk(preferences) {
	return async function inner(dispatch, getState) {
		try {
			const state = getState();
			if (!state.user) return;
			const prefs = await getPreferences(state.user.uid);
			let updatedPreferences = null;
			if (!prefs.id) {
				updatedPreferences = await createPreferences(
					state.user.uid,
					preferences
				);
			} else {
				updatedPreferences = await updatePreferences(
					state.user.uid,
					prefs.id,
					preferences
				);
			}
			dispatch({
				type: actions.LOAD_PREFERENCES,
				payload: {
					preferences: Object.assign(prefs, updatedPreferences)
				}
			});
		} catch (e) {
			console.error(e);
		}
	};
}

export function getPreferencesThunk() {
	return async function inner(dispatch, getState) {
		try {
			const state = getState();
			if (!state?.user) return;
			const preferences = await getPreferences(state.user.uid);
			dispatch({
				type: actions.LOAD_PREFERENCES,
				payload: {
					preferences
				}
			});
		} catch (e) {
			console.error(e);
		}
	};
}

export const createSettings = async (uid, settings) => {
	const settingsRef = db.collection('users').doc(uid).collection('settings');
	try {
		const settingsDocRef = await settingsRef.add({
			...settings,
			createdAt: Date.now()
		});
		settings.id = settingsDocRef.id;
		return settings;
	} catch (e) {
		console.error('Error adding settings: ', e);
	}
};

export const updateSettings = async (uid, settings) => {
	const settingsUpdated = Object.assign(
		{},
		{
			...settings,
			modifiedAt: Date.now()
		}
	);
	try {
		await updateDocument(settingsUpdated, 'settings', uid);
	} catch (e) {
		console.error(`error updating settings: ${e}`);
	}
	return settingsUpdated;
};

export const getSettings = async uid => {
	let settings = {};
	try {
		const q = query(collection(db, `users/${uid}/settings`))
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(setting => {
			settings = { id: setting.id, ...setting.data() };
		});
	} catch (e) {
		console.error('Error getting user settings: ', e);
		throw e;
	}
	return settings;
};

