import { useScrollLock } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPreferencesThunk } from '../../model/users';
import { getVersionsThunk } from '../../model/versions';
import { cleanNotifications } from '@mantine/notifications';

export const PreferencesContext = React.createContext();

export const baseColors = {
	themeId: process.env.REACT_APP_DEFAULT_THEME_ID,
	primary: '#2D588A',
	primaryLight: '#5B95D6',
	secondary: '#DDA055',
	secondaryLight: '#71ADDD',
	accent: '#D67A72'
};

export const basePrefs = {
	...baseColors,
	backdrop: 'white',
	color: 'white',
	colorInverted: process.env.REACT_APP_BACKDROP_GREY,
	dark: false,
	rightHanded: true,
};

export const Preferences = props => {
	const prefs = useSelector(state => state.preferences);
	const auth = useSelector(state => state.auth);
	const version = useSelector(state => state.version);
	const [currFeature, setCurrFeature] = useState('');
	const [showHelpState, setShowHelpState] = useState(false);
	const [scrollLocked, setScrollLocked] = useScrollLock();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getVersionsThunk());
		dispatch(getPreferencesThunk());
	}, [dispatch]);

	useEffect(() => { }, [prefs]);
	useEffect(() => { }, [auth]);
	useEffect(() => { }, [version]);

	const closeNotification = () => {
		cleanNotifications();
		setCurrFeature('');
	};

	const setShowHelp = showHelp => {
		setScrollLocked(showHelp);
		setShowHelpState(showHelp);
	};

	const defaultPrefs = Object.assign(basePrefs, { ...prefs });

	// https://stackoverflow.com/questions/15852122/hex-transparency-in-colors#answers-header
	const customPrefs = Object.assign(defaultPrefs, {
		...defaultPrefs,
		color: 'white',
		colorInverted: defaultPrefs.dark
			? 'white'
			: process.env.REACT_APP_BACKDROP_GREY,
		backdrop: defaultPrefs.dark ? process.env.REACT_APP_BACKDROP_GREY : 'white',
		backgroundHighlight: defaultPrefs.dark
			? process.env.REACT_APP_BACKDROP_DARK_GREY
			: '#F0F0F0'
	});

	return (
		<PreferencesContext.Provider
			value={{
				preferences: customPrefs,
				auth,
				version,
				help: {
					showHelp: showHelpState,
					closeNotification,
					setShowHelp,
					currFeature,
					setCurrFeature
				},
				setScrollLocked
			}}
		>
			{props.children}
		</PreferencesContext.Provider>
	);
};
