import React, { useContext, useRef, useState } from 'react';
import { createStyles, ScrollArea, SegmentedControl, useMantineTheme } from '@mantine/core';
import { PreferencesContext } from '../../config/context/preferencesContext';
import useWindow from '../../hooks/useWindow';

const useStyles = createStyles((theme, { color = 'default', dark }) => {
	return ({
		scrollArea: {
			height: '55vh',
			zIndex: 50,

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				height: '55vh',
			},
		},
	});
});

export const RecipeScroll = ({
	recipeIndex = {
		a: 0,
		b: 0,
		c: 0,
		d: 0,
		e: 0,
		f: 0,
		g: 0,
		h: 0,
		i: 0,
		j: 0,
		k: 0,
		l: 0,
		m: 0,
		n: 0,
		o: 0,
		p: 0,
		q: 0,
		r: 0,
		s: 0,
		t: 0,
		u: 0,
		v: 0,
		w: 0,
		x: 0,
		y: 0,
		z: 0
	},
	recipeLetter = '',
	changeRecipeLetter,
	showHelp
}) => {
	const theme = useMantineTheme();
	const dragScrollElement = useRef(null);
	const { preferences } = useContext(PreferencesContext);
	const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
	const [value, setValue] = useState(recipeLetter);
	const { width, height } = useWindow();


	const sortedLetters = Object.keys(recipeIndex).sort();

	const noRecipesColor = preferences.dark
		? process.env.REACT_APP_DARK_GREY
		: process.env.REACT_APP_LIGHT_GREY;

	const recipeLetterData = sortedLetters.map(ridx => {
		const selected = ridx === recipeLetter;
		const hasRecipes = recipeIndex[ridx] > 0;
		return { label: ridx.toUpperCase(), value: ridx.toUpperCase(), disabled: !hasRecipes }
	});
	const handleRecipeLetter = letter => {
		setValue(letter);
		changeRecipeLetter(letter);
	}
	return (
		<ScrollArea type={'never'} className={classes.scrollArea}>
			<div>
				<SegmentedControl
					color={theme.primaryColor}
					orientation={'vertical'}
					value={value}
					onChange={handleRecipeLetter}
					data={recipeLetterData}
					fullWidth
				// fullWidth={width <= 800}
				// sx={{ width: '3rem' }}
				/>
			</div>
		</ScrollArea>
	);
};
