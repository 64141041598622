import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// V8 SETUP
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';

import store, { persistedStore } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import './styles/globals.scss';

// const firestore = firebase.firestore();
// const baseUrl = document.location.origin === process.env.REACT_APP_PROD_ORIGIN
// 	? 'https://nomicon.app'
// 	: 'http://localhost:3000';

const container = document.getElementById('root');
const root = createRoot(container);
// root.render(<App tab="home" />);

root.render(
	// ReactDOM.render(
	// <React.StrictMode>
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistedStore}>
			{/* <FirebaseContext.Provider value={new Firebase(firestore)}> */}
			<App />
			{/* </FirebaseContext.Provider> */}
		</PersistGate>
	</Provider>
	// </React.StrictMode>,
	// document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
