export function byObjProperty (property, asc = true) {
  return ( a, b ) => {
    if (asc) {
      if ( a[property] < b[property] ){
        return -1;
      }
      if ( a[property] > b[property] ){
        return 1;
      }
    } else {
      if ( a[property] > b[property] ){
        return -1;
      }
      if ( a[property] < b[property] ){
        return 1;
      }
    }
    return 0;
  };
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};