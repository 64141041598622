import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { CLEAR_PREFERENCES } from '../../redux/actions';
import { useHeaderMenu } from '../../components/Layout/HeaderMenu';
import { ActionIcon, Box, Button, Center, createStyles, Grid, Text, Title, useMantineTheme } from '@mantine/core';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { extractParamsFromLocation } from '../../utils/mappings';

const useStyles = createStyles((theme, { color = 'default', dark }) => {
	return ({
		wrapper: {
			position: 'relative',
			paddingBottom: 80,

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				paddingBottom: 60,
			},
		},
		backdrop: {
			// background: theme.colors[theme.primaryColor][8],
			height: '100vh',
			width: '100vw',
			position: 'fixed',
		},
		title: {
			...theme.other.preventSelection,
			textAlign: 'center',
			fontWeight: 800,
			fontSize: 40,
			letterSpacing: -1,
			color: theme.white,
			marginBottom: theme.spacing.xs,
			fontFamily: `Greycliff CF, ${theme.fontFamily}`,

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				fontSize: 28,
				textAlign: 'center',
			},
		},
	});
});

// Prepend `0` for one digit numbers. For that the number has to be
// converted to string, as numbers don't have length method
const padTime = time => {
	return String(time).length === 1 ? `0${time}` : `${time}`;
};

const format = time => {
	// Convert seconds into minutes and take the whole part
	const minutes = Math.floor(time / 60);

	// Get the seconds left after converting minutes
	const seconds = time % 60;

	//Return combined values as string in format mm:ss
	return `${minutes}:${padTime(seconds)}`;
};

export default function LogoutPage() {
	const [counter, setCounter] = React.useState(5);
	const { preferences } = useContext(PreferencesContext);
	const theme = useMantineTheme();
	const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark,});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const params = extractParamsFromLocation(location);
	const HeaderMenu = useHeaderMenu({ help: true, disabled: false, transparent: true });
	useEffect(() => {
		dispatch({
			type: CLEAR_PREFERENCES,
			payload: {}
		});
	}, [dispatch]);

	useEffect(() => {
		let timer;
		if (counter > 0) {
			timer = setTimeout(() => setCounter(c => c - 1), 1000);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [counter]);

	if (counter === 0) navigate('/', { replace: true });

	return (
		<div>
			<HeaderMenu hideHelp={true} />
			<Center>
				<Grid columns={24}>
					<Grid.Col>
						<Box sx={{ width: '50%', margin: 'auto', marginTop: '3rem' }}>
							<h1 style={{ textAlign: 'center' }} className={[classes.title, classes.section].join(' ')}>
								{params.e === '1' ? "Your session has expired. Please log back in." : "You have logged out."}
							</h1>
						</Box>
					</Grid.Col>
					<Grid.Col>
						<Center mt={'xl'}>
							<Button
								variant={'primary'}
								sx={{
									color: document.body.style.backgroundColor,
									backgroundColor: "white",
									'&:hover': {
										backgroundColor: "#343434",
										color: 'white',
									},
								}}
								size={'lg'}
								onClick={() => navigate('/')}
							>
								{params.e === '1' ? "Login" : "Home"} <Text color={'dimmed'} sx={{ fontSize: '14px', paddingLeft: '0.5rem', width: '1rem' }}>{counter}</Text>
							</Button>
						</Center>
					</Grid.Col>
				</Grid>
			</Center>
		</div>
	);
}
