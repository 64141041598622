import React, { useEffect, useState } from 'react';
import { Button, Loader, useMantineTheme } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useContext } from 'react';
import { PreferencesContext } from '../../config/context/preferencesContext';

const MAX_LABEL_LENGTH = 30;

export const BackButton = ({ defaultFrom, defaultTo = "/", replace = true }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || defaultTo;
  const fromLabel = `to ${location.state?.fromLabel || defaultFrom}`;
  const [clicked, setClicked] = useState(false);
  const { preferences } = useContext(PreferencesContext);
  const theme = useMantineTheme();

  useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        if (from === "/" && fromLabel !== "to Menu") {
          document.body.style.transition = "";
          document.body.style.backgroundColor = process.env.REACT_APP_DEFAULT_COLOR;
        }
        navigate(from, { replace });
      }, 500);
    }
  }, [clicked]);

  return (
    <Button
      onClick={() => setClicked(true)}
      variant={"subtle"}
      sx={{ color: preferences?.dark ? "white" : theme?.primaryColor }}
      leftIcon={clicked ? <Loader size={'sm'} /> : <IconArrowNarrowLeft />}
    >
      Back {fromLabel.substring(0, MAX_LABEL_LENGTH)}{fromLabel.length > MAX_LABEL_LENGTH ? '...' : ''}
    </Button>
  );
};
