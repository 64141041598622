import * as actions from "../redux/actions";
import { updateDocument } from "./generic";


export function updateProfileThunk(profile) {
  return async function inner(dispatch, getState) {
    const state = getState();
    if (!state.user || !profile?.id) return;
    const updatedProfile = await updateDocument(profile, "profiles", state.user.uid);
    await updateDocument(profile, "publicProfiles");
    dispatch({
      type: actions.PROFILE_UPDATED,
      payload: {
        profile: updatedProfile
      }
    });
  };
};
