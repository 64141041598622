import React from "react";
import { useLocation } from "react-router-dom";
import SigninForm from "../../components/SigninForm/SigninForm";
import { extractParamsFromLocation } from "../../utils/mappings";

const SigninPage = () => {
  const location = useLocation();
  const params = extractParamsFromLocation(location);
  return <SigninForm subscribed={params["subscribed"]} recipeId={params["r"]} plannerId={params["p"]} />;
};

export default SigninPage;
