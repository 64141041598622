import { byObjProperty } from './sort';

export const deserializeItems = (allItems, sections) => {
	const allItemsSorted = {};
	sections.sort(byObjProperty('idx'));
	// populate object with an empty array for each section
	sections?.map(section => (allItemsSorted[section.droppableId] = []));
	// push all items into their respective section arrays corresponding with droppableId
	allItems?.forEach(item => {
		allItemsSorted[item.droppableId]?.push(item);
	});
	Object.keys(allItemsSorted).forEach(section => {
		allItemsSorted[section].sort(byObjProperty('idx'));
	});
	return allItemsSorted;
};

export const serializeItems = allItems => {
	const allItemsSerialized = [];
	for (const section in allItems) {
		allItems[section]?.map((item, idx) =>
			allItemsSerialized.push({ ...item, idx })
		);
	}
	return allItemsSerialized;
};

export const extractParamsFromLocation = location => {
	const paramsArr = location.search
		.substring(1, location.search.length)
		.split('&');
	const params = {};
	paramsArr.forEach(p => (params[p.split('=')[0]] = p.split('=')[1]));
	return params;
};
