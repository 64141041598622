import React, { useContext } from 'react';
import { createStyles, Card, Text, Group, Button, Title, useMantineTheme } from '@mantine/core';
import { IconEdit } from '@tabler/icons';
import SignoutButton from '../SignoutButton/SignoutButton';
import { PreferencesContext } from '../../config/context/preferencesContext';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    width: '100%',
  },
}));

export function AccountProfile({
  name,
  email,
  stats,
  onEdit,
  isPublic = false,
}) {
  const { classes } = useStyles();
  const { preferences } = useContext(PreferencesContext);
  const theme = useMantineTheme();

  const items = stats?.map((stat) => (
    <div key={stat.label}>
      <Text align="center" size="lg" weight={500}>
        {stat.value}
      </Text>
      <Text align="center" size="sm" color="dimmed">
        {stat.label}
      </Text>
    </div>
  ));

  return (
    <Card p="xl" radius="sm" className={classes.card}>
      <Title
        onClick={!isPublic && onEdit}
        align="center"
        weight={500}
        mt="sm"
        order={3}
        sx={{ cursor: !isPublic ? 'pointer' : undefined }}
      >
        {name}
      </Title>
      <Text align="center" size="sm" color="dimmed">
        {email}
      </Text>
      <Group mt="md" position="center" spacing={30}>
        {items}
      </Group>
    </Card>
  );
}