import React from "react";
import { useLocation } from "react-router-dom";
import Menu from "../components/Menu/Menu";
import SecurePage from "../config/auth/SecurePage";
import { extractParamsFromLocation } from "../utils/mappings";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { PreferencesContext } from "../config/context/preferencesContext";

const MenuPage = () => {
  const location = useLocation();
  const params = extractParamsFromLocation(location);
  const user = useSelector(state => state.user);
  const { preferences } = useContext(PreferencesContext);

  return (
    <SecurePage>
      <Menu recipeId={params["r"]} plannerId={params["p"]} />
    </SecurePage>
  );
};

export default MenuPage;
