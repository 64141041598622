import { Container, createStyles, Grid, Text, useMantineTheme } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IconGripVertical } from '@tabler/icons';
import { byObjProperty } from '../../utils/sort';
import { useEffect } from 'react';
import { useContext } from 'react';
import { PreferencesContext } from '../../config/context/preferencesContext';

const grid = 8;
const getItemStyle = (
	draggableStyle,
	snapshot,
	isPublic,
	preferences,
	bagged
) => {
	let backgroundColor = snapshot.isDragging
		? preferences.secondary
		: preferences.primary;
	if (bagged) backgroundColor = 'grey';
	const defaultStyle = {
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',
		padding: '4px',
		margin: `0 auto ${grid}px auto`,
		borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
		width: `100%`, // 100% = 350px, 99% = 353px, 95% = 368px
		backgroundColor,
		color: preferences.color,
		border: `1px outset ${backgroundColor}`,
		// boxShadow: `rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset`,
		// styles we need to apply on draggables
		...draggableStyle
	};

	if (!snapshot.isDropAnimating) {
		return defaultStyle;
	}
	// const { moveTo, curve, duration } = snapshot.dropAnimation;
	// move to the right spot
	// const translate = `translate(${moveTo.x + 25}px, ${moveTo.y}px)`;
	// const translate2 = `translate(${moveTo.y}px, ${moveTo.x + 25}px)`;
	// add a bit of turn for fun
	// const rotate = 'rotate(0.5turn)';

	return {
		...defaultStyle
		// ...draggableStyle,
		// transform: `${translate}, ${translate2}`,
		// slowing down the drop because we can
		// transition: `${translate} ${curve} ${duration + 0.2}s. `,
	};
};

const useStyles = createStyles((theme, { preferenceColor }) => ({
  item: {
    // display: 'flex',
    alignItems: 'center',
    borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
    // border: `1rem solid ${
    //   theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    // }`,
    // padding: `${theme.spacing.sm} ${theme.spacing.xl}`,
    // paddingLeft: `calc(${theme.spacing.xl} - ${theme.spacing.md})`, // to offset drag handle
    // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    color: 'white',
    backgroundColor: theme.colors[theme.primaryColor][5],
    marginBottom: theme.spacing.lg,
    fontWeight: 'bold'
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  dragHandle: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: 'white',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },

  inner: {
		alignItems: 'center',
	},
}));

export function ShoppingListSections ({ data, save }) {
  const { preferences } = useContext(PreferencesContext);
  const theme = useMantineTheme();
  const { classes, cx } = useStyles({ preferenceColor: theme.primaryColor });
  const [state, handlers] = useListState([...data].sort(byObjProperty("idx")));
  useEffect(() => {
    if (data.length > 0 && JSON.stringify(data) !== JSON.stringify(state)) {
      save(state);
    }
  }, [state]); // eslint-disable-line
  const items = state.map((item, index) => (
    <Draggable key={item.droppableId} index={index} draggableId={item.droppableId}>
      {(provided, snapshot) => {
        const itemStyle = getItemStyle(
					provided.draggableProps.style,
					snapshot,
					false,
					{
						color: 'white',
						primary: theme.colors[theme.primaryColor][theme.other.rowColorHueIndex],
						secondary: theme.colors[theme.primaryColor][6],
					},
					false
				);
        return (
          <Container
            className={cx(classes.item, { [classes.itemDragging]: snapshot.isDragging })}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={itemStyle}
          >
            <Grid
              className={classes.inner}
              columns={12}
            >
              <Grid.Col span={10} order={preferences.rightHanded ? 1 : 2}>
                <Text sx={{ textAlign: 'center' }} size={'lg'}>{item.droppableId}</Text>
              </Grid.Col>
              <Grid.Col pt={'md'} span={2} className={classes.dragHandle} order={preferences.rightHanded ? 2 : 1}>
                <div style={{ float: preferences.rightHanded ? 'right' : 'left'}}>
                  <IconGripVertical size={32} />
                </div>
              </Grid.Col>
            </Grid>
          </Container>
        )
    }}
    </Draggable>
  ));

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        handlers.reorder({ from: source.index, to: destination?.index || 0 });
        handlers.apply((item, index) => ({ ...item, idx: index }));
      }}
    >
      <Droppable droppableId="dnd-list" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            {items}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}