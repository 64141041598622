import React, { useEffect, useState } from 'react';
import { Box, Center, Loader, useMantineTheme } from '@mantine/core';

export const LagLoader = ({ children, preferences, ms }) => {
	const theme = useMantineTheme();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const lagTimeout = setTimeout(() => {
			setLoading(false);
		}, ms);

		return () => {
			setLoading(true);
			clearTimeout(lagTimeout);
		};
	}, [ms]);

	const loader = (
		<Box>
			<Center>
				<Loader color={theme.primaryColor} size={'lg'} />
			</Center>
		</Box>
	);

	return loading ? loader : children;
};
