export const DATABASE_WORKING = "DATABASE_WORKING";

export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const LOAD_NOTIFICATION = "LOAD_NOTIFICATION";

export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";
export const SIGNOUT_USER = "SIGNOUT_USER";

export const CLEAR_RECIPE = "CLEAR_RECIPE";
export const CLEAR_RECIPES = "CLEAR_RECIPES";
export const LOAD_RECIPE = "LOAD_RECIPE";
export const LOAD_RECIPES = "LOAD_RECIPES";
export const LOAD_RECIPES_INFINITE = "LOAD_RECIPES_INFINITE";
export const LOAD_RECIPES_META = "LOAD_RECIPES_META";
export const LOAD_SELECTED_RECIPES = "LOAD_SELECTED_RECIPES";

export const CLEAR_INGREDIENTS = "CLEAR_INGREDIENTS";
export const LOAD_INGREDIENTS = "LOAD_INGREDIENTS";

export const CLEAR_PLANNER = "CLEAR_PLANNER";
export const CLEAR_PLANNER_EVENT = "CLEAR_PLANNER_EVENT";
export const CLEAR_PLANNER_EVENTS = "CLEAR_PLANNER_EVENTS";
export const CLEAR_PLANNER_EVENT_RECIPE = "CLEAR_PLANNER_EVENT_RECIPE";
export const CLEAR_PLANNER_RESPONSE = "CLEAR_PLANNER_RESPONSE";
export const CLEAR_PLANNER_SHARE_ACKNOWLEDGED = "CLEAR_PLANNER_SHARE_ACKNOWLEDGED";
export const CLEAR_PLANNER_SHARE_LINK = "CLEAR_PLANNER_SHARE_LINK";
export const CLEAR_PLANNERS = "CLEAR_PLANNERS";
export const LOAD_PLANNER = "LOAD_PLANNER";
export const LOAD_PLANNER_ACCESS_UPDATED = "LOAD_PLANNER_ACCESS_UPDATED";
export const LOAD_PLANNER_EVENT = "LOAD_PLANNER_EVENT";
export const LOAD_PLANNER_EVENTS = "LOAD_PLANNER_EVENTS";
export const LOAD_PLANNER_EVENT_RECIPE = "LOAD_PLANNER_EVENT_RECIPE";
export const LOAD_PLANNER_RESPONSE = "LOAD_PLANNER_RESPONSE";
export const LOAD_PLANNER_SHARE_ACKNOWLEDGED = "LOAD_PLANNER_SHARE_ACKNOWLEDGED";
export const LOAD_PLANNER_SHARE_LINK = "LOAD_PLANNER_SHARE_LINK";
export const LOAD_PLANNERS = "LOAD_PLANNERS";

export const CLEAR_SHOPPING_LIST = "CLEAR_SHOPPING_LIST";
export const LOAD_SHOPPING_LIST_LATEST = "LOAD_SHOPPING_LIST_LATEST";
export const LOAD_SHOPPING_LIST = "LOAD_SHOPPING_LIST";
export const LOAD_SHOPPING_LISTS = "LOAD_SHOPPING_LISTS";

export const LOAD_PREFERENCES = "LOAD_PREFERENCES";
export const CLEAR_PREFERENCES = "CLEAR_PREFERENCES";

export const LOAD_THEMES = "LOAD_THEMES";
export const LOAD_USER = "LOAD_USER";
export const LOAD_VERSIONS = "LOAD_VERSIONS";
export const LOAD_AUTHORISATION = "LOAD_AUTHORISATION";

export const CLEAR_PUBLIC_SHOPPING_LIST = "CLEAR_PUBLIC_SHOPPING_LIST";
export const LOAD_PUBLIC_SHOPPING_LIST = "LOAD_PUBLIC_SHOPPING_LIST";
export const LOAD_PUBLIC_SHOPPING_LIST_AS_OWNER = "LOAD_PUBLIC_SHOPPING_LIST_AS_OWNER";
export const PUBLIC_SHOPPING_LIST_CREATED = "PUBLIC_SHOPPING_LIST_CREATED";

export const PUBLIC_RECIPE_CREATED = "PUBLIC_RECIPE_CREATED";
export const LOAD_PUBLIC_RECIPE = "LOAD_PUBLIC_RECIPE";
export const LOAD_SEARCH_PUBLIC_RECIPES = "LOAD_SEARCH_PUBLIC_RECIPES";

export const PROFILES_CREATED = "PROFILES_CREATED";
export const PROFILE_UPDATED = "PROFILE_UPDATED";
export const LOAD_PUBLIC_PROFILE = "LOAD_PUBLIC_PROFILE";
export const LOAD_RECIPE_PUBLIC_PROFILE = "LOAD_RECIPE_PUBLIC_PROFILE";
export const CLEAR_SEARCH_PUBLIC_PROFILE = "CLEAR_SEARCH_PUBLIC_PROFILE";
export const LOAD_SEARCH_PUBLIC_PROFILE = "LOAD_SEARCH_PUBLIC_PROFILE";

export const CLEAR_NEXT = "CLEAR_NEXT";

export const SET_ERROR = "SET_ERROR";
export const WINDOW_DIMENSIONS_CHANGED = "WINDOW_DIMENSIONS_CHANGED";
