import React, { useContext, useEffect } from "react";

import SecurePage from "../../config/auth/SecurePage";
import GenericList from "../../components/GenericList/GenericList";
import { PreferencesContext } from "../../config/context/preferencesContext";
import { useDispatch, useSelector } from "react-redux";
import useWindow from "../../hooks/useWindow";
import { getRecipeRowsThunk } from "../../model/recipes";
import { Grid } from "@mantine/core";
import { HelpWrapper } from '../../components/Utils/HelpWrapper';
import { RecipeScroll } from "../../components/Recipes/RecipeScroll";
import { SearchBar } from "../Inputs/SearchBar";
import { getDocumentsByArrayThunk } from "../../model/generic";
import { LOAD_RECIPES } from "../../redux/actions";

const RecipesPageInner = ({ objectType = 'recipe', highlightedListData = [] }) => {
  const { preferences } = useContext(PreferencesContext);
  const recipes = useSelector(state => state.recipes);
  const recipeLetter = useSelector(state => state.recipeLetter);
  const recipesMeta = useSelector(state => state.recipesMeta);
  const nextRecipePage = useSelector(state => state.nextRecipePage);
  const shoppingListRecipes = useSelector(state => state.shoppingListRecipes);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const { width, height } = useWindow();
  const pageSize = height > 840
		? process.env.REACT_APP_PAGE_SIZE_DESKTOP
		: process.env.REACT_APP_PAGE_SIZE_MOBILE;
  const contentHeight = height > 840
  ? 600
  : 400;
  const nearestLetter = () => {
    const recipeCountObj = recipesMeta?.recipeCounts;
    const recipeCountObjKeys = Object.keys(recipeCountObj);
    recipeCountObjKeys.sort();
    let closestLetterFound = undefined;
    recipeCountObjKeys.forEach(key => {
      if (!recipeLetter && recipeCountObj[key] > 0) {
        closestLetterFound = key;
      }
    });
    getRecipe(closestLetterFound);
  };
  const getRecipe = letter => dispatch(getRecipeRowsThunk({
		recipeLetter: letter || 'A',
		pageSize,
	}));

  useEffect(() => {
    if (recipeLetter) {
      dispatch(getRecipeRowsThunk({
        recipeLetter: recipeLetter,
        pageSize,
      }));
    } else {
      nearestLetter();
    }
  }, []);

  const onSearch = tagArray => {
    dispatch(getDocumentsByArrayThunk(
      "recipes",
      { field: "tags", array: tagArray },
      "recipes",
      LOAD_RECIPES,
      user?.uid,
    ));
  };

  const nextPage = () => {
    dispatch(getRecipeRowsThunk({
      next: nextRecipePage,
      recipeLetter: recipeLetter,
      pageSize,
    }))
  };
  const totalRecipeCount = recipesMeta?.recipeCounts[recipeLetter] || 0;
  const hasMore = recipes?.length < totalRecipeCount;

  const recipeIndexScroll = (
		<Grid.Col span={2}>
			<HelpWrapper
				feature={process.env.REACT_APP_HELP_RECIPES_INDEX_SCROLL}
				position={'top-center'}
			>
				<RecipeScroll
					changeRecipeLetter={getRecipe}
					recipeLetter={recipeLetter}
					recipeIndex={recipesMeta?.recipeCounts}
				/>
			</HelpWrapper>
		</Grid.Col>
	);

  return (
    <GenericList
      upperComponent={<SearchBar search={onSearch} />}
      listOnly
      pickable
      cols={10}
      objectType={objectType}
      sidebar={recipeIndexScroll}
      hasMore={hasMore}
      contentHeight={contentHeight}
      objectDataFn={{
        nextPage,
        pageSize,
        totalRecords: recipesMeta?.recipeCounts[recipeLetter] || 0,
        highlightedListData: highlightedListData ? highlightedListData : shoppingListRecipes,
      }}
    />
  );
};

export default RecipesPageInner;
