import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPublicShoppingListThunk } from '../../model/shoppingListsPublic';
import { useHeaderMenu } from '../../components/Layout/HeaderMenu';
import { Button, Center, createStyles, Space, Text } from '@mantine/core';
import { cleanNotifications, showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons';
import { FloatingLabelInput } from '../../components/Inputs/FloatingLabelInput';
import { BREAKPOINT } from '../hero';
import { extractParamsFromLocation } from '../../utils/mappings';
import ShoppingList from '../../components/ShoppingLists/ShoppingList';
import { useColorBackground } from '../../hooks/useColorBackground';
import { AcceptButton } from '../../components/Inputs/AcceptButton';
import { RejectButton } from '../../components/Inputs/RejectButton';

const useStyles = createStyles((theme) => {
	return ({
		textline: {
			widht: '40%',
			color: 'white',
			margin: 'auto',

			[BREAKPOINT]: {
				width: '90%'
			},
		},
		title: {
			fontFamily: `Greycliff CF, ${theme.fontFamily}`,
			fontSize: 62,
			fontWeight: 900,
			lineHeight: 1.1,
			margin: 0,
			padding: '2rem',
			color: theme.white,

			[BREAKPOINT]: {
				fontSize: 42,
				lineHeight: 1.2,
			},
		},
		backdrop: {
			// background: theme.colors[theme.primaryColor][8],
			height: '100vh',
			width: '100vw',
			position: 'fixed',
		},
	});
});

const ShoppingListPage = () => {
	const location = useLocation();
	const [authorised, setAuthorised] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { p: defaultPin } = extractParamsFromLocation(location);
	const [pin, setPin] = useState(defaultPin);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const publicShoppingList = useSelector(state => state.publicShoppingList);
	const HeaderMenu = useHeaderMenu({ help: true, disabled: true, transparent: true });
	let { id } = useParams();
	const { classes } = useStyles();
	useColorBackground(true);

	const publicShoppingListId = id;

	const onSubmit = () => {
		if (!loading && pin !== '') {
			setLoading(true);
			dispatch(getPublicShoppingListThunk(publicShoppingListId, pin));
		}
	};

	useEffect(() => {
		if (publicShoppingList?.error) {
			setError(true);
			setLoading(false);
			return;
		} else if (publicShoppingList?.id !== id) {
			setAuthorised(false);
			setLoading(false);
		} else if (publicShoppingList?.id) {
			setAuthorised(true);
			setLoading(false);
		}
	}, [id, publicShoppingList]);

	const resetError = () => {
		cleanNotifications();
		setError(false);
	};
	useEffect(() => {
		if (error) {
			showNotification({
				id: 'pin-verification',
				disallowClose: true,
				autoClose: 5000,
				title: "Oops!",
				message: "We weren't able to find a shopping list for the details you've provided. 🤔 Are you sure the PIN was correct?",
				color: 'red',
				icon: <IconX />,
				loading: false,
				onClose: resetError,
			});
		}
	}, [error]);

	const onChange = evt => {
		setPin(evt.target.value);
	};

	const isInvalid = pin === '' || pin?.length < 4;
	const publicShoppingListPage = <ShoppingList id={publicShoppingListId} isPublic={true} />;
	const splashPage = (
		<div>
			<HeaderMenu hideHelp={true} disabled />
			<h1 style={{ textAlign: 'center' }} className={[classes.title, classes.section].join(' ')}>
				Hello there.
			</h1>
			<Text
				align={'center'}
				p={'sm'}
				px={'4rem'}
				className={classes.textline}
			>
				If you are reading this, you've likely drawn the short straw in a game of "who's going shopping?"
			</Text>
			<Text
				align={'center'}
				p={'sm'}
				px={'4rem'}
				className={classes.textline}
				sx={{
					fontSize: '12px',
				}}
			>
				(Commisserations)
			</Text>
			<Text
				align={'center'}
				p={'sm'}
				px={'4rem'}
				className={classes.textline}
			>
				Enter your buddy's pin below to see their shopping list.
			</Text>
			<Center>
				<FloatingLabelInput
					required
					id='pin'
					name='PIN'
					autoComplete='off'
					type='text'
					onChange={onChange}
					onEnter={onSubmit}
					placeholder={`Enter Super Secret PIN 🤫`}
					value={pin}
					mt={"lg"}
					label={<span style={{ color: 'white'}}>PIN</span>}
					sx={{ minWidth: '10%' }}
				/>
			</Center>
			<Center mt={'xl'}>
				{/* <Button
					onClick={() => navigate(`/`)}
					variant={'subtle'}
					sx={{
						color: 'white',
						'&:hover': {
							backgroundColor: "#343434",
							color: 'white',
						},
					}}
				>
					Back
				</Button> */}
				<RejectButton onSubmit={() => navigate(`/`)}>Back</RejectButton>
				<Space w={'lg'} />
				<AcceptButton disabled={isInvalid} onSubmit={onSubmit}>Unlock</AcceptButton>
			</Center>
		</div>
	);

	return authorised ? publicShoppingListPage : splashPage;
};

export default ShoppingListPage;
