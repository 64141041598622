// V9 SETUP
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import {
	confirmPasswordReset,
	createUserWithEmailAndPassword,
	getAuth,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut,
	updatePassword,
} from 'firebase/auth';
import {
	firebaseConfigDevel,
	firebaseConfigProd
} from '../../.firebase-config';

const isProd = document.location.origin === process.env.REACT_APP_PROD_ORIGIN;
const app = initializeApp(
	isProd
		? firebaseConfigProd
		: firebaseConfigDevel
);

export const db = getFirestore(app);
export const auth = getAuth();

const baseUrl = isProd
	? 'https://nomicon.app'
	: 'http://localhost:3000';

export const doCreateUserWithEmailAndPassword = (email, password) => {
	return createUserWithEmailAndPassword(auth, email, password);
};
export const doSignInWithEmailAndPassword = (email, password) => {
	return signInWithEmailAndPassword(auth, email, password);
};
/**
 * email	string	The user's email address.
 * actionCodeSettings	ActionCodeSettings	The ActionCodeSettings.
 */
export const doSendPasswordResetEmail = email => {
	const actionCodeSettings = {
		url: `${baseUrl}/verify?email=${email}`,
		handleCodeInApp: true,
	};
	return sendPasswordResetEmail(auth, email, actionCodeSettings);
};
/**
 * oobCode			string	A confirmation code sent to the user.
 * newPassword	string	The new password.
 */
export const doConfirmPasswordReset = (oobCode, newPassword) => {
	return confirmPasswordReset(auth, oobCode, newPassword);
};
export const doSignOut = () => {
	signOut(auth);
};
export const doPasswordReset = email => auth.sendPasswordResetEmail(email);
export const doPasswordUpdate = password => {
	const user = auth.currentUser;
	updatePassword(user, password);
}
