import React from "react";

import SecurePage from "../config/auth/SecurePage";

import styles from "../styles/Home.module.scss";

const TestPage = () => {

  const box = (
    <div>
      <div className={[styles.box, styles.box1].join(' ')}>
        <div className={[styles.oddboxinner].join(' ')}>bangers?</div>
      </div>
      <div className={[styles.box, styles.box2].join(' ')}>
        <div className={[styles.evenboxinner].join(' ')}>bangers!</div>
      </div>
      <div className={[styles.box, styles.box3].join(' ')}>
        <div className={[styles.oddboxinner].join(' ')}></div>
      </div>
    </div>
  );
  return (
    <SecurePage>
      {box}
    </SecurePage>
  );
};

export default TestPage;

