/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";

import { Box, Button, Center, Container, Grid, Indicator, Space } from "@mantine/core";

import { PreferencesContext } from '../../config/context/preferencesContext';
import { FloatingLabelInput } from "./FloatingLabelInput";
import { useDispatch } from "react-redux";
import { RejectButton } from "./RejectButton";

export const ITEM_CONFIG = {
  "profile": {
    nameLabel: "Profile Name",
    namePlaceholder: "Enter a name for your profile.",
  },
  "shopping": {
    type: "shopping list",
    nameLabel: "Shopping List Name",
    namePlaceholder: "Enter a name for your shopping list.",
  },
  "group": {
    type: "group",
    nameLabel: "Group Name",
    namePlaceholder: "Enter a new group name.",
  },
  "recipe": {
    type: "recipe",
    nameLabel: "Group Name",
    namePlaceholder: "Enter a new group name.",
  },
  "planner": {
    type: "planner",
    nameLabel: "Planner Name",
    namePlaceholder: "Enter a name to identify this planner later.",
  },
};

export const NewItemModal = ({
  onSubmit,
  onCancel,
  source,
  nameMaxLength,
  inName = "",
  validationFn = undefined,
}) => {
  const { preferences } = useContext(PreferencesContext);
  const dispatch = useDispatch();
  const [name, setName] = useState(inName);

  const submitItem = () => {
    if (name === "") onCancel();
    onSubmit(name?.trim());
    setName("");
  };

  const onNameChange = evt => {
    const { value } = evt.currentTarget;
    setName(value?.substring(0, nameMaxLength));
  };

  let disabled = name === "";
  if (validationFn) {
    const validName = validationFn(name);
    disabled = !validName;
  }

  return (
    <Container>
      <Grid columns={24} grow>
        <Grid.Col span={24} mb={'lg'}>
          <Center>
            <Indicator
              sx={{ width: '90%' }}
              inline
              label={`${name?.length}/${nameMaxLength}`}
              size={14}
              position="bottom-end"
            >
              <FloatingLabelInput
                mt={"xl"}
                size={'md'}
                wrapperProps={{ 'sx': { width: '100%' } }}
                label={ITEM_CONFIG[source].nameLabel || "Name"}
                placeholder={ITEM_CONFIG[source].namePlaceholder || "Name"}
                value={name}
                onChange={onNameChange}
                onEnter={submitItem}
                autoFocus={true}
              />
            </Indicator>
          </Center>
        </Grid.Col>
        <Grid.Col span={24} mb={"lg"}>
          <Box>
            <Center>
              <RejectButton onSubmit={onCancel}>
                Cancel
              </RejectButton>
              <Space w={'md'} />
              <Button
                disabled={disabled}
                onClick={submitItem}
              >
                Save
              </Button>
            </Center>
          </Box>
        </Grid.Col>
      </Grid>
    </Container>
  );
};
