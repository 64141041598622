import axios from 'axios';
import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	limit,
	orderBy,
	query,
	startAfter,
	where,
} from "firebase/firestore";
import * as actions from '../redux/actions';
import { byObjProperty } from '../utils/sort';
import {
	createDocument,
	getDocument,
	getDocumentsByAttribute,
	getDocumentSingleton,
	handleError,
	updateDocument,
	updateDocumentThunk,
} from './generic';
import { LOAD_PLANNER, LOAD_PLANNERS, LOAD_PLANNER_RESPONSE } from '../redux/actions';
import { db } from '../config/firebase/firebase';
import sendNotification from '../components/Utils/notificationHandler';

const plannersGcfUrl =
	document.location.origin === process.env.REACT_APP_PROD_ORIGIN
		? process.env.REACT_APP_PLANNERS_GCF_PROD
		: process.env.REACT_APP_PLANNERS_GCF_DEV;

const ERROR_MSGS = {
	plannerShareNotLoggedIn404: "You need to be logged in to do that. 😬",
	plannerShareAcknowledge404: "Sorry, that share link has either already been redeem or it has expired. 😬",
	plannerShare404: "We couldn't create your share link. 😬",
	plannerEvent404: "We couldn't find your planner events. 😬",
	plannerEventCreate404: "We couldn't create that planner meal for you. 😬",
	plannerEvents404: "We couldn't find your planner events. 😬",
	plannerAccessUpdate404: "We couldn't update this users role for your planner. 😬",
	planners404: "We couldn't find your planners. 😬",
	planner404: "We couldn't find that planner. 😬",
	plannerNoName: "We can't create a planner without a name."
};

async function createPlanner(uid, publicProfileId, planner) {
	try {
		const plannerResponse = await axios
			.post(
				plannersGcfUrl,
				{ uid, publicProfileId, name: planner.name },
				{ headers: { 'Content-Type': 'application/json' } },
			);
		return plannerResponse.data;
	} catch (err) {
		throw err;
	};
};

export function createPlannerThunk(planner) {
	return async function inner(dispatch, getState) {
		const { user, publicProfile } = getState();
		if (!user || !planner) return;
		sendNotification(dispatch, "create", "loading", "planner");
		const plannerResponse = await createPlanner(user.uid, publicProfile.id, planner);
		if (plannerResponse?.success) {
			sendNotification(dispatch, "create", "success", "planner");
			dispatch(getPlannerRowsThunk());
		} else {
			sendNotification(dispatch, "create", "error", "planner");
		}
	};
}
export const deletePlanner = async (uid, publicProfileId, plannerId) => {
	try {
		const plannerResponse = await axios
			.delete(
				`${plannersGcfUrl}/${plannerId}`,
				{ data: { uid, publicProfileId }},
				{ headers: { 'Content-Type': 'application/json' } },
			);
		return plannerResponse.data;
	} catch (err) {
		throw err;
	}
};
export function deletePlannerThunk(planner) {
	return async function inner(dispatch, getState) {
		if (!user || !planner || !planner.id) return;
		const { user, publicProfile } = getState();
		const plannerResponse = await deletePlanner(user.uid, publicProfile.id, planner.id);
		if (plannerResponse?.success) dispatch(getPlannerRowsThunk());
		dispatch({
			type: actions.CLEAR_PLANNER,
			payload: {
				planner: null,
			}
		});
	};
};

export const deletePlannerAccess = async (uid, publicProfileId, plannerId) => {
	try {
		const plannerResponse = await axios
			.delete(
				`${plannersGcfUrl}/${plannerId}/access`,
				{ data: { uid, publicProfileId }},
				{ headers: { 'Content-Type': 'application/json' } },
			);
		return plannerResponse.data;
	} catch (err) {
		throw err;
	}
};
export function deletePlannerAccessThunk(planner) {
	return async function inner(dispatch, getState) {
		if (!planner || !planner.id) return;
		const { user, publicProfile } = getState();
		const plannerResponse = await deletePlannerAccess(user.uid, publicProfile.id, planner.id);
		if (plannerResponse?.success) dispatch(getPlannerRowsThunk());
		dispatch({
			type: actions.CLEAR_PLANNER,
			payload: {
				planner: null,
			}
		});
	};
};
export const updatePlannerAccess = async (uid, publicProfileId, plannerId, userPublicProfileId, role) => {
	try {
		const plannerAccessResponse = await axios
			.put(
				`${plannersGcfUrl}/${plannerId}/access`,
				{ uid, publicProfileId, userPublicProfileId, role },
				{ headers: { 'Content-Type': 'application/json' } },
			);
		if (!plannerAccessResponse.data.success) {
			throw ERROR_MSGS.plannerEventCreate404
		}
		return plannerAccessResponse.data;
	} catch (err) {
		throw err;
	}
};
export function updatePlannerAccessThunk(userPublicProfileId, role) {
	return async function inner(dispatch, getState) {
		try {
			const { user, publicProfile, planner } = getState();
			if (!user || !publicProfile || !planner || !role || !userPublicProfileId) return;
			const plannerAccessResponse = await updatePlannerAccess(user.uid, publicProfile.id, planner.id, userPublicProfileId, role);
			dispatch({ type: actions.LOAD_PLANNER_ACCESS_UPDATED, payload: { plannerAccessResponse } });
			if (plannerAccessResponse?.success) {
				dispatch(getPlannerThunk(planner.id));
			}
		} catch (err) {
			if (err.message === ERROR_MSGS.plannerAccessUpdate404) {
				handleError(
					{
						msg: err
					},
					dispatch
				);
			}
		}
	};
};

export const getPlanner = async (uid, publicProfileId, plannerId) => {
	try {
		const plannerResponse = await axios
			.get(
				`${plannersGcfUrl}/${plannerId}?u=${uid}&p=${publicProfileId}`,
				{ headers: { 'Content-Type': 'application/json' } },
			);
		return plannerResponse.data;
	} catch (err) {
		throw err;
	}
};

export function getPlannerThunk(plannerId) {
	return async function inner(dispatch, getState) {
		try {
			const { user, publicProfile } = getState();
			if (!user) return;
			const plannerResponse = await getPlanner(user.uid, publicProfile.id, plannerId);
			if (plannerResponse?.success && plannerResponse.planner) {
				dispatch({
					type: actions.LOAD_PLANNER,
					payload: {
						planner: plannerResponse.planner,
					}
				});
			}
		} catch (err) {
			if (err === ERROR_MSGS.planners404) {
				handleError(
					{
						msg: err
					},
					dispatch
				);
			}
		}
	};
};

export const getPlannerRows = async (uid, publicProfileId) => {
	try {
		const plannersResponse = await axios
			.get(
				`${plannersGcfUrl}?u=${uid}&p=${publicProfileId}`,
				{ headers: { 'Content-Type': 'application/json' } },
			);
		return plannersResponse.data;
	} catch (err) {
		throw err;
	}
};

export function getPlannerRowsThunk() {
	return async function inner(dispatch, getState) {
		try {
			const { user, publicProfile } = getState();
			if (!user) return;
			sendNotification(dispatch, "read", "loading", "planner");
			const plannersResponse = await getPlannerRows(user.uid, publicProfile.id);
			if (plannersResponse?.success) {
				sendNotification(dispatch, "read", "success", "planner");
				dispatch({
					type: actions.LOAD_PLANNERS,
					payload: {
						planners: plannersResponse.planners,
					}
				});
			}
		} catch (err) {
			sendNotification(dispatch, "read", "error", "planner");
		}
	};
};

export const createPlannerShareLink = async (uid, publicProfileId, planner, role) => {
	try {
		const plannerShareResponse = await axios
			.post(
				`${plannersGcfUrl}/${planner.id}/share`,
				{ uid, publicProfileId, plannerId: planner.id, role },
				{ headers: { 'Content-Type': 'application/json' } },
			);
		if (!plannerShareResponse.data.success) {
			throw ERROR_MSGS.plannerShare404
		}
		return plannerShareResponse.data;
	} catch (err) {
		throw err;
	}
};
export function createPlannerShareLinkThunk(planner, role) {
	return async function inner(dispatch, getState) {
		try {
			const { user, publicProfile } = getState();
			if (!user) return;
			sendNotification(dispatch, "share", "loading", "planner");
			const plannerShareLinkResponse = await createPlannerShareLink(user.uid, publicProfile.id, planner, role);
			if (plannerShareLinkResponse?.success && plannerShareLinkResponse.plannerShareLink) {
				sendNotification(dispatch, "share", "success", "planner");
				dispatch({
					type: actions.LOAD_PLANNER_SHARE_LINK,
					payload: {
						plannerShareLink: plannerShareLinkResponse.plannerShareLink,
					}
				});
			}
		} catch (err) {
			sendNotification(dispatch, "share", "error", "planner");
		}
	};
};
export const acknowledgePlannerShare = async (uid, publicProfileId, plannerShareId) => {
	try {
		const acknowledgePlannerShareResponse = await axios
			.post(
				`${plannersGcfUrl}/${plannerShareId}/acknowledge`,
				{ uid, publicProfileId },
				{ headers: { 'Content-Type': 'application/json' } },
			);
		return acknowledgePlannerShareResponse.data;
	} catch (err) {
		throw err;
	}
};
export function acknowledgePlannerShareThunk(id) {
	return async function inner(dispatch, getState) {
		try {
			const { user, publicProfile } = getState();
			if (!user || !publicProfile) throw Error(ERROR_MSGS.plannerShareNotLoggedIn404);
			const acknowledgedPlannerShareResponse = await acknowledgePlannerShare(user.uid, publicProfile.id, id);
			if (acknowledgedPlannerShareResponse?.success) {
				dispatch({
					type: actions.LOAD_PLANNER_SHARE_ACKNOWLEDGED,
					payload: {
						plannerShareLinkAcknowledged: true,
					}
				});
			} else {
				dispatch({
					type: actions.LOAD_PLANNER_SHARE_ACKNOWLEDGED,
					payload: {
						plannerShareLinkAcknowledged: false,
					}
				});
			}
		} catch (err) {
			if ([
				ERROR_MSGS.plannerShare404,
				ERROR_MSGS.plannerShareNotLoggedIn404
			].includes(err.message)) {
				handleError({ msg: err }, dispatch);
			}
		}
	};
};
export const getPlannerEvents = async (uid, publicProfileId, plannerId, month, year) => {
	try {
		const plannerEventsResponse = await axios
			.post(
				`${plannersGcfUrl}/${plannerId}/events/${year}/${month}`,
				{ uid, publicProfileId },
				{ headers: { 'Content-Type': 'application/json' } },
			);
		if (!plannerEventsResponse.data.success) {
			throw ERROR_MSGS.plannerEvents404
		}
		return plannerEventsResponse.data;
	} catch (err) {
		throw err;
	}
};
export function getPlannerEventsThunk(plannerId, month, year) {
	return async function inner(dispatch, getState) {
		try {
			const { user, publicProfile } = getState();
			if (!user || !publicProfile || month === undefined || year === undefined) return;
			dispatch({ type: actions.DATABASE_WORKING, payload: { databaseWorking: true, } });
			const plannerEventsResponse = await getPlannerEvents(user.uid, publicProfile.id, plannerId, month, year);
			dispatch({ type: actions.DATABASE_WORKING, payload: { databaseWorking: false, } });
			if (plannerEventsResponse?.success) {
				if (plannerEventsResponse.plannerEvents) {
					dispatch({
						type: actions.LOAD_PLANNER_EVENTS,
						payload: {
							plannerEvents: plannerEventsResponse.plannerEvents,
						}
					});
				} else {
					dispatch({
						type: actions.LOAD_PLANNER_EVENTS,
						payload: {
							plannerEvents: [],
						}
					});
				}
			}
		} catch (err) {
			if (err.message === ERROR_MSGS.plannerEvents404) {
				handleError(
					{
						msg: err
					},
					dispatch
				);
			}
		}
	};
};
export const deletePlannerEvent = async (uid, publicProfileId, plannerId, eventId) => {
	try {
		const plannerEventResponse = await axios
			.delete(
				`${plannersGcfUrl}/${plannerId}/events/${eventId}`,
				{ data: { uid, publicProfileId} },
				{ headers: { 'Content-Type': 'application/json' } },
			);
		if (!plannerEventResponse.data.success) {
			throw ERROR_MSGS.plannerEventCreate404
		}
		return plannerEventResponse.data;
	} catch (err) {
		throw err;
	}
};
export function deletePlannersEventsThunk(event) {
	return async function inner(dispatch, getState) {
		try {
			const { user, publicProfile, planner } = getState();
			if (!user || !publicProfile || !planner?.id || !event.id) return;
			dispatch({ type: actions.DATABASE_WORKING, payload: { databaseWorking: true, } });
			const plannerEventResponse = await deletePlannerEvent(user.uid, publicProfile.id, planner.id, event.id);
			dispatch({ type: actions.DATABASE_WORKING, payload: { databaseWorking: false, } });
			if (plannerEventResponse?.success) {
				dispatch(getPlannerEventsThunk(event.plannerId, event.month, event.year));
			}
		} catch (err) {
			if (err.message === ERROR_MSGS.plannerEventCreate404) {
				handleError(
					{
						msg: err
					},
					dispatch
				);
			}
		}
	};
};
export const putPlannerEvent = async (uid, publicProfileId, event) => {
	try {
		const plannerEventResponse = await axios
			.put(
				`${plannersGcfUrl}/${event.plannerId}/events`,
				{ uid, publicProfileId, event },
				{ headers: { 'Content-Type': 'application/json' } },
			);
		if (!plannerEventResponse.data.success) {
			throw ERROR_MSGS.plannerEventCreate404
		}
		return plannerEventResponse.data;
	} catch (err) {
		throw err;
	}
};
export function updatePlannerEventThunk(event) {
	return async function inner(dispatch, getState) {
		try {
			const { user, publicProfile, planner } = getState();
			event.plannerId = planner.id;
			if (!user || !publicProfile || !event || !event.plannerId || !event.month || !event.year) return;
			dispatch({ type: actions.DATABASE_WORKING, payload: { databaseWorking: true, } });
			const plannerEventResponse = await putPlannerEvent(user.uid, publicProfile.id, event);
			dispatch({ type: actions.DATABASE_WORKING, payload: { databaseWorking: false, } });
			if (plannerEventResponse?.success) {
				dispatch(getPlannerEventsThunk(event.plannerId, event.month, event.year));
			}
		} catch (err) {
			if (err.message === ERROR_MSGS.plannerEventCreate404) {
				handleError(
					{
						msg: err
					},
					dispatch
				);
			}
		}
	};
};
export const createPlannerEvent = async (uid, publicProfileId, event) => {
	try {
		const plannerEventResponse = await axios
			.post(
				`${plannersGcfUrl}/${event.plannerId}/events`,
				{ uid, publicProfileId, event },
				{ headers: { 'Content-Type': 'application/json' } },
			);
		if (!plannerEventResponse.data.success) {
			throw ERROR_MSGS.plannerEventCreate404
		}
		return plannerEventResponse.data;
	} catch (err) {
		throw err;
	}
};
export function createPlannerEventThunk(event) {
	return async function inner(dispatch, getState) {
		try {
			const { user, publicProfile, planner } = getState();
			event.plannerId = planner.id;
			if (!user || !publicProfile || !event || !event.plannerId || !event.month || !event.year) return;
			dispatch({ type: actions.DATABASE_WORKING, payload: { databaseWorking: true, } });
			const plannerEventResponse = await createPlannerEvent(user.uid, publicProfile.id, event);
			if (plannerEventResponse?.success) {
				dispatch(getPlannerEventsThunk(event.plannerId, event.month, event.year));
			} else {
				dispatch({ type: actions.DATABASE_WORKING, payload: { databaseWorking: false, } });
			}
		} catch (err) {
			if (err.message === ERROR_MSGS.plannerEventCreate404) {
				handleError(
					{
						msg: err
					},
					dispatch
				);
			}
		}
	};
};