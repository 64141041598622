import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPreferencesThunk } from '../../model/users';
import { authoriseUserThunk } from '../../model/auth';
import { createProfilesThunk } from '../../model/publicRecipes';
import { useHeaderMenu } from '../../components/Layout/HeaderMenu';
import { Center, Loader, useMantineTheme } from '@mantine/core';
import useWindow from '../../hooks/useWindow';
import { WINDOW_DIMENSIONS_CHANGED } from '../../redux/actions';

const SecurePage = props => {
	const [authorised, setAuthorised] = useState(false);
	const [loading, setLoading] = useState(true);
	const error = useSelector(state => state.error);
	const user = useSelector(state => state.user);
	const profile = useSelector(state => state.profile);
	const publicProfile = useSelector(state => state.publicProfile);
	const isLoggedIn = useSelector(state => state.isLoggedIn);
	const isLoggedOut = useSelector(state => state.isLoggedOut);
	const sessionExpired = useSelector(state => state.sessionExpired);
	const auth = useSelector(state => state.auth);
	const windowDimensions = useSelector(state => state.windowDimensions);
	const { width, height } = useWindow();
	const theme = useMantineTheme();

  const HeaderMenu = useHeaderMenu({ help: true, disabled: true, transparent: props.isPublic  });
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (user) {
			dispatch(authoriseUserThunk(user));
		}
	}, [user]);

	useEffect(() => {
		if (height !== windowDimensions?.height) {
			dispatch({
				type: WINDOW_DIMENSIONS_CHANGED,
				payload: {
					windowDimensions: {
						width, height
					},
				}
			});
		}
		dispatch(authoriseUserThunk());
	}, [height]);

	useEffect(() => {
		if (loading && auth) {
			setLoading(false);
			if (auth?.active) {
				// if (!profile?.id || !publicProfile?.id) {
				// 	dispatch(createProfilesThunk());
				// }
				dispatch(getPreferencesThunk());
				setAuthorised(true);
			} else if (user) {
				navigate('/beta', { replace: true });
			} else {
				navigate('/sorry', { replace: true });
			}
		}
	}, [auth]);

	useEffect(() => {
		if (error && !error.acked) {
			if (isLoggedIn) {
				navigate('/menu', { replace: true });
			} else {
				navigate('/', { replace: true });
			}
		}
	}, [error, isLoggedIn, navigate]);

	useEffect(() => {
		if (sessionExpired) {
			navigate('/logout?e=1', { replace: false });
		}
		else if (isLoggedOut) {
			navigate('/logout', { replace: false });
		} else if (!isLoggedIn || !user) {
			navigate('/sorry', { replace: false });
		}
	}, [user, isLoggedIn, isLoggedOut, navigate]);

	return authorised ? (
		<div style={{ height: '100vh' }}>
			{props.children}
			{/* <div style={{ overflow: "hidden"}}>
				<svg
					preserveAspectRatio="none"
					viewBox="0 0 1200 120"
					xmlns="http://www.w3.org/2000/svg"
					style={{ fill: '#4D39AE', width: '100%', height: 170, transform: 'rotate(180deg)' }}
				>
					<path d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z" />
				</svg>
			</div> */}
		</div>
	) : (
		<>
			<HeaderMenu hideHelp={true} disabled />
		</>
	);
};

export default SecurePage;
