import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Button, Center, createStyles, Grid, Space, TextInput, useMantineTheme } from "@mantine/core";
import { cleanNotifications, } from "@mantine/notifications";
import * as actions from "../../redux/actions";
import { useHeaderMenu } from "../Layout/HeaderMenu";
import { PreferencesContext } from "../../config/context/preferencesContext";
import useWindow from "../../hooks/useWindow";

const INITIAL_STATE = {
	email: "",
	password: "",
	error: null
};
/* eslint-disable no-useless-escape */
export const emailRegex =
	/^[a-zA-Z0-9.!#$%&"*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const signupUserGcfUrl =
	document.location.origin === process.env.REACT_APP_PROD_ORIGIN
		? process.env.REACT_APP_SIGNUP_USER_GCF_PROD
		: process.env.REACT_APP_SIGNUP_USER_GCF_DEV;


const useStyles = createStyles((theme, { color }) => {
	return ({
		backdrop: {
			// background: theme.colors[theme.primaryColor][8],
			height: "100vh",
			width: "100vw",
			position: "fixed",

			[theme.other.breakpoint]: {
				position: "static",
				height: "100%"
			}
		},
		textInput: {
			width: "40%",
			[theme.other.breakpoint]: {
				width: "100%",
			}
		},
		header: {
			background: "red",
			backgroundSize: "cover",
			backgroundPosition: "top",
			position: "relative",
		},
		section: {
			// background: theme.colors[theme.primaryColor][8],
			width: "100%",
			margin: "auto",
		},
		box: {
			// backgroundColor: theme.primaryColor,
			borderRadius: process.env.REACT_APP_BORDER_RADIUS_MD,
			color: "white",
			padding: "1rem",
		},
		title: {
			fontFamily: `Greycliff CF, ${theme.fontFamily}`,
			fontSize: 62,
			fontWeight: 900,
			lineHeight: 1.1,
			margin: 0,
			padding: 0,
			color: theme.white,

			[theme.other.breakpoint]: {
				fontSize: 42,
				lineHeight: 1.2,
			},
		},
	});
});

const SignupForm = ({ recipeId, plannerId }) => {
	const [state, setState] = useState(INITIAL_STATE);
	const [ip, setIP] = useState("");
	const [authUser, setAuthUser] = useState(false);
	const [captchaChallenged, setCaptchaChallenged] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const captchaRef = useRef(null);
	const HeaderMenu = useHeaderMenu({ hideHelp: true, disabled: false, transparent: true });
	let signUpUrl = recipeId ? `/menu?r=${recipeId}` : "/";
	if (plannerId) {
		signUpUrl = `/menu?p=${plannerId}`;
	}
	const { email, password } = state;
	const { preferences } = useContext(PreferencesContext);
	const { classes } = useStyles({ color: preferences.themeId });
	const theme = useMantineTheme();
	const { width } = useWindow();

	const isInvalid =
		!emailRegex.test(email) || password.length < 6 || !captchaChallenged;

	const getIp = async () => {
		try {
			const res = await axios.get("https://geolocation-db.com/json/");
			setIP(res.data.IPv4);
		} catch (err) { }
	};

	useEffect(() => {
		// getIp();
		return () => {
			cleanNotifications();
		};
	}, []);

	const notifyError = error => {
		const notification = {
			id: "signup-process",
			type: "error",
			title: "Oops!",
			message: error,
		};
		dispatch({ type: actions.LOAD_NOTIFICATION, payload: { ...notification } });
		setSubmitted(false);
		captchaRef.current.reset();
	};

	const memoizedSignup = useCallback(
		async (email, password, captchaChallenged, ip) => {
			const verificationRes = await axios
				.post(
					signupUserGcfUrl,
					{
						email,
						password,
						ip,
						token: captchaChallenged
					},
					{
						headers: {
							"Content-Type": "application/json"
						}
					}
				)
				.catch(error => {
					console.error(error);
					notifyError(error);
				});
			if (verificationRes?.data?.success) {
				const notificationError = {
					id: "signup-process",
					title: "Success!",
					message: "Your Nomicon is ready.",
					type: "success",
				};
				dispatch({ type: actions.LOAD_NOTIFICATION, payload: { ...notificationError } });
				const authUser = verificationRes.data.authUser;
				const auth = getAuth();
				signInWithEmailAndPassword(auth, email, password)
					.then(userCredential => {
						setAuthUser(authUser);
						setLoading(false);
					})
					.catch(err => {
						setLoading(false);
					});
			} else {
				notifyError(verificationRes?.data?.error.message);
				setCaptchaChallenged(false);
				setSubmitted(false);
				setLoading(false);
			}
		},
		[]
	);

	useEffect(() => {
		if (submitted) {
			async function signupWrapper() {
				await memoizedSignup(email, password, captchaChallenged, ip);
			}
			signupWrapper();
		}
	}, [submitted, memoizedSignup, email, password, captchaChallenged, ip]);

	useEffect(() => {
		if (authUser) {
			dispatch({
				type: actions.SET_USER,
				payload: { user: authUser, isLoggedIn: true }
			});
			setTimeout(() => {
				navigate(signUpUrl, { replace: false });
			}, process.env.REACT_APP_SIGNUP_TIMEOUT_MS);
		}

		return () => {
			setAuthUser(null);
		};
	}, [authUser, dispatch, navigate, signUpUrl]);

	const onSubmit = async () => {
		setLoading(true);
		setSubmitted(true);
		const notification = {
			id: "signup-process",
			title: "Creating Account",
			message: "This will only take a moment!",
			type: "loading",
		};
		dispatch({ type: actions.LOAD_NOTIFICATION, payload: notification });
	};

	const onChange = event => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const onChangeCaptcha = token => {
		setCaptchaChallenged(token);
	};

	return (
		<div>
			<HeaderMenu hideHelp={true} />
			<Box sx={{ width: "50%", margin: "auto", marginTop: "3rem" }}>
				<h1 style={{ textAlign: "center" }} className={[classes.title, classes.section].join(" ")}>
					Create Account.
				</h1>
			</Box>
			<Center>
				<Box>
					<Grid>
						<Grid.Col>
							<Center p={"md"}>
								<TextInput
									required
									id="email"
									name="email"
									autoComplete="username"
									onChange={onChange}
									placeholder={"Email"}
									value={email}
									type="email"
									mt={"lg"}
									px={width > 800 ? 'md' : 'xl'}
									label={<span style={{ color: "white" }}>Email</span>}
									sx={{ minWidth: "6rem" }}
									className={classes.textInput}
								/>
							</Center>
						</Grid.Col>
						<Grid.Col>
							<Center p={"md"}>
								<TextInput
									required
									id="password"
									name="password"
									autoComplete="current-password"
									onChange={onChange}
									placeholder={`Password`}
									value={password}
									type={"password"}
									mt={"lg"}
									px={width > 800 ? 'md' : 'xl'}
									label={<span style={{ color: "white" }}>Password</span>}
									sx={{ minWidth: "6rem" }}
									className={classes.textInput}
								/>
							</Center>
						</Grid.Col>
						<Grid.Col>
							<Center mt={"lg"}>
								<ReCAPTCHA
									ref={captchaRef}
									sitekey="6Lev1EUgAAAAAKUpacHyma9xyr1kYoLycgL7IabP"
									onChange={onChangeCaptcha}
									onExpired={() => setCaptchaChallenged(false)}
									onErrored={() => setCaptchaChallenged(false)}
								/>
							</Center>
						</Grid.Col>
						<Grid.Col>
							<Center mt={"xl"}>
								<Button
									variant={'subtle'}
									sx={{
										color: 'white',
										'&:hover': {
											backgroundColor: "#343434",
											color: 'white',
										},
									}}
									onClick={() => navigate(`/`)}
									size={"lg"}
								>
									Back
								</Button>
								<Space w={"lg"} />
								<Button
									size={"lg"}
									variant={'primary'}
									sx={{
										color: document.body.style.backgroundColor,
										backgroundColor: "white",
										'&:hover': {
											backgroundColor: "#343434",
											color: 'white',
										},
										'&[data-disabled]': {
											color: '#878787',
											'& svg': { stroke: document.body.style.backgroundColor },
										},
									}}
									onClick={onSubmit}
									disabled={isInvalid || loading}
									loading={loading}
								>
									Create Account
								</Button>
							</Center>
						</Grid.Col>
					</Grid>
				</Box>
			</Center>
		</div>
	);
};

export default SignupForm;
