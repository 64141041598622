import React from "react";
import { useLocation } from "react-router-dom";
import VerifyForm from "../../components/SigninForm/VerifyForm";
import { extractParamsFromLocation } from "../../utils/mappings";

const VerifyPage = () => {
  const location = useLocation();
  const params = extractParamsFromLocation(location);
  const continueUrl = decodeURIComponent(params["continueUrl"]);
  // "http://localhost:3000/verify?email%3Dnodedevel%2Bempty@gmail.com"
  const url = new URL(continueUrl);
  const p = new URLSearchParams(url.search);

  // TODO: find a more robust way to extra email from continueUrl
  // decodeURIComponent is stripping '+' off Gmail aliases
  return <VerifyForm email={p.get('email').replace(' ', '+')} oobCode={params["oobCode"]} />;
};

export default VerifyPage;
