import React, { useContext } from 'react';
import { Box, createStyles, Title, useMantineTheme } from '@mantine/core';
import { INGREDIENT_TYPES } from '../Ingredient/Ingredient';
import { PreferencesContext } from '../../config/context/preferencesContext';


const useStyles = createStyles((theme, { color = 'default', dark }) => {
	return ({
		box: {
			...theme.other.preventSelection,
			backgroundColor: theme.colors[color][theme.other.rowColorHueIndex],
			borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
			color: 'white',
			padding: '1rem',
		},
	});
});

const translateType = inputType => {
	if (!inputType) return;
	const typeDef = INGREDIENT_TYPES.find(ingType => ingType.value === inputType);
	return typeDef.label;
}

export const useBuildIngredientLabel = ({ amount = null, name, type = null, }) => {
	const hasAmount = !!amount;
	const hasType = type && type !== 'none';
	let qtyLabel = hasAmount ? amount : '';
	if (hasType) {
		qtyLabel = qtyLabel + (hasAmount ? ' / ' : '') + translateType(type);
	}
	let label;
	if (hasAmount || hasType) {
		label = `${name} (${qtyLabel})`;
	} else {
		label = name;
	}
	return (
		<Title
			style={{ textAlign: 'center' }}
			order={4}
		>{label}</Title>
	)
};

const RecipeRow = ({
	listItem,
	onClick,
}) => {
	let { preferences } = useContext(PreferencesContext);
	const theme = useMantineTheme();
	const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
	const ingredientLabel = useBuildIngredientLabel(listItem);

	return (
		<Box
			key={listItem?.id}
			mt={'xs'}
			onClick={onClick}
			className={classes.box}
			sx={{ ...theme.other.lineItem }}
		>
			{ingredientLabel}
		</Box>
	);
};

export default RecipeRow;
