import React, { useContext, useEffect, useState } from 'react';
import { useHotkeys } from '@mantine/hooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withFirebase } from '../../config/context/firebaseContext';
import { CLEAR_PREFERENCES } from '../../redux/actions';
import * as actions from '../../redux/actions';
import { useHeaderMenu } from '../Layout/HeaderMenu';
import { Anchor, Box, Button, Center, createStyles, Grid, Space, Text, TextInput, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons';
import { emailRegex } from '../SignupForm/SignupForm';
import {
	doSendPasswordResetEmail,
	doSignInWithEmailAndPassword
} from '../../config/firebase/firebase';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { authoriseUserThunk } from '../../model/auth';
import useWindow from '../../hooks/useWindow';

const INITIAL_STATE = {
	email: '',
	password: '',
	error: null
};

const useStyles = createStyles((theme, { color }) => {
	return ({
		wrapper: {
			position: 'relative',
			paddingTop: 20,
			paddingBottom: 80,

			[`@media (max-width: ${process.env.REACT_APP_SCREEN_SM_BREAKPOINT}px)`]: {
				paddingTop: 40,
				paddingBottom: 60,
			},
		},
		section: {
			// background: theme.colors[theme.primaryColor][8],
			width: '100%',
			margin: 'auto',
		},
		skewed: {
			transform: 'skewY(-7deg)',
		},
		backdrop: {
			// background: theme.colors[theme.primaryColor][8],
			height: '100vh',
			width: '100vw',
			position: 'fixed',

			[theme.other.breakpoint]: {
				position: 'static',
				height: '100%',
			}
		},
		textInput: {
			width: '40%',
			[theme.other.breakpoint]: {
				width: '100%',
			}
		},
		clipped: {
			padding: '3.5rem',
			// background: theme.colors[theme.primaryColor][8],
		},
		clippedHeader: {
			padding: '2rem',
			// background: theme.colors[theme.primaryColor][8],
			clipPath: 'polygon(0 0, 100% 0, 100% 85%, 0 100%)',
		},
		header: {
			background: 'red',
			backgroundSize: 'cover',
			backgroundPosition: 'top',
			position: 'relative',
		},

		box: {
			// backgroundColor: theme.primaryColor,
			borderRadius: process.env.REACT_APP_BORDER_RADIUS_MD,
			color: 'white',
			padding: '1rem',
		},

		inner: {
			position: 'relative',
		},

		title: {
			text: 'center',
			fontFamily: `Greycliff CF, ${theme.fontFamily}`,
			fontSize: 62,
			fontWeight: 900,
			lineHeight: 1.1,
			margin: 0,
			padding: 0,
			color: theme.white,

			[theme.other.breakpoint]: {
				fontSize: 42,
				lineHeight: 1.2,
			}
		}
	});
});

const SigninForm = ({ firebase, subscribed, recipeId, plannerId }) => {
	const [state, setState] = useState(INITIAL_STATE);
	const [forgottenPassword, setForgottenPassword] = useState(false);
	const [forgottenPasswordCodeSent, setForgottenPasswordCodeSent] = useState(false);
	const [showSubscribedMsg, setShowSubscribedMsg] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const HeaderMenu = useHeaderMenu({ hideHelp: true, disabled: false, transparent: true });
	let signInUrl = recipeId ? `/?r=${recipeId}` : '/';
	if (plannerId) {
		signInUrl = `/menu?p=${plannerId}`;
	}
	const theme = useMantineTheme();
	const { preferences } = useContext(PreferencesContext);
	const { classes } = useStyles({ color: preferences.themeId });
	const { email, password } = state;
	const { width } = useWindow();

	const isInvalid = email === '' || password === '';
	const isResetPasswordInvalid = email === '' || !emailRegex.test(email);
	useHotkeys([
		['enter', () => {
			if (!isInvalid && !loading) {
				onSubmit();
			}
		}],
	]);

	useEffect(() => {
		dispatch({
			type: actions.LOAD_VERSIONS,
			payload: {
				version: null
			}
		});
		setShowSubscribedMsg(subscribed === 'true' ? true : false);
		dispatch({
			type: CLEAR_PREFERENCES,
			payload: {}
		});
		return () => {
			setShowSubscribedMsg(false);
		};
	}, [dispatch, subscribed]);

	useEffect(() => {
		if (showSubscribedMsg) {
			showNotification({
				id: 'subscribe-process',
				disallowClose: false,
				autoClose: 5000,
				title: 'Your account is ready! 🎉 You can now sign in below.',
				color: 'teal',
				icon: <IconCheck />,
				loading: false,
			});
		}
	}, [showSubscribedMsg]);

	const onSubmit = evt => {
		setLoading(true)
		const { email, password } = state;
		doSignInWithEmailAndPassword(email, password)
			.then(userCredential => {
				setLoading(false);
				const user = userCredential.user;
				dispatch({
					type: actions.SET_USER,
					payload: { user: user, isLoggedIn: true, version: null }
				});
				navigate(signInUrl, { replace: false });
			})
			.catch(err => {
				setLoading(false);
				if (err)
					showNotification({
						id: 'subscribe-process',
						disallowClose: true,
						autoClose: 5000,
						title: "Oops!",
						message: "We couldn't log you in with those details.",
						color: 'red',
						icon: <IconX />,
						loading: false,
					});
				setState({
					...state,
					error: "Oops! We couldn't log you in with those details."
				});
			});
	};

	const onForgot = evt => {
		setLoading(true);
		const { email } = state;
		doSendPasswordResetEmail(email)
			.then(_ => {
				showNotification({
					id: 'reset-password',
					disallowClose: false,
					autoClose: 8000,
					title: "Password reset email sent",
					message: "Check your email for your password reset link.",
					color: 'teal',
					icon: <IconCheck />,
					loading: false,
				});
				setForgottenPasswordCodeSent(true);
				setLoading(false);
			})
			.catch(err => {
				setLoading(false);
				showNotification({
					id: 'reset-password',
					disallowClose: true,
					autoClose: 5000,
					title: "Oops!",
					message: "We weren't able to reset your password - try again later.",
					color: 'red',
					icon: <IconX />,
					loading: false,
				});
			});
	};

	const onKeyDown = event => {
		if (event.keyCode === 13) onSubmit();
	};

	const onChange = event => {
		setState({
			...state,
			error: null,
			[event.target.name]: event.target.value
		});
	};

	return (
		<div>
			<HeaderMenu hideHelp={true} disabled />
			<Center>
				{forgottenPassword ? (
					<Box>
						<Grid>
							<Grid.Col>
								<Box sx={{ width: '50%', margin: 'auto', marginTop: '3rem' }}>
									<h1 style={{ textAlign: 'center' }} className={[classes.title, classes.section].join(' ')}>
										Account Recovery.
									</h1>
								</Box>
							</Grid.Col>
							<Grid.Col>
								<Box ml={'lg'} mr={'lg'}>
									<Text sx={{ color: 'white', textAlign: 'center' }}>
										{forgottenPasswordCodeSent
											? 'Check your email inbox for instructions on how to reset your password.'
											: 'Enter your email and we\'ll send you a link to reset your password.'}
									</Text>
								</Box>
							</Grid.Col>
							{forgottenPasswordCodeSent ? (
								null
							) : (
								<Grid.Col>
									<Center p={'md'}>
										<TextInput
											required
											id='email'
											name='email'
											autoComplete='username'
											onChange={onChange}
											placeholder={'Email'}
											value={email}
											type='email'
											mt={'lg'}
											// px={width > 800 ? 'md' : 'xl'}
											label={<span style={{ color: 'white' }}>Email</span>}
											sx={{ minWidth: "6rem" }}
											className={classes.textInput}
										/>
									</Center>
								</Grid.Col>
							)}
							{forgottenPasswordCodeSent ? (
								null
							) : (
								<Grid.Col>
									<Center mt={'md'}>
										<Button
											size={'lg'}
											onClick={onForgot}
											disabled={isResetPasswordInvalid || loading}
											variant={'primary'}
											sx={{
												color: document.body.style.backgroundColor,
												backgroundColor: "white",
												'&:hover': {
													backgroundColor: "#343434",
													color: 'white',
												},
											}}
											loading={loading}
										>
											Reset Password
										</Button>
									</Center>
								</Grid.Col>
							)}
							<Grid.Col>
								<Center mt={'lg'}>
									<Anchor type={'button'} onClick={() => setForgottenPassword(!forgottenPassword)} sx={{ color: 'white' }}>
										{forgottenPassword ? 'Back to Sign In' : 'Forgot your password?'}
									</Anchor>
								</Center>
							</Grid.Col>
							<footer style={{ height: '280px' }} />
						</Grid>
					</Box>
				) : (
					<Box>
						<Grid>
							<Grid.Col>
								<Box sx={{width: "50%", margin: 'auto', marginTop: '3rem' }}>
									<h1 style={{ textAlign: 'center' }} className={[classes.title, classes.section].join(' ')}>
										Welcome back.
									</h1>
								</Box>
							</Grid.Col>
							<Grid.Col>
								<Center p={'md'}>
									<TextInput
										required
										id='email'
										name='email'
										autoComplete='username'
										onChange={onChange}
										onKeyDown={onKeyDown}
										placeholder={'Email'}
										value={email}
										type='email'
										mt={'lg'}
										px={width > 800 ? 'md' : 'xl'}
										sx={{ minWidth: "6rem" }}
										label={<span style={{ color: 'white' }}>Email</span>}
										className={classes.textInput}
									/>
								</Center>
							</Grid.Col>
							<Grid.Col>
								<Center p={'md'}>
									<TextInput
										required
										id='password'
										name='password'
										autoComplete='current-password'
										onChange={onChange}
										onKeyDown={onKeyDown}
										placeholder={'Password'}
										value={password}
										type={"password"}
										mt={"lg"}
										px={width > 800 ? 'md' : 'xl'}
										sx={{ minWidth: "6rem" }}
										label={<span style={{ color: 'white' }}>Password</span>}
										className={classes.textInput}
									/>
								</Center>
							</Grid.Col>
						</Grid>
						<Center mt={'xl'}>
							<Button
								variant={'subtle'}
								sx={{
									color: 'white',
									'&:hover': {
										backgroundColor: "#343434",
										color: 'white',
									},
								}}
								onClick={() => navigate(`/`)}
								size={'lg'}
							>
								Back
							</Button>
							<Space w={'lg'} />
							<Button
								onClick={onSubmit}
								variant={'primary'}
								disabled={isInvalid || loading}
								size={'lg'}
								loading={loading}
								sx={{
									color: document.body.style.backgroundColor,
									backgroundColor: "white",
									'&:hover': {
										backgroundColor: "#343434",
										color: 'white',
									},
									'&[data-disabled]': {
										color: '#878787',
										'& svg': { stroke: document.body.style.backgroundColor },
									},
								}}
							>
								Sign In
							</Button>
						</Center>
						<Center mt={'lg'}>
							<Anchor type={'button'} onClick={() => setForgottenPassword(!forgottenPassword)} sx={{ color: 'white' }}>
								{forgottenPassword ? 'Back to Sign In' : 'Forgot your password?'}
							</Anchor>
						</Center>
					</Box>
				)}
			</Center>
		</div>
	);
};

export default withFirebase(SigninForm);
