import React, { useEffect } from "react";

import SecurePage from "../../config/auth/SecurePage";
import GenericList from "../../components/GenericList/GenericList";
import { useDispatch, useSelector } from "react-redux";
import useWindow from "../../hooks/useWindow";
import { createPlannerThunk, deletePlannerAccessThunk, deletePlannerThunk, getPlannerRowsThunk } from "../../model/planners";
import { IconX } from "@tabler/icons";
import { CLEAR_PLANNER, CLEAR_PLANNER_EVENTS, CLEAR_PLANNER_SHARE_ACKNOWLEDGED, SET_ERROR } from "../../redux/actions";
import { updateNotification } from "@mantine/notifications";

const PlannersPage = () => {
  const error = useSelector(state => state.error);
  const plannerCreationResponse = useSelector(state => state.plannerCreationResponse);
  const publicProfile = useSelector(state => state.publicProfile);
  const isLoading = useSelector(state => state.isLoading);
  const dispatch = useDispatch();
  const { height } = useWindow();
  const pageSize = height > 840
		? process.env.REACT_APP_PAGE_SIZE_DESKTOP
		: process.env.REACT_APP_PAGE_SIZE_MOBILE;

  useEffect(() => {
    dispatch({ type: CLEAR_PLANNER_EVENTS, payload: {} });
    dispatch({ type: CLEAR_PLANNER, payload: {} });
    dispatch({ type: CLEAR_PLANNER_SHARE_ACKNOWLEDGED, payload: {} });

    dispatch(getPlannerRowsThunk({
      pageSize,
    }));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (error) {
      updateNotification({
        id: 'planner-creation',
        autoClose: 5000,
        title: "Oops!",
        message: plannerCreationResponse?.error.message,
        color: 'red',
        icon: <IconX />,
        loading: false,
      });
      dispatch({ type: SET_ERROR, payload: {} });
    } else if (plannerCreationResponse?.success) {
      // updateNotification({
      //   id: 'planner-creation',
      //   autoClose: 5000,
      //   title: "Success!",
      //   message: "Your Nomicon is ready.",
      //   color: 'green',
      //   icon: <IconCheck />,
      //   loading: false,
      // });
      dispatch({ type: SET_ERROR, payload: {} });
    }
  }, [error]); // eslint-disable-line

  // const next = () => {
  //   dispatch(getRecipeRowsThunk({
  //     next: nextRecipePage,
  //     recipeLetter: recipeLetter,
  //     pageSize,
  //   }));
  // };

  const pageThunks = (row) => {
    let deleteThunk = deletePlannerAccessThunk;
    let deleteLabel = 'Remove Planner';
    if (row && row.owner === publicProfile.id) {
      deleteThunk = deletePlannerThunk;
      deleteLabel = 'Delete Planner';
    }
    return {
      create: createPlannerThunk,
      get: getPlannerRowsThunk,
      delete: deleteThunk,
      deleteLabel,
    };
  }

  const rowName = (row) => {
    return row.name;
  }

  return (
    <SecurePage>
      <GenericList
        cols={12}
        objectType={'planner'}
        pageThunks={pageThunks}
        rowNameFn={rowName}
        loading={isLoading}
      />
    </SecurePage>
  );
};

export default PlannersPage;
