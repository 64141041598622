import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useLagNavigate = (ms, fromLabel = '') => {
	const location = useLocation();
	const navigate = useNavigate();
	const [clicked, setClicked] = useState(false);

	useEffect(() => {
		return () => {
			setClicked(false);
		};
	}, []);

	const onClick = (urlOverride) => {
		setTimeout(() => {
			navigate(urlOverride, { state: { from: location, fromLabel } });
			setClicked(false);
		}, ms);
	};

	return [clicked, onClick];
};
