import { Avatar, Badge, Table, Group, Text, Select, ScrollArea, useMantineTheme, LoadingOverlay, Container, Box } from '@mantine/core';
import { IconChefHat } from '@tabler/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updatePlannerAccessThunk } from '../../model/planners';
import { byObjProperty } from '../../utils/sort';
import { forwardRef, useEffect, useState } from 'react';
import { LOAD_PLANNER_ACCESS_UPDATED } from '../../redux/actions';

const rolesData = ['Admin', 'Editor', 'Viewer', 'Remove'];

const RoleItem = forwardRef(
  ({ value, ...others }, ref) => {
    return (
      <div ref={ref} {...others} style={{ width: '100%' }}>
        <Group noWrap>
          <div>
            <Text size="sm">{value}</Text>
          </div>
        </Group>
      </div>
    )
  }
);

export function ChefList({ data, owner, publicProfile, planner }) {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const getColor = (color) => theme.colors[color][theme.colorScheme === 'dark' ? 5 : 7];
  const preparedData = data.map(d => ({ ...d, sortname: d.name.toUpperCase() }));
  const sortedData = preparedData.sort(byObjProperty('sortname', true));
  const plannerAccessResponse = useSelector(state => state.plannerAccessResponse);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (plannerAccessResponse) {
      setTimeout(() => {
        setLoading(false);
        dispatch({ type: LOAD_PLANNER_ACCESS_UPDATED, payload: { undefined } });
      }, 500);
    }
  }, [plannerAccessResponse]);

  const rows = sortedData.map((item, idx) => {
    let color = undefined;
    switch (item.role) {
      case 'Admin':
        color = 'red';
        break;
      case 'Editor':
        color = 'grape';
        break;
      default:
        color = 'teal';
    }

    const isDisabled = (publicProfileId) => {
      const userIsAdmin = planner.admins.includes(publicProfileId);
      const userIsEditor = planner.admins.includes(publicProfileId);
      const userIsViewer = planner.admins.includes(publicProfileId);
      const userIsViewerOnly = userIsViewer && !userIsEditor;
      let disabled = false;
      if (item.publicProfileId === owner) {
        disabled = true;
      } else if (userIsViewerOnly) {
        disabled = true;
      }
      return disabled;
    }

    const handleRoleChange = (roleDisplay, publicProfileId) => {
      setLoading(true);
      let role = undefined;
      switch (roleDisplay) {
        case "Admin":
          role = "admin"
          break;
        case "Editor":
          role = "editor"
          break;
        case "Viewer":
          role = "viewer"
          break;
        default:
          role = "remove"
      }
      dispatch(updatePlannerAccessThunk(publicProfileId, role));
    }

    return (
      <tr key={idx}>
        {/* <td>
          <Avatar size={40} radius={40}><IconChefHat color={getColor(color)} /></Avatar>
        </td> */}
        <td>
          <Group spacing="sm">
            <div>
              <Text fz="sm" fw={500}>
                {item.name}{' '}{item.publicProfileId === publicProfile.id && "(You)"}
              </Text>
            </div>
          </Group>
        </td>
        <td>
          <Select
            onChange={(role) => handleRoleChange(role, item.publicProfileId)}
            dropdownPosition={'flip'}
            // itemComponent={RoleItem}
            maxDropdownHeight={400}
            data={rolesData}
            value={item.role}
            variant="unstyled" disabled={isDisabled(item.publicProfileId)} />
        </td>
      </tr>
    );
  });

  return (
    <Box sx={{ position: 'relative', padding: 'none' }}>
      <LoadingOverlay
        visible={loading}
        overlayBlur={20}
        transitionDuration={500}
      />
      <Table verticalSpacing="sm" sx={{ height: '100%', width: '100%' }}>
        <thead>
          <tr>
            {/* <th></th> */}
            <th>Chef</th>
            <th>Access Level</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Box>
  );
}