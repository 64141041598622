import React from "react";
import { useParams } from "react-router-dom";

import ShoppingList from "../../components/ShoppingLists/ShoppingList";
import SecurePage from "../../config/auth/SecurePage";

const ShoppingListPage = () => {
  let params = useParams();

  return (
    <SecurePage>
        <ShoppingList id={params.id} />
    </SecurePage>
  );
};

export default ShoppingListPage;
