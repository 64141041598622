import { Button } from "@mantine/core";
import { useContext } from "react";
import { PreferencesContext } from "../../config/context/preferencesContext";

export const RejectButton = ({ children, onSubmit, disabled = false, isPublic = false }) => {
  const { preferences } = useContext(PreferencesContext);

  return (
    <Button
      variant={'subtle'}
      onClick={onSubmit}
      disabled={disabled}
      size={isPublic ? 'lg' : undefined}
      sx={{
        color: preferences?.dark ? 'white' : "#343434",
        '&:hover': {
          backgroundColor: "#343434",
          color: 'white',
        },
      }}
    >
      { children }
    </Button>
  );
}