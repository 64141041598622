import { Box, Modal, Title, createStyles, useMantineTheme } from "@mantine/core";
import { useContext } from "react";
import { PreferencesContext } from "../../config/context/preferencesContext";
import { useState } from "react";
import { NewItemModal } from "../Inputs/NewModal";
import { ITEM_CONFIG } from "../Inputs/NewModal";

const useStyles = createStyles((theme, { color = 'default', dark }) => {
  return ({
    title: {
      ...theme.other.title,
    },
  });
});

export const TitleText = ({ title, renameFn, source, maxLength }) => {
  const theme = useMantineTheme();
  const { preferences } = useContext(PreferencesContext);
  const [renaming, setRenaming] = useState(false);
  const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });

  const titleModal = source && (
		<Modal
			overlayColor={preferences.dark ? theme.colors.dark[9] : theme.colors.gray[2]}
			overlayOpacity={0.55}
			overlayBlur={3}
			opened={renaming}
			onClose={() => setRenaming(false)}
			title={`Rename ${ITEM_CONFIG[source] && ITEM_CONFIG[source].type}`}
			closeOnEscape
			zIndex={4000}
			closable={"false"}
		>
			<NewItemModal
				source={source}
				onCancel={() => setRenaming(false)}
				onSubmit={name => {
          renameFn(name);
          setRenaming(false);
        }}
				nameMaxLength={maxLength}
				inName={title}
			/>
		</Modal>
	);

  return (
    <Box sx={{ minHeight: '3rem' }}>
      {titleModal}
      <Title
        onClick={() => setRenaming(!renaming)}
        order={1}
        style={{
          color: preferences.dark ? 'white' : 'black',
          letterSpacing: '1.2px',
          textAlign: 'center',
          ...theme.other.preventSelection,
        }}
      >
        {title}
      </Title>
    </Box>
  )
};