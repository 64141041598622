import React, { useEffect } from "react";

import SecurePage from "../../config/auth/SecurePage";
import GenericList from "../../components/GenericList/GenericList";
import { useDispatch } from "react-redux";
import { getShoppingListsThunk } from "../../model/shoppingLists";

const ShoppingListsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getShoppingListsThunk())
  }, []);

  return (
    <SecurePage>
      <GenericList objectType={'shopping'} />
    </SecurePage>
  );
};

export default ShoppingListsPage;
