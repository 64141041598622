import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { rootReducer } from "./reducer";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "./storage";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const reduxDevTools = typeof window !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION__() : null;

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
const store = createStore(persistedReducer, composedEnhancer);

export default store;
export const persistedStore = persistStore(store);
