export const firebaseConfigDevel = {
	apiKey: process.env.REACT_APP_FB_DEV_API_KEY,
  authDomain: process.env.REACT_APP_FB_DEV_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_DEV_APP_ID
};

export const firebaseConfigProd = {
	apiKey: process.env.REACT_APP_FB_PROD_API_KEY,
  authDomain: process.env.REACT_APP_FB_PROD_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_PROD_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_PROD_APP_ID,
  measurementId: process.env.REACT_APP_FB_PROD_MEASUREMENT_ID
};

export const domainConfig = {
  "http://localhost:3000": firebaseConfigDevel,
  "https://dev.nomicon.app": firebaseConfigDevel,
  "https://nomicon.app": firebaseConfigProd
};
