/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import {
	Center,
	Container,
	createStyles,
	Loader,
	Modal,
	Space,
	Switch,
	Tabs,
	useMantineTheme
} from '@mantine/core';
import { IconBasket, IconBook, IconCalendarTime } from '@tabler/icons'

import ShoppingListIngredients from './ShoppingListIngredients';
import { IngredientForm } from '../Ingredient/Ingredient';

import {
	deleteShoppingListThunk,
	getShoppingListRecipesThunk,
	getShoppingListThunk,
	updateShoppingListIngredientsThunk,
	updateShoppingListThunk
} from '../../model/shoppingLists';
import { getPublicShoppingListAsOwnerThunk, updatePublicShoppingListThunk } from '../../model/shoppingListsPublic';
import { PreferencesContext } from '../../config/context/preferencesContext';
import { addShoppingListIngredientThunk } from '../../model/ingredients';
import { deserializeItems, serializeItems } from '../../utils/mappings';
import { reorder } from '../../utils/sort';
import { useHeaderMenu } from '../Layout/HeaderMenu';
import { BackButton } from '../Layout/BackButton';
import SideControls from '../Inputs/SidebarControls';
import { ShareModal } from '../Inputs/ShareModal';
import { DangerousActionModal } from '../Inputs/DangerousActionModal';
import useWindow from '../../hooks/useWindow';
import { HelpWrapper } from '../Utils/HelpWrapper';
import { doc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import { RecipeAdded } from './ShoppingListRecipeAdded';
import { TitleText } from '../Layout/TitleText';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { getDocumentThunk, updateDocumentThunk } from '../../model/generic';
import { LOAD_SEARCH_PUBLIC_PROFILE, LOAD_SHOPPING_LIST } from '../../redux/actions';
import InfoBox from '../Layout/InfoBox';
import RecipesPageInner from '../Recipes/RecipesPageInner';
import { ShoppingListSections } from './ShoppingListSections';
import { useColorBackground } from '../../hooks/useColorBackground';
import { useHotkeys } from '@mantine/hooks';

const useStyles = createStyles((theme, { color = 'default', dark }) => {
	return ({

		detailBarItem: {
			...theme.other.detailBarItem,
		},

		backBtn: {
			...theme.other.backBtn,
		},

		tooltip: {
			backgroundImage: `linear-gradient(-60deg, ${theme.fn.primaryColor()} 0%, ${theme.fn.primaryColor()} 100%)`,
			borderRadius: '12px',
		},

		mealplanBox: {
			color: 'white',
			fontWeight: 'bold',
			display: 'flex',
			backgroundImage: `linear-gradient(-60deg, ${theme.fn.primaryColor()} 0%, ${theme.fn.primaryColor()} 100%)`,

			[theme.fn.smallerThan('sm')]: {
				flexDirection: 'column',
			},

			userSelect: 'none',
			borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
		},

		deleteButton: {
			backgroundColor: theme.colors['warning'][7]
		},

		box: {
			backgroundColor: theme.colors[color][8],
			borderRadius: process.env.REACT_APP_BORDER_RADIUS_SM,
			color: 'white',
			padding: '1rem',
		},

		title: {
			...theme.other.title,
		},

		innerContainer: {
			...theme.other.innerContainer,
		},

		highlight: {
			color: 'white'
		}
	});
});

const ShoppingList = ({ id, isPublic = false }) => {
	const { preferences } = useContext(PreferencesContext);
	const shoppingListDb = useSelector(state => state.shoppingList);
	const shoppingListRecipes = useSelector(state => state.shoppingListRecipes);
	const [publicShoppingList] = useDocumentData(doc(db, 'publicShoppingLists', id));
	const publicProfile = useSelector(state => state.searchPublicProfile);
	const recipesMeta = useSelector(state => state.recipesMeta);
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [addingIngredient, setAddingIngredient] = useState(false);
	const [editingIngredient, setEditingIngredient] = useState(null);
	const [editExisting, setEditExisting] = useState(false);
	const [shareVisible, setShareVisible] = useState(false);
	const [activeTab, setActiveTab] = useState('shopping');
	const [deleting, setDeleting] = useState(false);
	const [resetting, setResetting] = useState(false);
	const [showAdvanced, setShowAdvanced] = useState(false);
	const { width } = useWindow();
	const theme = useMantineTheme();
	const { classes } = useStyles({ color: theme.primaryColor, dark: preferences.dark });
	const HeaderMenu = useHeaderMenu({ help: true, disabled: false });
	const itemsEndRef = useRef(null);
	const itemsStartRef = useRef(null);
	useHotkeys([
    ['a', () => addIngredient()],
    ['s', () => setShareVisible(true)],
  ]);

	const shoppingList = isPublic ? publicShoppingList : shoppingListDb;
	const profileConfig = publicShoppingList?.publicProfileId ? {
		id: publicShoppingList.publicProfileId,
		name: publicProfile?.name || "Mystery Chef",
		fromLabel: `${publicProfile?.name || "Mystery Chef"}'s Shopping List`
	} : null;

	useColorBackground(false);

	useEffect(() => {
		if (id && !isPublic) {
			dispatch(getShoppingListThunk(id));
		}
	}, []);

	useEffect(() => {
		if (loading) {
			setTimeout(() => setLoading(false), process.env.REACT_APP_LAG_NAV_MS);
		}
		if (!shoppingListRecipes) {
			dispatch(getShoppingListRecipesThunk());
		}
		if (shoppingList || isPublic) return;
		dispatch(
			getPublicShoppingListAsOwnerThunk(shoppingList?.publicShoppingListId)
		);
	}, [shoppingList]);

	useEffect(() => {
		if (publicShoppingList?.publicProfileId && !publicProfile) {
			dispatch(
				getDocumentThunk(
					publicShoppingList?.publicProfileId,
					'publicProfiles',
					'searchPublicProfile',
					LOAD_SEARCH_PUBLIC_PROFILE,
				)
			);
		}
	}, [publicShoppingList]);

	const hasRecipes = () => {
		let recipesFound = false;
		if (!recipesMeta) return recipesFound;
		for (const [recipeLetter, recipeCount] of Object.entries(recipesMeta.recipeCounts)) {
			if (recipeCount > 0) {
				recipesFound = true;
				break;
			}
		}
		return recipesFound;
	}
	const updateShoppingList = (slist) => {
		dispatch(updateShoppingListThunk(slist));
	}

	const addIngredients = ingredients => {
		if (id) dispatch(addShoppingListIngredientThunk(id, ingredients));
		setAddingIngredient(false);
	};

	const editIngredient = ings => {
		const editedItem = ings[0];
		const newIngs = shoppingList.ingredients.map(i => {
			if (i.id === editedItem.id) {
				return Object.assign({}, editedItem);
			} else {
				return i;
			}
		});
		onSaveOrder(deserializeItems(newIngs, shoppingList.sections));
	};

	const onDeleteShoppingList = () => {
		dispatch(deleteShoppingListThunk(shoppingList));
		navigate(-1);
	};

	const addIngredient = () => {
		setAddingIngredient(true);
	};

	const cancelIngredient = () => {
		setEditExisting(false);
		setEditingIngredient(null);
		setAddingIngredient(!addingIngredient);
	};

	const onSaveOrderPrivate = items => {
		const serializedItemList = serializeItems(items);
		if (id)
			dispatch(updateShoppingListIngredientsThunk(id, serializedItemList));
	};

	const onSaveOrderPublic = items => {
		for (const [droppableId, sectionItems] of Object.entries(items)) {
			items[droppableId] = sectionItems.map(ing =>
				Object.assign(ing, { acked: true })
			);
		}
		const serializedItemList = serializeItems(items);
		dispatch(
			updatePublicShoppingListThunk({
				id,
				...publicShoppingList,
				ingredients: serializedItemList
			})
		);
	};
	const onSaveOrder = isPublic ? onSaveOrderPublic : onSaveOrderPrivate;

	const onSaveSectionOrder = (droppableId, dir) => {
		const sections = Array.from(shoppingList.sections);
		const movingSection = sections.findIndex(
			s => s.droppableId === droppableId
		);
		const sectionsReordered = reorder(
			sections,
			movingSection,
			dir === 'up' ? movingSection - 1 : movingSection + 1
		);
		sectionsReordered.map((s, idx) => (s.idx = idx));
		const slist = Object.assign(
			{},
			{ ...shoppingList, sections: sectionsReordered }
		);
		dispatch(updateShoppingListThunk(slist));
	};

	const onDelete = () => {
		const itemIdx = shoppingList.ingredients.findIndex(
			i => i.id === editingIngredient.id
		);
		shoppingList.ingredients.splice(itemIdx, 1);
		onSaveOrder(
			deserializeItems(shoppingList.ingredients, shoppingList.sections)
		);
	};

	const atLeastOneBagged = () => {
		const firstBaggedItemFound = shoppingList.ingredients.find(i => i.bagged);
		return firstBaggedItemFound !== undefined;
	};

	const renameShoppingList = (name) => {
    dispatch(
      updateDocumentThunk({ id, ...shoppingList, name}, 'shoppingLists', 'shoppingList', LOAD_SHOPPING_LIST, true)
    );
  }

	const shoppingListIngredients = useMemo(() => (
		<ShoppingListIngredients
			shoppingListDocRef={
				doc(db, `users/${user?.uid}/shoppingLists`, id)
			}
			onSaveOrder={onSaveOrder}
			onSaveSectionOrder={onSaveSectionOrder}
			editItem={item => {
				setEditExisting(true);
				setEditingIngredient(item);
				setAddingIngredient(true);
			}}
		/>
	), [shoppingListDb]);

	const resetShoppingList = () => {
		const newIngs = shoppingList.ingredients.map(i => {
			i.bagged = false;
			return i;
		});
		onSaveOrder(deserializeItems(newIngs, shoppingList.sections));
		setResetting(false);
	};

	const ingredientModal = (
		<Modal
			overlayColor={preferences.dark ? theme.colors.dark[9] : theme.colors.gray[2]}
			overlayOpacity={0.55}
			overlayBlur={3}
			opened={addingIngredient}
			onClose={cancelIngredient}
			title={editExisting ? 'Edit Ingredient' : 'Add Ingredient'}
			closeOnEscape
			zIndex={4000}
			closable={"false"}
		>
			<IngredientForm
				source={'Item'}
				ingredient={editingIngredient}
				onCancel={cancelIngredient}
				onSubmit={editExisting ? editIngredient : addIngredients}
				onDelete={onDelete}
				editing={editExisting}
			/>
		</Modal>
	);


	const numIngredients = shoppingList?.ingredients?.length
		? shoppingList.ingredients.length
		: 0;

	// If no recipes added, but we're on the plan tab, go to shopping list.
	if (
		shoppingListRecipes?.length === 0
		&& activeTab === 'recipesadded'
	) setActiveTab('shopping');

	const shoppingListComponent = isPublic ? (
		<>
			<ShoppingListIngredients
				isPublic={true}
				shoppingListDocRef={doc(db, 'publicShoppingLists', id)}
				onSaveOrder={onSaveOrder}
			/>
			{numIngredients === 0 ? (
				<InfoBox text={'Hmm - We found this list with the details you entered, but there\'s nothing on it. 🤔'} />
			) : null}
		</>
	) : (
		<Tabs
			defaultValue={'shopping'}
			onTabChange={setActiveTab}
			value={activeTab}
			variant="pills"
		>
			<Tabs.List grow position={width > 800 ? "center" : undefined}>
				<Tabs.Tab
					value="shopping"
					icon={<IconBasket size={14} />}
					sx={{ fontWeight: activeTab === 'shopping' ? 'bold' : undefined }}
				>
					List
				</Tabs.Tab>
				{/* {shoppingListRecipes?.filter(r => shoppingList?.recipes?.findIndex(slr => slr.id === r.id) > -1).length > 0 && (
					<Tabs.Tab
						value="recipesadded"
						icon={<IconCalendarTime size={14} />}
						sx={{ fontWeight: activeTab === 'recipesadded' ? 'bold' : undefined }}
					>
						Plan
					</Tabs.Tab>
				)} */}
				{hasRecipes() && (
					<Tabs.Tab
						value="mealplan"
						icon={<IconBook size={14} />}
						sx={{ fontWeight: activeTab === 'mealplan' ? 'bold' : undefined }}
					>
						Recipes
					</Tabs.Tab>
				)}
			</Tabs.List >
			<Tabs.Panel value='shopping'>
				{shoppingList?.ingredients?.length > 0 && (
					<Switch
						p={'sm'}
						checked={showAdvanced}
						color={theme.primaryColor}
						labelPosition={preferences.rightHanded ? 'left' : 'right'}
						onChange={(event) => setShowAdvanced(event.currentTarget.checked)}
						label={"Rearrange Groups"}
						sx={{ textAlign: preferences.rightHanded ? 'right' : 'left' }}
					/>
				)}
				{showAdvanced ? (
					<ShoppingListSections
						save={(sections) => updateShoppingList({...shoppingList, sections })}
						data={shoppingList.sections}
					/>
				) : (
					shoppingListIngredients
				)}
				{ingredientModal}
				<Space h={"xl"} />
				<Space h={"xl"} />
				<Space h={"xl"} />
			</Tabs.Panel>
			{/* <Tabs.Panel value='recipesadded'>
				<HelpWrapper
					feature={process.env.REACT_APP_HELP_SHOPPING_LIST_RECIPE_SCROLLER}
					position={'top-center'}
				>
					<Container px={0} >
						{shoppingListRecipes?.filter(r => shoppingList?.recipes?.findIndex(slr => slr.id === r.id) > -1).map((recipe) => (
							<RecipeAdded key={recipe?.id} recipe={recipe} />
						))}
					</Container>
				</HelpWrapper>
			</Tabs.Panel> */}
			{hasRecipes() && (
				<Tabs.Panel value='mealplan'>
					<Space h={"md"} />
					<RecipesPageInner highlightedListData={shoppingListRecipes?.filter(r => shoppingList?.recipes?.findIndex(slr => slr.id === r.id) > -1)} />
				</Tabs.Panel>
			)}
		</Tabs>
	);

	return (
		<>
			<HeaderMenu hideHelp={isPublic} />
			<Container px={0} sx={{ ...theme.other.wrapper }}>
				<Container my="xs">
					<BackButton defaultFrom={'Shopping Lists'} />
					<Space h={"xs"} />
					<HelpWrapper
						feature={process.env.REACT_APP_HELP_SHOPPING_LIST_TITLE}
						position={'middle-end'}
					>
						<TitleText
							title={shoppingList?.name}
							renameFn={!isPublic && renameShoppingList}
              source={'shopping'}
              maxLength={process.env.REACT_APP_MAX_LENGTH_SHOPPING_LIST_NAME}
						/>
					</HelpWrapper>
					<Space h={"md"} />
					{!loading && shoppingList ? (
						shoppingListComponent
					) : (
						<Center>
							<Loader size={50} />
						</Center>
					)}
					<Space h={'xl'} />
					<Space h={'xl'} />
				</Container>
			</Container>
			{
				isPublic ? (
					<SideControls
						startRef={
							numIngredients > Number(process.env.REACT_APP_MIN_ITEM_NAV)
								? itemsStartRef
								: null
						}
						endRef={
							numIngredients > Number(process.env.REACT_APP_MIN_ITEM_NAV)
								? itemsEndRef
								: null
						}
						preferencesOverride={publicShoppingList?.preferences}
						profile={profileConfig ? profileConfig : undefined}
					/>
				) : (
					<>
						<SideControls
							add={activeTab === 'shopping' ? addIngredient : undefined}
							adding={addingIngredient}
							helpAdd={process.env.REACT_APP_HELP_SHOPPING_LIST_ADD_BUTTON}
							hideAdd={activeTab !== 'shopping'}
							hideShare={activeTab !== 'shopping' || (activeTab === 'shopping' && numIngredients === 0)}
							reset={activeTab === 'shopping' && numIngredients > 0 && atLeastOneBagged() && (() => setResetting(true))}
							share={activeTab === 'shopping' && numIngredients > 0 ? () => setShareVisible(true) : undefined}
							source={'shopping'}
						/>
						<ShareModal
							visible={shareVisible}
							onCancel={() => setShareVisible(false)}
							preferences={preferences}
						/>
						<DangerousActionModal
							title={"Delete Shopping List"}
							onDelete={onDeleteShoppingList}
							onCancel={() => setDeleting(false)}
							visible={deleting}
						/>
						<DangerousActionModal
							buttonLabel={"Reset"}
							title={"Reset Shopping List"}
							message={"Are you sure? This will untick all your shopping list items."}
							onDelete={resetShoppingList}
							onCancel={() => setResetting(false)}
							visible={resetting}
						/>
					</>
				)
			}
		</>
	);
};

export default ShoppingList;
